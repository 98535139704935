import { NavLink } from "react-router-dom"
import dayjs from 'dayjs'
import ContentEditor from '../../editor/ContentEditor'


export default function SharedPost({ post }) {
    post = post?.shared_post ? post?.shared_post : post

    return (
        <>
            {post &&
                <div className="card w-100 shadow-xss rounded-xxl p-2 mt-2 background-color-lighter">

                    <div className="d-flex">
                        <figure className="me-1"><img src={post.user.profile.thumbnail} alt="user" className="shadow-sm rounded-circle w30" /></figure>
                        <NavLink className="fw-500 font-xssss m-1" to={`/${post.user.username ? post.user.username : post.user.id}`} > {post.user.profile.full_name} </NavLink>
                        <span className="font-xssss text-color-lighter m-1"> {dayjs(post.created_at).fromNow()} {post.edited_at && <span className="font-xsssss mx-2 lh-3 text-color-lightest" title={dayjs(post.edited_at).format('MMM D, YYYY h:mm A')}> Edited </span>}</span>
                        <NavLink className="ms-auto font-xsssss text-color-lighter p-1 me-2" to={`/post/${post.public_id}`}>View post</NavLink>
                    </div>

                    <div className='text-color'>
                        <ContentEditor
                            formattedContent={post.formatted_content}
                            uploadedFileList={post.files}
                            urlPreviewsList={post.url_previews}
                            readOnly={true}
                            clampOptions={{ withToggle: true }}
                            enableEmoji={true}
                            enableMention={true}
                            enableHashtag={true}
                        />
                    </div>
                </div>}


            {!post &&
                <div className="card w-100 shadow-xss rounded-xxl p-2 mt-2 background-color-lighter prevent-select">
                    <span className="text-color font-xss text-center">Post Unavailable</span>
                    <span className="text-color-lighter font-xssss text-center mt-1">This post is unavailable because it was deleted.</span>
                </div>
            }
        </>
    )

}