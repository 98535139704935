
import Sidebar from '../navbar/Sidebar'

export default function ViewWrapper({ isAuthenticated, user, children }) {

    return (
        <>
            <Sidebar isAuthenticated={isAuthenticated} user={user} />
            {children}
        </>
    )
}
