import { useState, useEffect } from 'react'
import { MdPhotoCamera } from 'react-icons/md'
import styles from './ProfileImage.module.css'
import Loader from '../components/Loader'
import UploadProfileImage from './UploadProfileImage'

export default function ProfileImage({loading, profile, enableUpload }) {
    const currentProfileImg = profile?.thumbnail

    const [image, setImage] = useState(currentProfileImg)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setImage(profile?.thumbnail)
    }, [profile])

    useEffect(() => {
        setIsLoading(loading)
    }, [loading])

    return (

        <>

            {isLoading || !profile ?
                <figure className={`${styles.imgContainer} avatar position-absolute w100 z-index-1`}>
                    <Loader> <circle cx="50" cy="50" r="50" /> </Loader>
                </figure> :

                <figure className={`${styles.imgContainer} avatar position-absolute w100 h100 z-index-1`}>
                    <img src={image} alt="user" className="p-1 background-color-lighter" style={{
                        height: '100px',
                        width: '100px',
                        borderRadius: '50%'
                    }} />
                    {enableUpload &&

                        <span className="position-absolute bottom-0 right-0 background-color-light font-sm rounded-sm px-1 lh-1">
                            <UploadProfileImage
                                category="PROFILE"
                                aspect={{ width: 500, height: 500 }}
                                setImage={setImage}
                                setIsLoading={setIsLoading}
                            >
                                <MdPhotoCamera />
                            </UploadProfileImage>
                        </span>
                    }
                </figure>}
        </>

    )
}
