import qs from 'qs'
import emojiRegex from 'emoji-regex'

export const regex = {
    emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    usernameRegex: /^(?=[a-zA-Z0-9._]{3,28}$)(?![0-9_]+$)(?!.*[_.]{2})[^_.].*[^_.]$/,
    passwordRegex: /^(?=.*\d)(?=.*[!@#$%^&*()_=+-])(?=.*[a-z])(?=.*[A-Z]).{8,48}$/,
    uuidRegex: /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi,
    urlRegex: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[a-zA-Z]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[a-zA-Z]{2,})/gi,
    hashtagRegex: /\B(?:#|＃)((?![\p{N}_#]+(?:$|\b|\s))(?:[\p{L}\p{M}\p{N}_]{1,60}))/ug,
    colonsEmojiRegex: /:[a-zA-Z0-9-_+]+:/g,
    nativeEmojiRegex: emojiRegex(),
}

/**
 * Remove any falsy item in the params object and convert it to string params 
 * @param {Object} params
 * @returns {String}
 */
export function stringifyUrlParams(params) {
    let cleanParams = Object.fromEntries(Object.entries(params).filter(([_, v]) => !!v))
    return qs.stringify(cleanParams)
}



/**
 * check if the value (only int)
 * @param {String} value
 * @returns {Boolean}
 */
export function isOnlyDigits(value) {
    return /^-?\d+$/.test(value)
}



/**
 * check if the username match the regex
 * @param {String} username
 * @returns {Boolean}
 */
export function isValidUsername(username) {
    return regex.usernameRegex.test(username)
}


/**
 * check if the str is valid uuid
 * @param {String} str
 * @returns {Boolean}
 */
export function isValidUUID(str) {
    return regex.uuidRegex.test(str)
}


/**
 * check if the html element is visible in the screen
 * @param {Object} el
 * @returns {Boolean}
 */
export function isScrolledIntoView(el) {
    const rect = el.getBoundingClientRect()
    const elemTop = rect.top
    const elemBottom = rect.bottom

    // Only completely visible elements return true:
    const isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight)
    // Partially visible elements return true:
    //isVisible = elemTop < window.innerHeight && elemBottom >= 0
    return isVisible
}



/**
 * get user theme
 * @param {Object} user
 * @returns {String}
 */
export function getUserTheme(user) {
    if (user?.theme) {
        if (user.theme === 'AUTO') {
            const isSysDarkTheme = window.matchMedia("(prefers-color-scheme: dark)").matches
            return isSysDarkTheme ? 'DARK' : 'LIGHT'
        } else {
            return user.theme
        }
    }
}

