import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './bootstrap.css'
import './index.css'


import axios from 'axios'

import { Provider } from 'react-redux'
import store from './store'

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'

// dayjs global setup 
dayjs.extend(relativeTime)
dayjs.extend(updateLocale)
dayjs.updateLocale('en', {
    // relativeTime: {
    //     future: "in %s",
    //     past: "%s ago",
    //     s: 'a few seconds',
    //     m: "a minute",
    //     mm: "%d minutes",
    //     h: "an hour",
    //     hh: "%d hours",
    //     d: "a day",
    //     dd: "%d days",
    //     M: "a month",
    //     MM: "%d months",
    //     y: "a year",
    //     yy: "%d years"
    //   }

    relativeTime: {
        future: "in %s",
        past: "%s",
        s: '1s',
        m: "1m",
        mm: "%dm",
        h: "1h",
        hh: "%dh",
        d: "1d",
        dd: "%dd",
        M: "1mo",
        MM: "%dmo",
        y: "1y",
        yy: "%dy"
    }
})


// axios global setup 
axios.defaults.baseURL = process.env.REACT_APP_API_URL
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.xsrfHeaderName = "X-CSRFToken"
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.withCredentials = true


// request interceptor
axios.interceptors.request.use(request => {
    // console.log(request)
    // Edit request config
    return request

}, error => {
    // console.log(error)
    return Promise.reject(error)
})

// response interceptor
axios.interceptors.response.use(response => {
    // console.log(response)
    // Edit response config
    return response

}, error => {
    // console.log(error)

    if (error.response?.status === 403 && error.response?.data?.detail === 'Authentication credentials were not provided.') {
        store.dispatch({ type: 'SIGN_OUT' })
        window.location.href = '/login/'

    }

    else if (error.response?.status === 401) {
        store.dispatch({ type: 'SIGN_OUT' })
        window.location.href = '/login/'
        return Promise.reject(error)
    }

    // specific error handling done elsewhere
    return Promise.reject(error)

})



ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()