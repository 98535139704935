
const initState = {
    chatWs: null,
    roomList: []
}


export const messagesReducer = (state = initState, action) => {

    switch (action.type) {

    case ('SET_CHAT_WS'):
        return {
            ...state,
            chatWs: action.ws,
        }

    case ('SET_ROOM_LIST'):
        return {
            ...state,
            roomList: action.roomList,
        }

    case ('UPDATE_ROOM_LIST'):
        // remove the first room if has no last_message 
        if (!state.roomList[0]?.last_message) {
            state.roomList.shift()
        }
        return {
            ...state,
            roomList: [action.room, ...state.roomList.filter(room => room.id !== action.room.id)],
        }


    case ('RESET_ROOM_UNREAD_COUNT'):
        return {
            ...state,
            roomList: state.roomList.map(room => room.id === action.room.id ? { ...room, unread_count: 0 } : room)
        }

    case ('UPDATE_LAST_MESSAGE'):{
        let roomExists = false
        let updatedRoom = null

        state.roomList.forEach(room => {
            if (room.id === action.payload.room_id) {
                roomExists = true
                updatedRoom = { ...room, unread_count: action.payload.local ? 0 : room.unread_count + 1, last_message: action.payload.message }
            }
        })

        // if it's new chat fet the room list from server
        if (!roomExists) {
            // state.chatWs.send(JSON.stringify({ command: "fetch_room_list" }))
        } else {
            return {
                ...state,
                roomList: [updatedRoom, ...state.roomList.filter(room => room.id !== updatedRoom.id)]
            }
        }
        break
    }

    case ('UPDATE_TYPING_STATUS'):
        return {
            ...state,
            roomList: state.roomList.map(room => room.id === action.payload.room_id ? { ...room, typing: action.payload.typing, typingSender: action.payload.sender } : room)
        }
    


    case ('RESET_CHAT_LIST'):
        return initState

    default:
        return state
    }

}
