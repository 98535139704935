import axios from 'axios'
import { useHistory } from "react-router-dom"
import { useDispatch } from 'react-redux'

export default function useLogout() {
    const dispatch = useDispatch()
    const history = useHistory()

    return async() => {

        try {
            await axios.post('/accounts/logout/')

        } catch (err) {
            // fall silently 
        } finally {
            dispatch({ type: 'SIGN_OUT' })
            history.push('/login')
        }

    }
}
