// import { Link, NavLink, useHistory } from "react-router-dom"
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from "react-router-dom"
// import useFetch from '../hooks/useWsFetchWithInfiniteScroll'
import useFetch from '../hooks/useFetchWithInfiniteScroll'
import { isOnlyDigits, isValidUUID } from '../utils/utils'

import Room from "./Room"
import ChatWindow from "./ChatWindow"

import { MdKeyboardBackspace, MdChat } from 'react-icons/md'
import { FaRegEdit } from 'react-icons/fa'


export default function Messages() {

    const roomList = useSelector((state) => state.messagesReducer.roomList)

    const [roomOption, setRoomOption] = useState({ roomId: null, roomUserId: null, newChat: false })
    const [activeRoom, setActiveRoom] = useState({})
    const [roomMobileOpen, setRoomMobileOpen] = useState(false)

    const { roomParam } = useParams()
    const dispatch = useDispatch()


    // const { isLoading, error, results, lastItemRef } = useFetch("fetch_room_list")
    const { isLoading, error, results, setResults, lastItemRef } = useFetch('/chat/list/')

    useEffect(() => {
        document.title = 'messages'
        dispatch({ type: 'SET_ROOM_LIST', roomList: results })
    }, [results])


    // check if theres active room to add it to the top of the list (needed when user click on profile message)
    useEffect(() => {
        if (roomOption?.roomUserId && activeRoom.id) {
            dispatch({ type: 'UPDATE_ROOM_LIST', room: activeRoom })
        }
    }, [activeRoom.id, results])


    // if roomParam param in url and no active room update the activeRoom - refresh is now working with last room
    useEffect(() => {
        if (roomParam === 'new') {
            setRoomOption({ roomId: null, roomUserId: null, newChat: true })
        }
        else if (isOnlyDigits(roomParam)) {
            setRoomOption({ roomId: null, roomUserId: parseInt(roomParam), newChat: false })
        }
        else if (isValidUUID(roomParam)) {
            setRoomOption({ roomId: roomParam, roomUserId: null, newChat: false })
        }

    }, [roomParam])


    // set first room with read messages as the active room
    useEffect(() => {
        if (!roomParam && !roomOption.roomId && !roomOption.roomUserId && !roomOption.newChat) {
            if (roomList.length > 0) {
                const firstRoomWithReadMessages = roomList.find(rm => (rm.last_message && rm.unread_count === 0))

                if (firstRoomWithReadMessages) {
                    setRoomOption({ roomId: firstRoomWithReadMessages.id, roomUserId: null, newChat: false })
                    setActiveRoom(firstRoomWithReadMessages)
                }
            }
        }
    }, [roomList])


    const handleNewChat = room => {
        setRoomMobileOpen(true)
        setRoomOption({ roomId: null, roomUserId: null, newChat: true })
        // update the url
        const newUrl = `${window.location.protocol}//${window.location.host}/messages/new`
        window.history.pushState({ path: newUrl }, '', newUrl)
    }

    const changeActiveRoom = room => {
        setRoomMobileOpen(true)
        setActiveRoom(room)
        setRoomOption({ roomId: room.id, roomUserId: null, newChat: false })
        // update the url
        const newUrl = `${window.location.protocol}//${window.location.host}/messages/${room.id}`
        window.history.pushState({ path: newUrl }, '', newUrl)
    }


    return (
        <div className="main-content right-chat-active">
            <div className="middle-sidebar-bottom">


                <div className="middle-sidebar-left">
                    <div className="row m-0 background-color-lightest rounded-xxl p-1">
                        {/* left side */}
                        <div className={`${roomMobileOpen ? 'd-none d-lg-block' : 'd-block'} col-lg-4 p-0 border-end`}>
                            <div className="background-color-lightest" style={{ height: 'calc(100vh - 110px)' }}>

                                <div className='d-flex p-2 mx-1 border-bottom'>
                                    <h5 className="text-color-light fw-500 mt-1 me-auto">Chats</h5>

                                    <h5 onClick={handleNewChat} title="New chat" className='pointer mt-1'> <FaRegEdit /></h5>
                                </div>

                                <ul className='overflow-auto' style={{ height: 'calc(100vh - 158px)' }}>
                                    {roomList.length > 0 && roomList.map((room, index) =>
                                        <li
                                            className={`${room.id === activeRoom.id ? 'active background-color-light rounded-xxl' : null} p-2 m-2 me-2 pointer`}
                                            ref={(roomList.length === index + 1) ? lastItemRef : null}
                                            onClick={() => changeActiveRoom(room)} key={room.id}>
                                            <Room room={room} />

                                        </li>
                                    )}

                                    {(!isLoading && roomList.length === 0) &&
                                        <div className='text-center my-4'>
                                            <h1 className='text-color-lightest'><MdChat /></h1>
                                            <h6 className='text-color-lightest'>
                                                No chat history
                                            </h6>
                                        </div>}

                                    {isLoading && <>
                                        <li className='p-2 mt-2'><Room loading={true} /> </li>
                                        <li className='p-2'><Room loading={true} /> </li>

                                    </>}


                                </ul>

                            </div>
                        </div>

                        {/* right side */}
                        <div className={`${roomMobileOpen ? 'd-block' : 'd-none d-lg-block'} col-lg-8 p-0`}>

                            <div className="background-color-lightest mx-1" style={{ height: `calc(100vh - 110px)` }}>
                                <div className='border-bottom p-2 m-1 w-100 d-flex'>
                                    {/* back btn on mobile only */}
                                    <span className="d-inline-block d-lg-none pointer me-2" onClick={() => setRoomMobileOpen(false)}>
                                        <MdKeyboardBackspace className='font-lg me-2' />
                                    </span>

                                    <div className='d-flex'>
                                        {activeRoom?.details?.room_image && <img
                                            src={activeRoom.details.room_image}
                                            alt="user"
                                            className="rounded-circle align-self-center me-3"
                                            width="30">
                                        </img>}
                                        <h6 className="pt-1 text-truncate" style={{ maxWidth: 300 }}> {activeRoom?.details?.room_name}</h6>

                                    </div>

                                </div>

                                <ChatWindow roomOption={roomOption} setActiveRoom={setActiveRoom} />

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
