import { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import useUploadFiles from '../../hooks/useUploadFiles'
import useFetchUrlPreview from '../../hooks/useFetchUrlPreview'
import useCloseWhenClickOut from '../../hooks/useCloseWhenClickOut'
import SharedPost from './SharedPost'
import UploadFile from "../../components/UploadFile"
import PreviewUrl from "../../components/PreviewUrl"
import ContentEditor from "../../editor/ContentEditor"
import { MdOutlineMoreHoriz, MdOutlineFileCopy, MdMode, MdOutlinePublic } from "react-icons/md"


export default function CreatePost({ sharedPost, updatePosts, shareModalRef, insidePopup }) {
    const postObj = { key: Math.random(), isLoading: false, message: '', error: '', content: '', contentWithEmojis: '', formattedContent: undefined, mentions: [], public: true }

    const [publicPost, setPublicPost] = useState(true)
    const [showNewPostOptions, setShowNewPostOptions] = useState(false)
    // const [localFiles, setLocalFiles] = useState([])
    // const { uploadFiles, setFileInfos, error: fileError } = useUploadFiles("newsfeed/post/file/", localFiles, setLocalFiles, process.env.REACT_APP_CHAT_MAX_FILES_COUNT, process.env.REACT_APP_CHAT_FILE_SIZE_LIMIT)
    // const { isLoading: UrlPreviewLoading, urlPreview, setUrlPreview } = useFetchUrlPreview('')
    // const fileInputRef = useRef(null)

    const newPostOptionsRef = useRef(null)

    // handle close popups when click outside the popup element
    useCloseWhenClickOut(newPostOptionsRef, setShowNewPostOptions)

    const source = axios.CancelToken.source()

    const storageName = 'homePostContent'

    // create new post
    const handlePostSubmit = async(content, formattedContent, contentWithEmojis, mentions, filteredUploadedFiles, urlPreviews) => {

        try {
            const response = await axios.post('/newsfeed/create_post/', {
                content: contentWithEmojis,
                formatted_content: formattedContent,
                mentions: mentions,
                public: publicPost,
                url_previews: urlPreviews,
                files_id: [...filteredUploadedFiles.map(f => f.id)]
            }, {
                cancelToken: source.token
            })

            if (response?.status === 201) {
                updatePosts(prev => ({ ...prev, results: [response.data, ...prev.results] }))
                // return ({ message: 'Post successfully posted.' })
            }

        } catch (err) {
            if (axios.isCancel(err)) return
            throw Error(err.response?.data?.non_field_errors)
        }

    }


    // create new shared post
    const handleSharePostSubmit = async(content, formattedContent, contentWithEmojis, mentions, filteredUploadedFiles, urlPreview) => {

        try {
            const response = await axios.post(`/newsfeed/share/${sharedPost.public_id}/`, {
                content: content,
                formatted_content: formattedContent,
                mentions: mentions,
                public: publicPost
            }, {
                cancelToken: source.token
            })
            // console.log('response', response)

            if (response?.status === 201) {
                shareModalRef.current.close()
                return ({ message: 'Post successfully shared.' })
            }

        } catch (err) {
            if (axios.isCancel(err)) return
            shareModalRef.current.close()
            throw Error(err.response?.data?.non_field_errors)

        }
    }


    useEffect(() => {
        return () => source.cancel()
    }, [])


    return (
        <div className="card w-100 rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
            <div className="card-body p-0 d-flex">
                <span className="font-xssss fw-500 text-color-light card-body p-0 d-flex align-items-center">
                    <MdMode className="btn-round-sm font-xs text-primary p-2 me-2 background-color-lighter" />
                    Create Post
                </span>


                {/* options wrapper */}
                <div className="position-relative ms-auto justify-content-end" ref={newPostOptionsRef}>

                    {/* options btn */}
                    <span onClick={() => setShowNewPostOptions(prev => !prev)} className="ms-auto pointer">
                        <MdOutlineMoreHoriz className="text-color-light  btn-round-sm background-color-lighter p-2" />
                    </span>

                    {/* options popup */}
                    <div className={`${showNewPostOptions ? "show" : null} right-0 w300 dropdown-menu p-4 rounded-xxl mt-3`}>


                        <div className="card-body pointer p-0 d-flex">
                            <MdOutlinePublic className="text-color me-3 font-lg" />
                            <h4 >
                                <label className="fw-500 text-color font-xssss mt-0 me-4 pointer">
                                    Public post
                                    <input type='checkbox' checked={publicPost} onChange={() => setPublicPost(prev => !prev)} className="ms-2"/>
                                    <span className="d-block font-xsssss fw-500 mt-1 text-color-lighter">If the checkbox is unchecked, only friends will be able to view the post.</span>
                                </label>
                            </h4>
                        </div>

                    </div>
                </div>
            </div>



            <div className="mt-2">

                {!sharedPost && <ContentEditor
                    storageName={storageName}
                    placeHolder="What's on your mind?"
                    readOnly={false}
                    enableTextFormat={true}
                    enableEmoji={true}
                    enableMention={true}
                    removeMentionPrefix={false}
                    enableHashtag={true}
                    enableUrlPreview={true}
                    enableUploadFiles={true}
                    uploadFilesEndpoint={"newsfeed/media/"}
                    submitButtonName={'Post'}
                    insidePopup={insidePopup}
                    handleSubmit={handlePostSubmit}
                />}

                {sharedPost && <>
                    <ContentEditor
                        key={sharedPost.public_id}
                        storageName={storageName + sharedPost.public_id}
                        placeHolder="create a post…"
                        readOnly={false}
                        enableTextFormat={true}
                        enableEmoji={true}
                        enableMention={true}
                        removeMentionPrefix={false}
                        enableHashtag={true}
                        enableUrlPreview={true}
                        submitButtonName={'Share'}
                        insidePopup={insidePopup}
                        handleSubmit={handleSharePostSubmit}
                    />
                    <SharedPost post={sharedPost} />

                </>}


            </div>

        </div>
    )

}