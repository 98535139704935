import { useState, useEffect } from 'react'
import useFetch from '../hooks/useFetchWithInfiniteScroll'
import { Link } from "react-router-dom"
import { toast } from 'react-toastify'
import { stringifyUrlParams } from '../utils/utils'
import Post from './post/Post'
import CreatePost from './post/CreatePost'

import { MdOutlineArtTrack } from 'react-icons/md'

export default function Newsfeed({ userId, username, type, hashtag, isMyProfile, isAuthenticated }) {

    const [url, setUrl] = useState(`/newsfeed/?${stringifyUrlParams({ user_id: userId, username, type, hashtag })}`)
    const { isLoading, error, results: posts, setResults, lastItemRef: lastPostRef } = useFetch(url)

    // update url if props changes
    useEffect(() => {
        setUrl(`/newsfeed/?${stringifyUrlParams({ user_id: userId, username, type, hashtag })}`)
    }, [userId, username, type, hashtag])


    // update posts list after delete
    const updatePosts = (post, action) => {
        if (action === 'delete') {
            setResults(prev => { return { ...prev, results: prev.results.filter(p => p.public_id !== post.public_id) } })
        }
    }

    useEffect(() => {
        if (error) {
            toast.error(error?.response?.data?.non_field_errors?.toString() || error?.message  || 'An unknown error occurred.')
        }
    }, [error])

    
    return (
        <>
            {(isMyProfile || type === 'home') && <CreatePost updatePosts={setResults} />}

            {/* no posts */}
            {!isLoading && posts.length === 0 &&
                <div className="card w-100 text-center shadow-xss rounded-xxl border-0 p-4 mb-3">
                    <div>
                        <h1 className='text-color-lighter'><MdOutlineArtTrack /></h1>
                        <h6 className='text-color-lighter'> No posts available at this time. </h6>
                        { isAuthenticated && <Link to={`/friends/find`} className="btn btn-outline-primary font-xsss m-3 p-2 px-3"> Find friends </Link>}
                    </div>
                </div>
            }

            {/* posts */}
            {posts.length > 0 && posts.map((post, index) =>
                <div className="text-color" ref={posts.length === index + 1 ? lastPostRef : null} key={post.public_id}>
                    <Post post={post} updatePosts={updatePosts} isAuthenticated={isAuthenticated}/>
                </div>
            )}

            {/* loading */}
            {isLoading > 0 && [...Array(2)].map((_, i) =>
                <Post loading={true} key={i} />
            )}
        </>
    )

}
