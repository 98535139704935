import { useState, useEffect } from 'react'
import axios from 'axios'
import { regex } from '../utils/utils'

export default function ForgotPasswordView() {

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [error, setError] = useState({})
    const [email, setEmail] = useState('')
    const source = axios.CancelToken.source()

    // Add reCaptcha
    useEffect(() => {
        const script = document.createElement("script")
        script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`
        script.async = true
        document.getElementById('forgot-password').appendChild(script)
    }, [])


    useEffect(() => {
        document.title = 'forgot password'
        return () => source.cancel()
    }, [])


    const handleSubmit = async(e) => {
        e.preventDefault()
        setMessage('')
        setError({})

        if (!email || !regex.emailRegex.test(email.trim())) {
            return setError({ email: ['Invalid email.'] })
        }


        // recaptcha
        window.grecaptcha.ready(() => {
            setIsLoading(true)

            window.grecaptcha
                .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: "forgotPassword" })
                .then(async token => {

                    // send reset email 
                    try {
                        const response = await axios.post('/accounts/forgot_password/', { email, recaptcha_token: token }, {
                            cancelToken: source.token
                        })

                        if (response?.status === 204) {
                            setMessage('Email successfully sent')
                            setEmail('')
                        }

                    } catch (err) {
                        if (axios.isCancel(err)) return
                        setError(err.response?.data)
                    } finally {
                        setIsLoading(false)
                    }


                }).catch(err => {
                    if (axios.isCancel(err)) return
                    setIsLoading(false)
                })
        })
    }

    return (
        <div className="fluid-container" id="forgot-password">
            <div className="row m-0">

                <div className='col-12 d-flex justify-content-center vertical-center'>

                    <div className="card mt-1 mt-md-3 py-4 px-5 border rounded-xl account-form">

                        <h5 className="text-center mb-4 text-main-color">Forgot password</h5>

                        {message && <li className="alert alert-success alert-dismissible fade show p-2 px-3 font-bold font-xsss rounded-xxxl">
                            {message}
                            <button onClick={() => setMessage('')} className="btn-close pt-2 font-bold font-xssss"></button>
                        </li>}

                        {error?.non_field_errors?.length > 0 && <ul>
                            {error?.non_field_errors.map((err, idx) =>
                                <li key={idx} className="alert alert-danger alert-dismissible fade show p-2 px-3 font-bold font-xsss rounded-xxxl">
                                    {err}
                                    <button onClick={() => setError(prev => ({ ...prev, non_field_errors: prev.non_field_errors.filter(e => e !== err) }))} className="btn-close pt-2 font-bold font-xssss"></button>
                                </li>
                            )}
                        </ul>}


                        <form onSubmit={handleSubmit} onClick={() => setError({})}>
                            <div className="col">
                                <label className="text-color font-xsss" >Email</label>
                                <input className={`${error.email ? 'is-invalid' : ''} form-control`} type="text" placeholder="Enter your email" value={email} disabled={isLoading} onChange={e => setEmail(e.target.value)} />
                                {error?.email?.length > 0 && <ul className='w-100'>
                                    {error?.email.map((err, idx) => <li key={idx} className="help-block text-danger font-xssss  ms-1">{err}</li>)}
                                </ul>}
                            </div>



                            {/* send reset password */}
                            <div className="d-grid my-4">
                                <button type="submit" className="btn btn-primary p-2 px-3" disabled={isLoading}>
                                    {isLoading && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}
                                    Send reset password email
                                </button>
                            </div>

                        </form>

                    </div>
                </div>
            </div>
        </div>
    )
}
