import { useEffect, useRef } from 'react'
import axios from 'axios'

export default function UrlComponent(props) {
    const { decoratedText, children, enableOpenUrl, enableUrlPreview, urlPreviewsRef, setUrlPreviews, readOnly } = props

    let url = decoratedText
    if (!decoratedText.startsWith('http')) {
        url = 'http://' + url
    }
    const source = axios.CancelToken.source()
    const timer = useRef(null)

    // handle url preview
    useEffect(() => {
        if (!readOnly && enableUrlPreview) {

            const handleUrlPreview = async(url) => {

                let foundUrl = false
                urlPreviewsRef.current.forEach(p => {
                    if (p.url.includes(url)) {
                        foundUrl = true
                    }
                })
                if (!foundUrl) {

                    try {
                        const response = await axios.get(`/newsfeed/url_preview/?url=${url}`, {
                            cancelToken: source.token
                        })
                        if (response?.status === 200) {
                            const updatedUrlPreviews = [...urlPreviewsRef.current, response.data]
                            urlPreviewsRef.current = updatedUrlPreviews
                            setUrlPreviews(updatedUrlPreviews)
                        }

                    } catch (err) {
                        if (axios.isCancel(err)) return
                        // setError(JSON.stringify(err.response?.data))
                        // setIsLoading(false)
                    }

                }
            }

            if (timer.current) {
                clearTimeout(timer.current)
            }

            // get link preview after 1 second of stop typing
            timer.current = setTimeout(() => {
                handleUrlPreview(url)
                timer.current = null
            }, 1000)
        }
    }, [url])


    return (readOnly && enableOpenUrl?
        <a href={url} target="_blank" className='hover-underline' rel="noreferrer">{children}</a> :
        <span className='text-primary'>{children}</span>
    )
}