
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from "react-router-dom"
import { eventEmitter } from '../events'
import Chat from "./Chat"
import ContentEditor from '../editor/ContentEditor'

export default function ChatWindow({ roomOption, setActiveRoom }) {

    const [roomId, setRoomId] = useState('')
    const [roomName, setRoomName] = useState('')
    // const [roomUserIds, setRoomUserIds] = useState([])
    const [content, setContent] = useState({ mentions: [] })

    const ws = useSelector((state) => state.messagesReducer.chatWs)
    // const roomList = useSelector((state) => state.messagesReducer.roomList)
    const dispatch = useDispatch()



    useEffect(() => {
        if (ws && roomOption.roomId) {
            setRoomId(roomOption.roomId)
            setContent({ mentions: [] })
        }
        else if (ws && roomOption.roomUserId) {
            handleGetOrCreateRoom([roomOption.roomUserId])
        }
        else if (ws && content.mentions.length) {
            handleGetOrCreateRoom(content.mentions)
        }
        else {
            setRoomId('')
            setActiveRoom({})
        }

    }, [roomOption, content.mentions.length, ws])




    // handle ws events
    useEffect(() => {

        eventEmitter.subscribe('fetch_or_create_room', payload => {
            setRoomId(payload.room.id)
            dispatch({ type: 'UPDATE_ROOM_LIST', room: payload.room })
        })

        return () => {
            eventEmitter.unsubscribe('fetch_or_create_room')
        }
    }, [])




    // handle send new messages
    const handleGetOrCreateRoom = async(userIds) => {
        // e?.preventDefault()
        // setIsLoading(true)
        // setError('')
        // const userIds = roomUserIds.split(',').filter(id => id).map(Number)
        if (userIds.length) {
            try {
                ws.send(JSON.stringify({ command: "fetch_or_create_room", payload: { room_user_ids: userIds, room_name: roomName } }))
                // ws.send(JSON.stringify({ command: "new_message", payload: { message: newMessage.content, room_user_ids: [2,1, 3], room_name: 'cool group' } }))
                // if (room.id !== user.id) {

            } catch (err) {
                // fall silently

                // setError(JSON.stringify(err.response?.data))
                // setIsLoading(false)
            }
        }

    }



    return (
        <div>
            {roomOption.newChat && <div className='mx-3 mt-2'>
                <ContentEditor
                    mentionsOnly={true}
                    enableMention={true}
                    placeHolder="Type a name or multiple names"
                    removeMentionPrefix={true}
                    setContent={setContent}
                />
            </div>}

            {roomId && <Chat room={{ id: roomId }} key={roomId} setActiveRoom={setActiveRoom} chatHeight={roomOption.newChat ? '225px' : '155px'} />}
        </div>
    )
}