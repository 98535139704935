import { useState, useEffect, useRef } from 'react'
import { MdPhotoCamera } from 'react-icons/md'
import Loader from '../components/Loader'
import UploadProfileImage from './UploadProfileImage'



export default function ProfileBackgroundImage({ loading, profile, enableUpload }) {
    const currentProfileBgImg = profile?.background_image_thumbnail
    const [image, setImage] = useState(currentProfileBgImg)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setImage(profile?.background_image_thumbnail)
    }, [profile])

    useEffect(() => {
        setIsLoading(loading)
    }, [loading])

    return (
        <>
            {/* TODO: change the height on mobile */}
            <div className="card-body p-0 rounded-xxl overflow-hidden m-3 position-relative" style={{ maxHeight: 250, height: 250 }}>

                {(isLoading || !profile) ?
                    <Loader height="250"> <rect x="0" y="0" rx="5" ry="5" width="100%" height="250" /> </Loader>
                    :

                    <>
                        <img src={image}
                            alt="background"
                            style={{ height: '100%', width: '100%', objectFit: 'fill' }}
                        />

                        {enableUpload && <span className="position-absolute top-0 right-0 background-color-light font-md rounded-xxxl m-3 py-1 px-2 lh-1">
                            <UploadProfileImage
                                aspect={{ width: 960, height: 250 }}
                                category="BACKGROUND"
                                setImage={setImage}
                                setIsLoading={setIsLoading}
                            >
                                <MdPhotoCamera />
                            </UploadProfileImage>
                        </span>}
                    </>
                }
            </div>
        </>

    )
}


