
const initState = {
    user: JSON.parse(localStorage.getItem("user")) || null,
    isAuthenticated: JSON.parse(localStorage.getItem("isAuthenticated")) || false,
}


export const userReducer = (state = initState, action) => {
    // console.log(action)

    switch (action.type) {

    case ('SIGN_IN'):
        localStorage.setItem('user', JSON.stringify(action.data.user))
        localStorage.setItem('isAuthenticated', 1)

        return {
            ...state,
            user: action.data.user,
            isAuthenticated: true
        }


    case ('SIGN_OUT'):{
        // localStorage.setItem('user', '')
        // localStorage.setItem('isAuthenticated', '')
        // // or clear all localStorage
        // localStorage.clear()


        const theme = localStorage.getItem('theme')
            
        // clear everything but the theme 
        localStorage.clear()
        localStorage.setItem('theme', theme)

        return {
            ...state,
            user: null,
            tokens: null,
            isAuthenticated: false
        }

    }
    case ('UPDATE_USER'):
        localStorage.setItem('user', JSON.stringify(action.user))
        return {
            ...state,
            user: action.user
        }


    default:
        return state
    }

}
