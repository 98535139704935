import { useState, useEffect } from 'react'
import { useParams } from "react-router-dom"
import { useHistory } from "react-router-dom"
import qs from 'qs'
import { toast } from 'react-toastify'
import useFetch from '../hooks/useFetchWithInfiniteScroll'
import Friend from '../friends/Friend'
import { MdPersonSearch } from 'react-icons/md'


export default function Search() {
    const history = useHistory()
    const { q } = useParams()
    const [url, setUrl] = useState(`/friends/search/?query=${q}`)
    const { isLoading, error, results, setResults, lastItemRef } = useFetch(url)


    useEffect(() => {
        document.title = 'search'
        // parse url params
        const urlParams = qs.parse(history.location?.search, { ignoreQueryPrefix: true })

        if (urlParams?.q) {
            document.title = `${urlParams.q} | search`
            // search friend
            setUrl(`/friends/search/?query=${urlParams.q}`)

            // set the url bar
            const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?q=${urlParams.q}`
            window.history.pushState({ path: newUrl }, '', newUrl)
        }

    }, [history.location?.search])


    useEffect(() => {
        if (error) {
            toast.error(error?.message || error?.data?.non_field_errors?.toString() || 'Unknown search error.')
        }
    }, [error])


    return (

        <div className="main-content right-chat-active">

            <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left">
                    <div className="">
                        <div className="p-3 w-100 position-relative scroll-bar background-color-lightest rounded-xxl">
                            <h2 className="fw-500 mb-4 m-2 font-md text-color-light">People</h2>

                            {!isLoading && results?.length === 0 && <div className='text-center p-3'>
                                <h1 className='text-color-lightest'><MdPersonSearch /></h1>
                                <h6 className='text-color-lightest'> No one found.</h6>
                            </div>}

                            {results?.length > 0 && results.map((friend, index) =>
                                <div ref={results.length === index + 1 ? lastItemRef : null} key={friend.id}>
                                    <Friend friend={friend} listView={true} />
                                </div>
                            )}

                            {isLoading && [...Array(2)].map((_, i) =>
                                <Friend loading={true} listView={true} key={i} />
                            )}

                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
