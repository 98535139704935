import { useEffect } from 'react'
import FriendsDirectory from './FriendsDirectory'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import axios from 'axios'
import useFetch from '../hooks/useFetchWithInfiniteScroll'
import Notification from '../notifications/Notification'
import { MdNotifications } from 'react-icons/md'


export default function FriendsRequestsNotifications() {

    const friendNotificationsCount = useSelector((state) => state.notificationReducer.friends)
    const { isLoading, error, results: notifications, lastItemRef: lastNotificationRef } = useFetch('/notifications/friends/')
    const dispatch = useDispatch()

    useEffect(() => {
        document.title = 'friends'

        // mark notifications as read
        if (friendNotificationsCount > 0) {
            try {
                axios.post('/notifications/mark_friend_notifications_as_read/')
                dispatch({ type: 'SET_FRIENDS_NOTIFICATION', count: 0 })

            } catch (err) {
                if (axios.isCancel(err)) return
            }
        }


    }, [friendNotificationsCount])


    useEffect(() => {
        if (error) {
            toast.error(error?.response?.data?.non_field_errors?.toString() || error?.message  || 'An unknown error occurred.')
        }
    }, [error])


    return (

        <div className="main-content right-chat-active">

            <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left">
                    <div className="">


                        <div className="p-3 w-100 mb-3 position-relative scroll-bar background-color-lightest rounded-xxl">
                            <h2 className="fw-500 mb-4 m-2 font-md text-color-light d-flex align-items-center">Friends Requests
                                <span className="ms-auto">
                                </span>
                            </h2>

                            <FriendsDirectory type='pending' notFoundMessage='No friends requests.' showFindFriendsBtn={true} />

                        </div>



                        <div className="p-3 w-100 position-relative scroll-bar background-color-lightest rounded-xxl">
                            <h2 className="fw-500 mb-4 m-2 font-md text-color-light d-flex align-items-center">Friends Notifications
                                {/* <span className="circle-count bg-warning text-white font-xsssss rounded-3 ms-2 ls-3 fw-500 p-2  mt-0">23</span> */}
                            </h2>

                            <ul className="notification-box">

                                {!isLoading && notifications.length === 0 && <div className='text-center p-3'>
                                    <h1 className='text-color-lightest'><MdNotifications /></h1>
                                    <h6 className='text-color-lightest'> No friends notifications.</h6>
                                </div>}

                                {notifications.length > 0 && notifications.map((notification, index) =>
                                    <li ref={(notifications.length === index + 1) ? lastNotificationRef : null} key={notification.id} className="m-1">
                                        <Notification notification={notification} />
                                    </li>
                                )}

                                {isLoading > 0 && [...Array(3)].map((_, i) =>
                                    <Notification loading={true} key={i} />
                                )}

                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}