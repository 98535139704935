import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import { toast } from 'react-toastify'
import { regex } from '../utils/utils'


export default function ChangeEmail({ user }) {

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    const [newEmail, setNewEmail] = useState('')
    const [newEmailSent, setNewEmailSent] = useState(false)
    const [code, setCode] = useState('')

    const dispatch = useDispatch()
    const source = axios.CancelToken.source()

    const handleEmailSubmit = async(e) => {
        if (user.email === newEmail.trim().toLowerCase()) {
            return setError({ email: ["it's your current email."] })
        }

        if (!regex.emailRegex.test(newEmail.trim())) {
            return setError({ email: ['Invalid email.'] })
        }


        e.preventDefault()
        setIsLoading(true)
        setError({})


        try {
            const response = await axios.post('/accounts/change_email/', {
                email: newEmail.trim()
            }, {
                cancelToken: source.token
            })
            if (response?.status === 204) {
                setIsLoading(false)
                setNewEmailSent(true)
                toast.success('Code successfully sent to the new email.')
            }

        } catch (err) {
            if (axios.isCancel(err)) return
            else if (err?.response?.data) {
                if (err.response?.data?.non_field_errors) {
                    toast.error(err.response.data.non_field_errors?.toString())
                }
                setError(err?.response?.data)
            } else {
                toast.error('Unknown error')
            }
            setIsLoading(false)
        }
    }

    const handleCodeSubmit = async(e) => {
        e.preventDefault()
        setIsLoading(true)
        setError({})

        try {
            const response = await axios.post('/accounts/change_email_confirm/', { code }, {
                cancelToken: source.token
            })

            if (response?.status === 204) {
                dispatch({ type: 'UPDATE_USER', user: { ...user, email: newEmail } })
                setNewEmail('')
                setCode('')
                setNewEmailSent(false)
                setIsLoading(false)
                toast.success('Email Successfully Updated.')
            }

        } catch (err) {
            if (axios.isCancel(err)) return
            else if (err?.response?.data) {
                if (err.response?.data?.non_field_errors) {
                    toast.error(err.response.data.non_field_errors?.toString())
                }
                setError(err?.response?.data)
            } else {
                toast.error('Unknown error')
            }
            setIsLoading(false)
        }
    }


    // handle Unmount component
    useEffect(() => {
        return () => source.cancel()
    }, [])

    return (

        <div className="px-4" >
            <h5 className="p-2 pb-3 border-bottom text-color-light">Email</h5>
            <div className="py-2">

                <div className="row">
                    <div className="col-md-6">

                        <div className="form-group has-error py-1">
                            <label className='font-xsss text-color-lighter'>Current Email</label>
                            <input className={`form-control`} type="text" value={user.email} disabled />
                        </div>
                        <div className="form-group has-error py-1">
                            <label className="text-color-lighter font-xsss" >New Email</label>
                            <input className={`${error.email ? 'is-invalid' : ''} form-control`} type="text" value={newEmail} onChange={e => setNewEmail(e.target.value)} onClick={() => setError({})} disabled={newEmailSent || isLoading} />
                            {error?.email?.length > 0 && <ul>
                                {error?.email.map((err, idx) => <li key={idx} className="help-block text-danger font-xssss  ms-1">{err}</li>)}
                            </ul>}
                        </div>

                        <div className="form-group has-error py-1">
                            <label className="text-color-lighter font-xsss" >Confirmation Code</label>
                            <input className={`${error.code ? 'is-invalid' : ''} form-control`} type="text" maxLength="6" value={code} onChange={e => setCode(e.target.value)} onClick={() => setError({})} disabled={!newEmailSent || isLoading} />
                            {error?.code?.length > 0 && <ul>
                                {error?.code.map((err, idx) => <li key={idx} className="help-block text-danger font-xssss  ms-1">{err}</li>)}
                            </ul>}
                        </div>
                    </div>

                    <div className="col-md-6 p-2">

                        <div className="my-4">
                            <h6 className="mb-2">Note:</h6>
                            <div className="small text-muted ps-2">
                                {/* <span className="d-block">{passwordRequirements.eightOrMore ? <MdCheck className="text-success" /> : <MdClose className="text-danger" />} At Minimum 8 characters.</span>
                                <span className="d-block">{passwordRequirements.specialChar ? <MdCheck className="text-success" /> : <MdClose className="text-danger" />} At least one special character.</span>
                                <span className="d-block">{passwordRequirements.numberChar ? <MdCheck className="text-success" /> : <MdClose className="text-danger" />} At least one number.</span>
                                <span className="d-block">{passwordRequirements.capitalChar ? <MdCheck className="text-success" /> : <MdClose className="text-danger" />} At least one capital letter.</span> */}
                                <span className="d-block">Yon old email will be deleted and no longer to be able to login with.</span>
                            </div>
                        </div>


                    </div>

                    {/* Action buttons */}
                    <div className="d-flex pt-3">
                        <div className="ms-auto">
                            {!newEmailSent && <button className="btn btn-primary font-xsss p-2 px-3" disabled={isLoading || !newEmail} onClick={handleEmailSubmit}>
                                {isLoading && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}
                                Change Email
                            </button>}
                            {newEmailSent && <button className="btn btn-primary font-xsss p-2 px-3" disabled={isLoading || !code} onClick={handleCodeSubmit}>
                                {isLoading && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}
                                Confirm Code
                            </button>}
                        </div>
                    </div>


                </div>

            </div>

        </div>


    )
}
