import { useEffect } from 'react'
import { useParams } from "react-router-dom"
import { useSelector } from 'react-redux'
import Newsfeed from './Newsfeed'

export default function HashtagView() {
    const isAuthenticated = useSelector((state) => state.userReducer.isAuthenticated)
    const { hashtag } = useParams()

    useEffect(() => {
        document.title = `#${hashtag}`
    })


    return (
        <div className="main-content right-chat-active">
            <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left">
                    <Newsfeed hashtag={hashtag} isAuthenticated={isAuthenticated}/>
                </div>
            </div>
        </div>)
}