import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ContentEditor from '../editor/ContentEditor'
import dayjs from 'dayjs'
import { FaPencilAlt } from 'react-icons/fa'
import Loader from '../components/Loader'

export default function Room({ room, updateUserChat, loading }) {

    const [isLoading, setIsLoading] = useState(loading)

    // const user = useSelector((state) => state.userReducer.user)

    // const getRoomInfo = (room) => {
    //     let roomInfo = { name: 'Room', image: null }
    //     // room with two only
    //     if (room.type === 'PRIVATE') {
    //         roomInfo = {
    //             name: room.members[0].id !== user.id ? room.members[0].profile.full_name : room.members[1].profile.full_name,
    //             image: room.members[0].id !== user.id ? room.members[0].profile.thumbnail : room.members[1].profile.thumbnail
    //         }
    //         //room with user him/her self
    //     } else if (room.type === 'PERSONAL') {
    //         roomInfo = {
    //             name: room.members[0].profile.full_name,
    //             image: room.members[0].profile.thumbnail
    //         }
    //         // room with more than 2 
    //     } else if (room.type === 'GROUP') {
    //         roomInfo = {
    //             name: room.name ? room.name : 'Group Chat',
    //             image: null
    //         }
    //     }
    //     return roomInfo
    // }

    // const roomInfo = getRoomInfo(room)


    return (
        <>
            {isLoading ?
                <Loader height={82}>
                    < circle cx="40" cy="32" r="28" />
                    <rect x="80" y="12" rx="4" ry="4" width="90" height="13" />
                    <rect x="80" y="36" rx="4" ry="4" width="170" height="15" />
                </Loader > :

                <div className="d-flex px-2">


                    <div className="d-flex mt-2 me-2">
                        {room.details?.members?.length <= 1 && <div className="flex-shrink-0 avatar me-2">
                            <img className="avatar-img rounded-circle" src={room.details?.members[0]?.profile?.thumbnail || room.details.room_image} alt="avatar" />
                        </div>}


                        {room.details?.members?.length === 2 && <div className="flex-shrink-0 avatar me-2">
                            <ul className="avatar-group avatar-group-two">
                                <li className="avatar avatar-xs">
                                    <img className="avatar-img rounded-circle" src={room.details?.members[0]?.profile?.thumbnail} alt="avatar" />
                                </li>
                                <li className="avatar avatar-xs">
                                    <img className="avatar-img rounded-circle" src={room.details?.members[1]?.profile?.thumbnail} alt="avatar" />
                                </li>
                            </ul>
                        </div>}


                        {room.details?.members?.length === 3 && <div className="flex-shrink-0 avatar me-2">
                            <ul className="avatar-group avatar-group-three">
                                <li className="avatar avatar-xs">
                                    <img className="avatar-img rounded-circle" src={room.details?.members[0]?.profile?.thumbnail} alt="avatar" />
                                </li>
                                <li className="avatar avatar-xs">
                                    <img className="avatar-img rounded-circle" src={room.details?.members[1]?.profile?.thumbnail} alt="avatar" />
                                </li>
                                <li className="avatar avatar-xs">
                                    <img className="avatar-img rounded-circle" src={room.details?.members[2]?.profile?.thumbnail} alt="avatar" />
                                </li>
                            </ul>
                        </div>}


                        {room.details?.members?.length >= 4 && <div className="flex-shrink-0 avatar me-2">
                            <ul className="avatar-group avatar-group-four">
                                <li className="avatar avatar-xxs">
                                    <img className="avatar-img rounded-circle" src={room.details?.members[0]?.profile?.thumbnail} alt="avatar" />
                                </li>
                                <li className="avatar avatar-xxs">
                                    <img className="avatar-img rounded-circle" src={room.details?.members[1]?.profile?.thumbnail} alt="avatar" />
                                </li>
                                <li className="avatar avatar-xxs">
                                    <img className="avatar-img rounded-circle" src={room.details?.members[2]?.profile?.thumbnail} alt="avatar" />
                                </li>
                                <li className="avatar avatar-xxs">
                                    <img className="avatar-img rounded-circle" src={room.details?.members[3]?.profile?.thumbnail} alt="avatar" />
                                </li>
                            </ul>
                        </div>}

                    </div>







                    <div className='w-100 py-1 my-2'>
                        <div className="d-flex align-items-center">
                            <span className="fw-600 font-xssss text-primary text-truncate me-auto" style={{ maxWidth: 170 }}>{room.details.room_name} {room.typing && <FaPencilAlt className='m-1 font-xsssss text-muted p-0 m-0' />}</span>
                            {room.last_message && <span className=" font-xsssss text-color-lighter" title={dayjs(room.last_message.created_at).format('ddd, MMM D, H:mm')}>{dayjs(room.last_message.created_at).fromNow()}</span>}
                        </div>

                        <div className="d-flex align-items-start">
                            <div className="text-color font-xsssss me-1">
                                {room.last_message &&
                                    <ContentEditor
                                        key={room.last_message.created_at}
                                        formattedContent={room.last_message.formatted_content}
                                        clampOptions={{ lines: 1 }}
                                        readOnly={true}
                                        enableOpenUrl={false}
                                        enableEmoji={true}
                                    />}
                            </div>
                            {room.unread_count > 0 && <div className="ms-auto"><span className="font-xsssss badge bg-primary">{room.unread_count}</span></div>}
                        </div>
                    </div>
                </div>
            }
        </>
    )
}