import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from "react-router-dom"
import axios from 'axios'
import { regex } from '../utils/utils'
import CheckAndSetPasswords from '../components/CheckAndSetPasswords'

export default function SignupView() {

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState({})
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [validPassword, setValidPassword] = useState(false)
    const googleAuthLink = `${process.env.REACT_APP_API_URL}/accounts/oauth/google`
    const microsoftAuthLink = `${process.env.REACT_APP_API_URL}/accounts/oauth/microsoft`

    const dispatch = useDispatch()
    const history = useHistory()

    const source = axios.CancelToken.source()

    useEffect(() => {
        document.title = 'signup'

        return () => source.cancel()
    }, [])



    // Add reCaptcha
    useEffect(() => {
        const script = document.createElement("script")
        script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`
        script.async = true
        document.getElementById('signup').appendChild(script)
    }, [])


    const handleSubmit = async(e) => {
        e.preventDefault()

        const errors = {}
        if (!firstName) {
            errors.profile = { first_name: ['Invalid first name.'] }
        }
        if (!lastName) {
            errors.profile = { ...errors.profile, last_name: ['Invalid last name.'] }
        }
        if (!regex.emailRegex.test(email.trim())) {
            errors.email = ['Invalid email.']
        }
        if (username && !regex.usernameRegex.test(username.trim())) {
            errors.username = ['Invalid username.']
        }
        if (!validPassword){
            errors.password = ['Invalid password.']
        }

        if (Object.keys(errors).length !== 0) return setError(errors)


        // recaptcha
        window.grecaptcha.ready(() => {

            setError({})
            setIsLoading(true)

            window.grecaptcha
                .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: "signup" })
                .then(async token => {

                    // signup
                    try {
                        const response = await axios.post('/accounts/signup/', {
                            profile: { first_name: firstName, last_name: lastName },
                            username: username,
                            email: email,
                            password: password,
                            recaptcha_token: token
                        }, {
                            cancelToken: source.token
                        })
                        if (response?.status === 201) {
                            dispatch({ type: 'SIGN_IN', data: response.data })
                            history.push('/')
                        }

                    } catch (err) {
                        if (axios.isCancel(err)) return
                        setError(err.response?.data)

                    } finally {
                        setIsLoading(false)
                    }


                }).catch(err => {
                    if (axios.isCancel(err)) return
                    setIsLoading(false)
                })
        })
    }


    return (
        <div className="fluid-container" id="signup">
            <div className="row m-0">

                <div className='col-12 d-flex justify-content-center vertical-center'>

                    <div className="card mt-1 mt-md-3 py-4 px-5 border rounded-xl account-form">

                        <h5 className="text-center mb-4 text-main-color">Sign up</h5>

                        {/* errors */}
                        {error?.non_field_errors?.length > 0 && <ul>
                            {error?.non_field_errors.map((err, idx) =>
                                <li key={idx} className="alert alert-danger alert-dismissible fade show p-2 px-3 font-bold font-xsss rounded-xxxl">
                                    {err}
                                    <button onClick={() => setError(prev => ({ ...prev, non_field_errors: prev.non_field_errors.filter(e => e !== err) }))} className="btn-close pt-2 font-bold font-xssss"></button>
                                </li>
                            )}
                        </ul>}

                        <form onSubmit={handleSubmit} onClick={() => setError({})}>

                            <div className="col my-1">
                                <label className="text-color font-xsss">First name</label>
                                <input className={`${error.profile?.first_name ? 'is-invalid' : ''} form-control`} type="text" placeholder="first name" value={firstName} disabled={isLoading} onChange={e => setFirstName(e.target.value)} />
                                {error?.profile?.first_name?.length > 0 && <ul>
                                    {error.profile.first_name.map((err, idx) => <li key={idx} className="help-block text-danger font-xssss  ms-1">{err}</li>)}
                                </ul>}
                            </div>

                            <div className="col my-1">
                                <label className="text-color font-xsss">Last name</label>
                                <input className={`${error.profile?.last_name ? 'is-invalid' : ''} form-control`} type="text" placeholder="last name" value={lastName} disabled={isLoading} onChange={e => setLastName(e.target.value)} />
                                {error?.profile?.last_name?.length > 0 && <ul>
                                    {error.profile.last_name.map((err, idx) => <li key={idx} className="help-block text-danger font-xssss  ms-1">{err}</li>)}
                                </ul>}
                            </div>

                            <div className="col my-1">
                                <label className="text-color font-xsss">Email</label>
                                <input className={`${error.email ? 'is-invalid' : ''} form-control`} type="text" placeholder="email" value={email} disabled={isLoading} onChange={e => setEmail(e.target.value)} />
                                {error?.email?.length > 0 && <ul className='w-100'>
                                    {error.email.map((err, idx) => <li key={idx} className="help-block text-danger font-xssss  ms-1">{err}</li>)}
                                </ul>}
                            </div>

                            <div className="col my-1">
                                <label className="text-color font-xsss">Username</label>
                                <input className={`${error.username ? 'is-invalid' : ''} form-control`} autoComplete="off" type="text" placeholder="username" value={username} disabled={isLoading} onChange={e => setUsername(e.target.value)} />
                                {error?.username?.length > 0 && <ul className='w-100'>
                                    {error.username.map((err, idx) => <li key={idx} className="help-block text-danger font-xssss ms-1">{err}</li>)}
                                </ul>}
                            </div>

                            <CheckAndSetPasswords
                                error={error}
                                password={password}
                                setPassword={setPassword}
                                setValidPassword={setValidPassword}
                            />

                            <p className="text-color-lighter font-xssss mt-4">By clicking Sign up, you agree to our 
                                <Link to={"/terms"}> Terms </Link> and <Link to={"/privacy"}> Privacy Policy</Link>.
                            </p>

                            <p className="text-color-lighter font-xssss mt-2">This site is protected by reCAPTCHA and the Google
                                <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer"> Privacy Policy</a> and
                                <a href="https://policies.google.com/terms" target="_blank" rel="noreferrer"> Terms of Service</a> apply.
                            </p>

                            {/* signup */}
                            <div className="d-grid mt-4">
                                <button type="submit" className="btn btn-primary fw-500 p-2 px-3" disabled={isLoading}>
                                    {isLoading && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}
                                    Sign up
                                </button>
                            </div>

                        </form>

                        <hr />

                        <div className="d-grid mb-2">
                            <a href={googleAuthLink} className="d-flex btn btn-outline-secondary font-xsss p-2 px-3" >
                                <img className="me-auto" style={{ width: "1.3em", height: "1.3em", marginTop: "0.1em" }} src="/logos/google.png" alt='google'/>
                                Sign up with Google
                            </a>
                        </div>

                        <div className="d-grid my-2">
                            <a href={microsoftAuthLink} className="d-flex btn btn-outline-secondary font-xsss p-2 px-3" >
                                <img className="me-auto" style={{ width: "1.3em", height: "1.3em", marginTop: "0.1em" }} src="/logos/microsoft.png" alt='microsoft'/>
                                Sign up with Microsoft
                            </a>
                        </div>

                        {/* <hr /> */}
                        <div className="mt-3">
                            <p className="mb-0 text-color font-xsss text-center">Have an account already?  <Link className="ms-1 fw-500" to={"/login"}> Login</Link></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
