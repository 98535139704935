
import ContentLoader from "react-content-loader"

export default function Loader({ children, width="100%",height,speed=2,viewBox }) {

    return (
        <ContentLoader
            speed={speed}
            width={width}
            height={height}
            viewBox={viewBox}
            backgroundColor={getComputedStyle(document.body).getPropertyValue('--background-color-light')}
            foregroundColor={getComputedStyle(document.body).getPropertyValue('--background-color-lightest')}
        >
            {children}
        </ContentLoader>

    )
}