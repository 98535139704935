import { useEffect } from 'react'
import FriendsDirectory from './FriendsDirectory'

export default function FindFriends() {


    useEffect(() => {
        document.title = 'find friends'
    })

    return (
        <div className="main-content right-chat-active">
            <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left">

                    <div className="background-color-lightest rounded-xxl p-3 py-4">

                        <h5 class="text-color-light fw-500"> Find Friends</h5>
                        <FriendsDirectory endpoint='/friends/find/' notFoundMessage='No friends found.' />
                    </div>


                </div>
            </div>
        </div>

    )
}