import { useState, useEffect, useRef, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { eventEmitter } from '../events'


export default function useWsFetchWithInfiniteScroll(command, payload) {

    const ws = useSelector((state) => state.messagesReducer.chatWs)

    const [resultsPages, setResultsPages] = useState({ current: 1, next: null, previous: null })
    const [results, setResults] = useState({ results: [], isLoading: true, error: null })



    useEffect(() => {
        eventEmitter.subscribe(command, message => {
            setResultsPages(prev => { return { ...prev, next: message.next, previous: message.previous } })

            if (message.page === 1) {
                setResults(prev => {
                    return {
                        results: message.results,
                        isLoading: false,
                        error: null
                    }
                })
            } else {
                setResults(prev => {
                    return {
                        results: [...prev.results, ...message.results],
                        isLoading: false,
                        error: null
                    }
                })
            }
        })
        return () => { eventEmitter.unsubscribe(command) }
    }, [])


    // handle inifinte scroll 
    const observer = useRef()
    const lastItemRef = useCallback(
        (node) => {
            if (observer.current) {
                observer.current.disconnect()
            }
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && resultsPages.next) {
                    setResultsPages(prev => { return { ...prev, current: prev.next } })
                }
            })
            if (node) observer.current.observe(node)
        },
        [resultsPages.current, resultsPages.next]
    )




    // handle send command with payload
    useEffect(() => {
        if (ws) {
            try {
                ws.send(JSON.stringify({ command, payload, page: resultsPages.current }))
            } catch (err) {
                // fall silently
            }
        }
    }, [ws, resultsPages.current])



    return { ...results, setResults, lastItemRef }
}
