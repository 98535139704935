import { useState, useEffect, useRef, Component } from 'react'
import { NavLink } from "react-router-dom"
import { useSelector } from 'react-redux'
import axios from 'axios'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'

import useCloseWhenClickOut from '../../hooks/useCloseWhenClickOut'
import ContentEditor from '../../editor/ContentEditor'
import Modal from '../../components/Modal'
import Loader from '../../components/Loader'
import { FaThumbsUp, FaCommentAlt, FaShare, FaPencilAlt, FaTrash, FaExclamationCircle } from 'react-icons/fa'
import { MdOutlineMoreHoriz } from "react-icons/md"

export default function Comment({ loading, comment, updateComments, isAuthenticated }) {
    let componentComment = useRef(comment)

    const createdAtDateFormat = 'YYYY-MM-DD HH:mm:ss'
    const [isLoading, setIsLoading] = useState(loading)
    const [error, setError] = useState('')
    const [currentComment, setCurrentComment] = useState(componentComment.current)
    const [editMode, setEditMode] = useState(false)
    const [editorCount, setEditorCount] = useState(0)
    const [showCommentOptions, setShowCommentOptions] = useState(false)

    const user = useSelector((state) => state.userReducer.user)

    const modalRef = useRef(null)
    const commentOptionsRef = useRef(null)

    // handle close popups when click outside the popup element
    useCloseWhenClickOut(commentOptionsRef, setShowCommentOptions)

    const source = axios.CancelToken.source()


    // handle errors
    useEffect(() => {
        if (error) {
            toast.error(error?.response?.data?.non_field_errors?.toString() || error?.message || 'An unknown error occurred.')
            setError('')
        }
    }, [error])


    const handleEditBtn = () => {
        setEditorCount(prev => prev + 1)
        setEditMode(true)
        setShowCommentOptions(false)
    }

    const handleCancelBtn = () => {
        setCurrentComment(componentComment.current)
        setEditorCount(prev => prev + 1)
        setEditMode(false)
        setShowCommentOptions(false)
    }

    const handleDeleteBtn = () => {
        modalRef.current.open()
        setShowCommentOptions(false)
    }

    // edit comment
    const handleUpdateComment = async(content, formattedContent, contentWithEmojis, mentions, filteredUploadedFiles, urlPreviews) => {
        try {
            setIsLoading(true)
            const response = await axios.patch(`/newsfeed/comment/${currentComment.id}/`, {
                content: content,
                formatted_content: formattedContent,
                url_previews: urlPreviews,
            }, {
                cancelToken: source.token
            })

            if (response?.status === 204) {
                setCurrentComment(prev => {
                    const updatedComment = { ...prev, content: content, formatted_content: formattedContent, url_previews: urlPreviews, edited_at: new Date().getTime() }
                    componentComment.current = updatedComment
                    return updatedComment
                })
                setEditMode(false)
                return ({ message: 'Comment Successfully updated' })

            }

        } catch (err) {
            if (axios.isCancel(err)) return
            setError(err)
            throw Error(err.response?.data?.non_field_errors)
        } finally {
            setIsLoading(false)
        }
    }


    // delete comment
    const handleDeleteComment = async() => {
        try {
            setIsLoading(true)
            const response = await axios.delete(`/newsfeed/comment/${currentComment.id}/`, {
                cancelToken: source.token
            })

            if (response?.status === 204) {
                updateComments(comment, 'delete')
            }

        } catch (err) {
            if (axios.isCancel(err)) return
            setError(err)
        } finally {
            setIsLoading(false)
            modalRef.current.close()
        }
    }


    useEffect(() => {
        return () => source.cancel()
    }, [])


    return (
        <>
            {isLoading ?

                <Loader height={85}>
                    <circle cx="28" cy="32" r="23" />
                    <rect x="60" y="7" rx="15" ry="15" width="90%" height="70" />
                </Loader>

                :
                <div className="p-1 my-1 d-flex position-relative">
                    <figure className="me-2"><img src={currentComment.user.profile.thumbnail} alt="user" className="shadow-sm rounded-circle w45" /></figure>

                    <div className="card-body p-0 d-flex background-color-lighter rounded-xxl">
                        {/* comment */}
                        <div className={`${editMode ? null : ' mx-2'} px-3 py-2 ms-auto flex-fill`}>
                            {/* name and time */}
                            <NavLink className="font-xssss fw-600" to={`/${currentComment.user.username ? currentComment.user.username : currentComment.user.id}`} > {currentComment.user.profile.full_name} </NavLink>
                            <span className="font-xssss mt-1 lh-3 text-color-lighter"> {dayjs(currentComment.created_at).fromNow()}</span>
                            {currentComment.edited_at && <span className="font-xsssss fw-500 mx-2 lh-3 text-color-lightest" title={dayjs(currentComment.edited_at).format('MMM D, YYYY h:mm A')}> Edited </span>}


                            {/* view comment */}
                            <div className='text-color m-0'>
                                <ContentEditor
                                    key={editorCount}
                                    formattedContent={currentComment.formatted_content}
                                    // setContent={setCurrentComment}
                                    clampOptions={{ withToggle: true }}
                                    readOnly={!editMode}
                                    enableEmoji={true}
                                    enableMention={true}
                                    removeMentionPrefix={false}
                                    enableHashtag={true}
                                    enableUrlPreview={!editMode}
                                    stripPastedStyles={true}
                                    action={'UPDATE'}
                                    submitButtonName={"Update"}
                                    handleSubmit={handleUpdateComment}
                                />
                            </div>
                        </div>

                        {/* options wrapper */}
                        <div className="position-relative ms-auto mt-1 justify-content-end" ref={commentOptionsRef}>
                            {/* cancel btn*/}
                            {editMode && <span onClick={handleCancelBtn} className="d-block pointer text-color-lighter p-2 font-xs"> &#x2715; </span>}

                            {/* options btn */}
                            <span onClick={() => setShowCommentOptions(prev => !prev)} className="ms-auto pointer" >
                                <span className="text-color-lighter  p-2 me-2 font-sm"> <MdOutlineMoreHoriz /></span>
                            </span>

                            {/* options popup */}
                            <div className={`${showCommentOptions ? "show" : null} top-50 end-50 w300 background-color-lightest dropdown-menu p-4 rounded-xxl`}>

                                {/* check if user is the author */}
                                {isAuthenticated && comment.user.id === user.id ?
                                    <>
                                        {/* edit */}
                                        {!editMode &&
                                            <div onClick={handleEditBtn} className="pointer p-0 d-flex">
                                                < FaPencilAlt className="text-color mt-2 me-3 font-md" />
                                                <div>
                                                    <span className="fw-500 text-color font-xssss mt-0 me-4 pointer">
                                                        Edit
                                                        <span className="d-block font-xsssss fw-500 mt-1 text-color-lighter">Edit this comment</span>
                                                    </span>
                                                </div>
                                            </div>}


                                        {/* cancel edit */}
                                        {editMode && <div onClick={handleCancelBtn} className="pointer p-0 d-flex">
                                            <span className="text-color-lighter  me-3 font-md mt-0"> &#x2715; </span>
                                            <div>
                                                <span className="fw-500 text-color font-xssss mt-0 me-4">
                                                    Cancel
                                                    <span className="d-block font-xsssss fw-500 mt-1 text-color-lighter">Cancel editing this comment</span>
                                                </span>
                                            </div>
                                        </div>}


                                        {/* delete */}
                                        <div onClick={handleDeleteBtn} className="pointer p-0 d-flex">
                                            <FaTrash className="text-color mt-2 me-3 font-md" />
                                            <div>
                                                <span className="fw-500 text-color font-xssss mt-0 me-4 pointer">
                                                    Delete <span className="d-block font-xsssss fw-500 mt-1 text-color-lighter">Delete this comment</span>
                                                </span>
                                            </div>
                                        </div>

                                        {/* confirmation modalRef */}
                                        <Modal
                                            title="Are you sure you want to delete this comment?"
                                            actionBtns={[
                                                { text: "Cancel", classes: "btn text-color font-xssss p-2 px-3 me-2", closeOnClick: true, onClick: null },
                                                { text: "Yes", classes: "btn btn-primary font-xssss text-white p-2 px-3 me-2", onClick: handleDeleteComment }
                                            ]}
                                            ref={modalRef}>
                                        </Modal>

                                    </> :
                                    <>
                                        {/* report */}
                                        <div className="pointer p-0 d-flex">
                                            <FaExclamationCircle className="text-color-lighter pt-1 me-3 font-md" />
                                            <div>
                                                <span className="fw-500 text-color font-xssss mt-0 me-4 pointer">
                                                    Report
                                                    <span className="d-block font-xsssss fw-500 mt-1 text-color-lighter">Report this comment</span>
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                }

                            </div>

                        </div>

                    </div>
                </div>
            }
        </>
    )
}