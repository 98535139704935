
import { Emoji } from 'emoji-mart'

export default function EmojiComponent({ decoratedText, children }) {

    return (
        <span>
            <Emoji
                set='twitter'
                size={18}
                emoji={decoratedText}
                fallback={() => <span>{children}</span>}>
                <span>{children}</span>
            </Emoji>
        </span>
    )

}