import { useState, useRef } from 'react'
import { createPortal } from "react-dom"
import { Picker } from 'emoji-mart'
import { usePopper } from 'react-popper'
import { FaRegSmile } from 'react-icons/fa'
import { getUserTheme } from '../utils/utils'
import useCloseWhenClickOut from '../hooks/useCloseWhenClickOut'


export default function EmojiPickerComponent({ user, handleAddingEmoji, insidePopup }) {
    const emojiTriggerRef = useRef(null)
    const emojiPickerRef = useRef(null)

    const [showEmojis, setShowEmojis] = useState(false)
    const [referenceElement, setReferenceElement] = useState(null)
    const [popperElement, setPopperElement] = useState(null)

    useCloseWhenClickOut(emojiPickerRef, setShowEmojis, emojiTriggerRef)

    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [120, 13],
                },
            },
        ],
    })

    return (
        <>
            <span className="d-flex align-items-center font-xssss fw-500 ls-1 text-color-lighter me-4 mt-3" ref={emojiTriggerRef}>
                <span className="pointer" onClick={() => setShowEmojis(prev => !prev)} ref={setReferenceElement}>
                    <FaRegSmile className={`${!showEmojis ? 'text-warning' : 'text-grey'} font-md me-2`} />
                    <span className="d-none-xs">Emojis</span>
                </span>
            </span>


            {showEmojis && createPortal(
                <div ref={emojiPickerRef}>
                    <span
                        ref={setPopperElement}
                        style={{ ...styles.popper, zIndex: insidePopup ? 101 : 3 }}
                        {...attributes.popper}
                    >
                        <Picker
                            set='twitter'
                            color={getComputedStyle(document.body).getPropertyValue('--bs-primary')}
                            theme={getUserTheme(user) === 'DARK' ? 'dark' : 'light'}
                            showPreview={false}
                            showSkinTones={false}
                            enableFrequentEmojiSort={false}
                            onSelect={emoji => { handleAddingEmoji(emoji); setShowEmojis(false) }}
                        />
                    </span>
                </div>,
                document.querySelector('#root')
            )}

        </>
    )
}