import { useState, useEffect } from 'react'
import { useParams, useHistory } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import { toast } from 'react-toastify'


export default function EmailVerificationView() {

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')

    const user = useSelector((state) => state.userReducer.user)
    const dispatch = useDispatch()
    const history = useHistory()

    // we can get those from props as well props.match.params
    const { uid, token } = useParams()


    useEffect(() => {
        if (error) {
            toast.error(error.response?.data?.non_field_errors?.toString() || error.response?.data?.token?.toString() || error?.message  || 'An unknown error occurred.')
            setError('')
        }
    }, [error])


    useEffect(() => {
        document.title = 'Email verification'
        const source = axios.CancelToken.source()

        async function fetchData() {
            
            try {
                setIsLoading(true)
                // pass route params
                const response = await axios.post('/accounts/email_verification/', { uid, token }, {
                    cancelToken: source.token
                })

                if (response?.status === 204) {
                    toast.success('Email successfully verified.')
                    dispatch({ type: 'UPDATE_USER', user: { ...user, is_email_verified: true } })
                }

            } catch (err) {
                if (axios.isCancel(err)) return
                setError(err)
            } finally {
                setIsLoading(false)
                history.push('/login')
            }
        }

        fetchData()

        return () => {
            return source.cancel()
        }

    }, [uid, token])

    return null
}
