import dayjs from 'dayjs'
import { useParams, NavLink } from "react-router-dom"
import ProfileImage from "./ProfileImage"
import ProfileBackgroundImage from "./ProfileBackgroundImage"
import Friend from "../friends/Friend"
import Loader from '../components/Loader'

import { MdMailOutline, MdOutlineMoreHoriz } from "react-icons/md"
import { MdHouse, MdOutlineWork, MdLocationPin, MdLink, MdCalendarToday } from "react-icons/md"

export default function ProfileAbout({ user, isMyProfile }) {

    return (
        <div className="card border-0 p-3 py-2 rounded-xxl">


            <div className="card-body d-block p-4">
                <h4 className="fw-500 mb-3 font-xsss text-color-lighter">Bio</h4>
                <p className="fw-500 text-color lh-24 font-xssss mb-0">{user?.profile?.bio}</p>
            </div>
            {/* <div className="card-body border-top d-flex">
                        <MdOutlineWork className="text-color-lighter me-2 font-lg" />
                        <h4 className="fw-500 text-color-lighter font-xssss ">Work <span className="d-block font-xssss fw-500 mt-1 lh-3 text-color">What's up, how are you?</span></h4>
                    </div> */}
            <div className="card-body d-flex pt-0">
                <MdLocationPin className="text-color-lighter me-2 font-lg" />
                <h4 className="fw-500 text-color-lighter font-xssss"> Lives<span className="d-block font-xssss mt-1 lh-3 text-color">{user?.profile?.current_city}</span> </h4>
            </div>

            <div className="card-body d-flex pt-0">
                <MdHouse className="text-color-lighter me-2 font-lg" />
                <h4 className="fw-500 text-color-lighter font-xssss ">Hometown <span className="d-block font-xssss mt-1 lh-3 text-color">{user?.profile?.hometown}</span></h4>
            </div>

            {user?.profile?.website && <div className="card-body pt-0 mb-3">
                <MdLink className="text-color-lighter me-2 font-lg" />
                <h4 className="fw-500 text-color-lighter font-xssss d-inline-block mb-0 lh-1">Website </h4>
                <span className="d-block ms-4 text-truncate lh-1">
                    <a className="font-xssss ms-2 fw-500 text-color" href={user.profile.website} target='_blank' rel="noreferrer">{user.profile.website}</a>
                </span>
            </div>}

            {user?.created_at && <div className="card-body d-flex pt-0">
                <MdCalendarToday className="text-color-lighter me-2 font-lg" />
                <h4 className="fw-500 text-color-lighter font-xssss">Joined <span className="d-block font-xssss mt-1 lh-3 text-color">{dayjs(user.created_at).format('MMMM D, YYYY')}</span></h4>
            </div>}

        </div>


    )
}
