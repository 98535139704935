import { useState, useEffect, useRef, useCallback } from 'react'
import axios from "axios"


export default function useFetchWithInfiniteScroll(url) {
    const [urls, setUrls] = useState({ current: url, next: null, previous: null })
    const [results, setResults] = useState({ results: [], isLoading: true, error: null })

    useEffect(() => {
        setUrls({ current: url, next: null, previous: null })
        setResults({ results: [], isLoading: true, error: null })
    }, [url])


    // handle infinite scroll
    const observer = useRef()
    const lastItemRef = useCallback(
        (node) => {
            if (results.isLoading) return

            if (observer.current) {
                observer.current.disconnect()
            }
            observer.current = new IntersectionObserver((entries) => {

                if (entries[0].isIntersecting && urls.next) {
                    setUrls(prev => {
                        return {
                            ...prev,
                            current: prev.next
                        }
                    })

                }
            })
            if (node) observer.current.observe(node)
        },
        [results.isLoading, urls.current]
    )


    // call api 
    useEffect(() => {
        const source = axios.CancelToken.source()

        const fetchData = async() => {
            try {
                setResults(prev => ({ ...prev, isLoading: true, error: null }))
                const response = await axios.get(urls.current, {
                    cancelToken: source.token
                })

                if (response?.status === 200) {
                    setUrls(prev => {
                        return {
                            ...prev, next: response.data.next,
                            previous: response.data.previous
                        }
                    })
                    setResults(prev => {
                        return {
                            results: [...prev.results, ...response.data.results],
                            isLoading: false,
                            error: null
                        }
                    })
                }

            } catch (err) {
                if (axios.isCancel(err)) return
                setResults({ ...results, isLoading: false, error: null })
            }
        }


        if (urls.current) fetchData()

        return () => {
            return source.cancel()
        }

    }, [urls.current])


    return { ...results, setResults, lastItemRef }
}
