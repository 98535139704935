import { useSelector } from 'react-redux'
import { Route, Redirect } from "react-router-dom"

export default function CheckRoute({ hideOnLogin, requiresAuth, children, ...rest }) {
    const isAuthenticated = useSelector((state) => state.userReducer.isAuthenticated)

    return (
        <Route
            {...rest}
            render={({ location }) => {

                if (!isAuthenticated && requiresAuth) {
                    return <Redirect to={{ pathname: '/login', state: { from: location } }} />

                } else if (isAuthenticated && hideOnLogin) {
                    return <Redirect to={{ pathname: '/' }} />

                } else {
                    return children
                }
            }}
        />
    )
}
