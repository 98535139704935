import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams, NavLink, Switch, Route } from "react-router-dom"

import ChangePassword from "./ChangePassword"
import UserForm from "./UserForm"
import ChangeUsername from "./ChangeUsername"
import ChangeEmail from "./ChangeEmail"


export default function Settings() {
    const user = useSelector((state) => state.userReducer.user)

    useEffect(() => {
        document.title = 'settings'
    })

    return (
        <div className="main-content right-chat-active">
            <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left">
                    <div className="row m-0">

                        <div className="col-lg-4">

                            <div className="card w-100 shadow-xss rounded-xxl border-0 pt-3">
                                <NavLink to="/settings/" exact className="card-body d-flex pt-0" activeClassName="active">
                                    <h4 className="fw-500 text-color font-xssss mt-1">Profile <span className="d-block font-xssss fw-500 mt-1 lh-3 text-color-lighter">Change info</span></h4>
                                </NavLink>

                                <NavLink to="/settings/username" exact className="card-body d-flex pt-0" activeClassName="active">
                                    <h4 className="fw-500 text-color font-xssss mt-1">Username <span className="d-block font-xssss fw-500 mt-1 lh-3 text-color-lighter">Change Username</span></h4>
                                </NavLink>

                                <NavLink to="/settings/email" exact className="card-body d-flex pt-0" activeClassName="active">
                                    <h4 className="fw-500 text-color font-xssss mt-1">Email <span className="d-block font-xssss fw-500 mt-1 lh-3 text-color-lighter">Change email</span></h4>
                                </NavLink>

                                <NavLink to="/settings/password" exact className="card-body d-flex pt-0" activeClassName="active">
                                    <h4 className="fw-500 text-color font-xssss mt-1">Password <span className="d-block font-xssss fw-500 mt-1 lh-3 text-color-lighter">Change Password</span></h4>
                                </NavLink>
                            </div>

                        </div>


                        <div className="col-lg-8">
                            <div className="background-color-lightest shadow-xss rounded-xxl p-2 pt-3" style={{ minHeight: `calc(100vh - 300px` }}>
                                <Switch>
                                    <Route path={`/settings/`} exact render={props => <UserForm user={user} {...props} />} />
                                    <Route path={`/settings/username`} exact render={props => <ChangeUsername  user={user} {...props} />} />
                                    <Route path={`/settings/email`} render={props => <ChangeEmail  user={user}  {...props} />} />
                                    <Route path={`/settings/password`} render={props => <ChangePassword  user={user}  {...props} />} />
                                </Switch>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
