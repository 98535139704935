
import { useEffect } from 'react'

export default function useCloseWhenClickOut(ref, closeFn, ignoreRef) {

    // close (set the state to false) when click outside the ref element
    useEffect(() => {
        function handleClickOutside(event) {
            // if click on ignoreRef element then won't run closeFn (used in emoji trigger)
            if (ignoreRef?.current && ignoreRef.current.contains(event.target)) return
            else if (ref?.current && !ref.current.contains(event.target)) closeFn(false)
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [ref])
}
