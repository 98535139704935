// import { Link, NavLink, useHistory, useParams } from "react-router-dom"
import { useState, useEffect, useRef, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { eventEmitter } from '../events'
import { isScrolledIntoView } from '../utils/utils'
import useFetch from '../hooks/useWsFetchWithInfiniteScroll'
// import useUploadFiles from '../hooks/useUploadFiles'
import Message from "./Message"
// import UploadFile from "../components/UploadFile"
import ContentEditor from '../editor/ContentEditor'


export default function Chat({ room, setActiveRoom, chatHeight }) {
    // const newMessageObj = { key: Math.random(), isLoading: false, error: null, contentWithEmojis: '', contentWithEmojis: '', formattedContent: undefined }
    const [newMessage, setNewMessage] = useState(null)
    const [userTyping, setUserTyping] = useState({ user: null, typing: false })
    // const [localFiles, setLocalFiles] = useState([])

    const { isLoading, error, results: messages, setResults, lastItemRef: firstMessageRef } = useFetch("fetch_room_messages", { room_id: room.id })
    // const { uploadFiles, setFileInfos, error: fileError } = useUploadFiles("chat/message/file/", localFiles, setLocalFiles, process.env.REACT_APP_CHAT_MAX_FILES_COUNT, process.env.REACT_APP_CHAT_FILE_SIZE_LIMIT)


    const user = useSelector((state) => state.userReducer.user)
    const ws = useSelector((state) => state.messagesReducer.chatWs)
    // const roomList = useSelector((state) => state.messagesReducer.roomList)


    const dispatch = useDispatch()
    const storageName = `${room.id}NewMessage`
    const timer = useRef()
    const timeoutVal = 1500
    // const messageCountPerCall = 5
    // const fileInputRef = useRef(null)

    const messagesRef = useRef(null)


    // handle ws events
    useEffect(() => {

        eventEmitter.subscribe('fetch_room_messages', payload => {
            if (payload.room_id === room.id) {
                if (setActiveRoom){
                    setActiveRoom(payload.room)
                }
            }
        })

        eventEmitter.subscribe('typing_status', payload => {
            if (payload.room_id === room.id) {
                setUserTyping({ user: payload.sender, typing: payload.typing })
            }
        })

        eventEmitter.subscribe('new_message', payload => {
            if (payload.room_id === room.id) {
                setResults(prev => {
                    return { ...prev, results: [payload.message, ...prev.results] }
                })
            }
        })

        eventEmitter.subscribe('messages_read', payload => {
            if (payload.room_id === room.id) {
                setResults(prev => {
                    return {
                        ...prev,
                        results: prev.results.map(m => {
                            if (m.read_by.filter(i => i.user.id === payload.reader.id).length || m.user.id === payload.reader.id) return m
                            return { ...m, read_by: [...m.read_by, { read_at: payload.read_at, user: payload.reader }] }
                        })
                    }
                })
            }
        })

        return () => {
            eventEmitter.unsubscribe('fetch_room_messages')
            eventEmitter.unsubscribe('typing_status')
            eventEmitter.unsubscribe('new_message')
            eventEmitter.unsubscribe('messages_read')
        }
    }, [room.id])



    // handle typing start/stop
    useEffect(() => {
        if (ws && newMessage?.contentWithEmojis) {
            try {
                if (!timer.current) {
                    ws.send(JSON.stringify({ command: "typing_status", payload: { room_id: room.id, typing: true } }))
                }
                // prevent errant multiple timeouts from being generated
                clearTimeout(timer.current)

                timer.current = setTimeout(() => {
                    ws.send(JSON.stringify({ command: "typing_status", payload: { room_id: room.id, typing: false } }))
                    timer.current = null
                }, timeoutVal)

            } catch (err) {
                // fall silently
            }
        }
    }, [newMessage?.contentWithEmojis, ws])


    // handle send "read messages" report if any message is unread 
    useEffect(() => {
        if (ws && messages && room.id) {
            let unreadCount = 0
            let unread = false

            for (let i = 0; i < messages.length; i++) {
                if (messages[i].user.id !== user.id) {

                    let userReadit = false
                    if (messages[i].read_by.length) {
                        messages[i].read_by.forEach(i => {
                            if (i.user.id === user.id) {
                                userReadit = true
                            }
                        })
                    }

                    if (!userReadit) {
                        unread = true
                        unreadCount++
                    }
                }
            }
            // make sure the page is visible 
            if (unread && !document.hidden) {
                ws.send(JSON.stringify({ command: "messages_read", payload: { room_id: room.id } }))
                dispatch({ type: 'RESET_ROOM_UNREAD_COUNT', room: room })

                // update local messages with read status
                setResults(prev => {
                    return {
                        ...prev,
                        results: prev.results.map(m => {
                            if (m.read_by.filter(i => i.user.id === user.id).length || m.user.id === user.id) return m
                            return { ...m, read_by: [...m.read_by, { read_at: new Date().toISOString(), user: user }] }
                        })
                    }
                })
                // console.log(-unreadCount)
                dispatch({ type: 'UPDATE_MESSAGES_NOTIFICATION', count: -unreadCount })
            }
        }
    }, [messages, newMessage?.contentWithEmojis])


    // // handle send new message
    // const handleSendMessage = async () => {

    //     // stop sending message if it's uploading or there's file error
    //     for (let i = 0; i < uploadFiles.length; i++) {
    //         if (uploadFiles[i].uploading || uploadFiles[i].error) {
    //             return
    //         }
    //     }


    //     setNewMessage(prev => ({ ...prev, isLoading: true, error: '' }))

    //     const messageUploadedFiles = uploadFiles.filter(f => f.url)

    //     if (newMessage.contentWithEmojis && messages || messageUploadedFiles.length) {

    //         try {

    //             ws.send(JSON.stringify({
    //                 command: "new_message", payload: {
    //                     content: newMessage.content,
    //                     formatted_content: newMessage.formattedContent,
    //                     files_id: [...messageUploadedFiles.map(f => f.id)],
    //                     room_id: room.id
    //                 }
    //             }))
    //             // if (room.id !== user.id) {
    //             const formattedMessage = {
    //                 id: Math.random(),
    //                 user: user,
    //                 content: newMessage.content,
    //                 formatted_content: newMessage.formattedContent,
    //                 files: messageUploadedFiles,
    //                 created_at: new Date().toISOString(),
    //                 read_by: [],
    //                 sent: false
    //             }

    //             setResults(prev => {
    //                 return {
    //                     ...prev,
    //                     results: [formattedMessage, ...prev.results]
    //                 }
    //             })
    //             dispatch({ type: 'UPDATE_LAST_MESSAGE', payload: { message: formattedMessage, room_id: room.id, sender: { id: user.id }, local: true } })

    //             window.localStorage.setItem(storageName, '')
    //             setNewMessage(newMessageObj)
    //             setLocalFiles([])
    //             setFileInfos([])
    //         } catch (err) {

    //             console.log(err)
    //             // setError(JSON.stringify(err.response?.data))
    //             setNewMessage(prev => ({ ...prev, isLoading: false, error: err?.response }))
    //             // setIsLoading(false)
    //         }
    //     }

    // }


    // handle send new message
    const handleSendMessage = async(content, formattedContent, contentWithEmojis, mentions, filteredUploadedFiles, urlPreviews) => {

        try {

            ws.send(JSON.stringify({
                command: "new_message", payload: {
                    content: content,
                    formatted_content: formattedContent,
                    files_id: [...filteredUploadedFiles.map(f => f.id)],
                    url_previews: urlPreviews,
                    room_id: room.id
                }
            }))
            // if (room.id !== user.id) {
            const formattedMessage = {
                id: Math.random(),
                user: user,
                content: content,
                formatted_content: formattedContent,
                files: filteredUploadedFiles,
                url_previews: urlPreviews,
                created_at: new Date().toISOString(),
                read_by: [],
                sent: false
            }

            setResults(prev => {
                return {
                    ...prev,
                    results: [formattedMessage, ...prev.results]
                }
            })
            dispatch({ type: 'UPDATE_LAST_MESSAGE', payload: { message: formattedMessage, room_id: room.id, sender: { id: user.id }, local: true } })
            // scroll to the bottom
            messagesRef.current.scrollTop = 0

            // window.localStorage.setItem(storageName, '')
            // setNewMessage(newMessageObj)
            // setLocalFiles([])
            // setFileInfos([])
            // return ({ message: 'message successfully sent.' })
        } catch (err) {

            throw Error(err)
            // setError(JSON.stringify(err.response?.data))
            // setIsLoading(false)
        }

    }




    const updateMessages = (message, action) => {
        if (action === 'add') {
            setResults({ ...messages, results: [...messages.results, message] })
        }
        else if (action === 'delete') {
            setResults({ ...messages, results: [...messages.results.filter(item => message.id !== item.id)] })
        }
    }


    return (
        <div className='d-flex flex-column' style={{ height: `calc(100vh - ${chatHeight})` }} >


            <div className="d-flex flex-column-reverse overflow-auto flex-grow-1" ref={messagesRef}>

                <div className="p-3 d-flex flex-column-reverse">
                    {messages && messages.length > 0 && messages.map((message, index) => {
                        const position = message.user.id === user.id ? 'end' : 'start'
                        return (<div ref={(index === messages.length - 1) ? firstMessageRef : null} key={message.id}>
                            <Message message={message} updateMessages={updateMessages} position={position} />
                        </div>)
                    }
                        // <div className={`${position=== "start"? "justify-content-start": "justify-content-end"} d-flex flex-row mb-4 pt-1 `}>

                    )}
                </div>
            </div>

            {(ws?.readyState ===1) && <div className="position-relative px-3 shadow-none border-top">
                {userTyping.typing && <span className='position-absolute text-color-lighter font-xsssss' style={{top:'-22px'}}>{userTyping.user.profile.first_name} is typing...</span>}
                <ContentEditor
                    // key={newMessage.key}
                    // formattedContent={newMessage.formattedContent}
                    setContent={setNewMessage}
                    storageName={storageName}
                    placeHolder="Send a message…"
                    readOnly={false}
                    enableTextFormat={true}
                    stripPastedStyles={true}
                    enableEmoji={true}
                    enableUrlPreview={true}
                    enableUploadFiles={true}
                    uploadFilesEndpoint={"chat/message/file/"}
                    enableEnterKeyToSubmit={true}
                    submitButtonName={'Send'}
                    handleSubmit={handleSendMessage}
                />

            </div>}
        </div>

    )
}