import { useEffect, useState, useRef } from 'react'
import { Link, NavLink, useHistory } from "react-router-dom"
import { useSelector } from 'react-redux'
import useCloseWhenClickOut from '../hooks/useCloseWhenClickOut'
import useLogout from "../hooks/useLogout"
import SVGWrapper from '../components/SVGWrapper'
import Logo from '../SVGIcons/Logo'

import { FaUserFriends, FaCommentAlt, FaGlobeAmericas, FaCaretDown } from 'react-icons/fa'
import { MdSearch, MdPeople, MdModeComment, MdOutlinePublic, MdSettings, MdLogout, MdMenu, MdHome, MdPerson, MdPersonSearch, MdChat, } from 'react-icons/md'

import Theme from '../components/Theme'


export default function Header() {
    const [query, setQuery] = useState('')

    const friendNotifications = useSelector((state) => state.notificationReducer.friends)
    const messageNotifications = useSelector((state) => state.notificationReducer.messages)
    const generalNotifications = useSelector((state) => state.notificationReducer.general)

    const isAuthenticated = useSelector((state) => state.userReducer.isAuthenticated)
    const user = useSelector((state) => state.userReducer.user)
    const theme = isAuthenticated ? user.theme : (localStorage.getItem('theme') || 'AUTO')

    const history = useHistory()
    const logout = useLogout()

    const handleSearch = async(e) => {
        if (e) e.preventDefault()
        history.push(`/search?q=${query}`)
        setQuery('')
    }

    const [friendNotificationsClasses, setFriendNotificationsClasses] = useState('')
    const [messageNotificationsClasses, setMessageNotificationsClasses] = useState('')
    const [generalNotificationsClasses, setGeneralNotificationsClasses] = useState('')
    const [showMobileNavbar, setShowMobileNavbar] = useState(false)

    const [showHeaderOptions, setShowHeaderOptions] = useState(false)

    const headerOptionsRef = useRef(null)
    const navbarRef = useRef(null)

    useCloseWhenClickOut(navbarRef, setShowMobileNavbar)
    // handle close popups when click outside the popup element
    useCloseWhenClickOut(headerOptionsRef, setShowHeaderOptions)


    useEffect(() => {
        setFriendNotificationsClasses(`${friendNotifications > 0 ? 'header-notification-highlight' : 'header-notification'} font-lg`)
        setMessageNotificationsClasses(`${messageNotifications > 0 ? 'header-notification-highlight' : 'header-notification'} font-lg`)
        setGeneralNotificationsClasses(`${generalNotifications > 0 ? 'header-notification-highlight' : 'header-notification'} font-lg`)
    }, [friendNotifications, messageNotifications, generalNotifications])


    return (
        <div className="nav-header shadow-xs border-0">

            {/* logo */}
            {/* <Link to="/"><span className="d-none d-md-inline-block fredoka-font ls-3 fw-500 text-current font-xxl logo-text ms-4 mb-0">Social Media</span> </Link> */}
            {/* <Link to="/"><img src="/logos/cmuna.png" className="d-none d-md-inline-block ms-5" style={{ maxWidth: 120, }} /></Link> */}
            <Link to="/" className="d-none d-md-inline-block" style={{ width: 170, }}>
                <SVGWrapper viewBox="-350 0 1500 200" width={170}>
                    <Logo />
                </SVGWrapper>
            </Link>

            {/* mobile logo */}
            {/* <Link to="/"><span className="d-inline-block d-md-none fredoka-font ls-3 fw-500 text-current font-xxl logo-text ms-4 mb-0">S</span> </Link> */}
            <Link to="/"><img src="/logos/favicon.png" className="d-inline-block d-md-none ms-3" style={{ maxWidth: 30, }} alt="cmuna"/></Link>

            {/* search TODO: fix the db*/}
            {!isAuthenticated && <form onSubmit={handleSearch} className="d-none d-lg-block header-search mx-auto">
                <div className="form-group mb-0 icon-input mx-3">
                    <span className="text-color-light header-search-icon"><MdSearch /></span>
                    <input type="text" value={query} onChange={e => setQuery(e.target.value)} placeholder="Search" className="background-color-light border-0 lh-32 py-1 ps-5 pe-3 font-xsss rounded-xl" />
                </div>
            </form>}

            {isAuthenticated &&
                <>
                    {/* search */}
                    <form onSubmit={handleSearch} className="header-search mx-auto">
                        <div className="form-group mb-0 icon-input mx-3">
                            <span className="text-color-light header-search-icon"><MdSearch /></span>
                            <input type="text" value={query} onChange={e => setQuery(e.target.value)} placeholder="Search" className="background-color-light border-0 lh-32 py-1 ps-5 pe-3 font-xsss rounded-xl" />
                        </div>
                    </form>


                    {/* profile */}
                    <NavLink to={`/${user.username ? user.username : user.id}`} className="d-none d-md-inline-block text-color-light text-center me-lg-2 me-xl-4 px-1 header-profile" activeClassName="active">
                        <span className=" d-inline-block font-xsss fw-500 text-color">
                            <img src={user.profile.thumbnail} alt="user" className="d-inline-block rounded-circle w30 border me-1" />
                            {user.profile?.first_name}
                        </span>
                    </NavLink>


                    <span className="d-none d-lg-block">
                        {/* friends notifications */}
                        {/* <span className={`p-2 pointer text-center ms-auto menu-icon ${notiClass}`} id="dropdownMenu3" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => setIsNoti(prev => !prev)}> */}
                        <span className="p-2 pointer  text-center menu-icon">
                            <NavLink to={"/friends"} exact className="notification-btn" activeClassName="active"><MdPeople className={friendNotificationsClasses} />
                                {friendNotifications > 0 && <span className="notification-count">{friendNotifications}</span>}
                            </NavLink>
                        </span>

                        {/* messages */}
                        <span className="p-2 pointer text-center ms-3 menu-icon">
                            <NavLink to={"/messages"} className="notification-btn" activeClassName="active"><MdModeComment className={messageNotificationsClasses} />
                                {messageNotifications > 0 && <span className="notification-count">{messageNotifications}</span>}
                            </NavLink>
                        </span>

                        {/* general notifications */}
                        <span className="p-2 pointer text-center ms-3 menu-icon">
                            <NavLink to={"/notifications"} className="notification-btn" activeClassName="active"> <MdOutlinePublic className={generalNotificationsClasses} />
                                {generalNotifications > 0 && <span className="notification-count">{generalNotifications}</span>}
                            </NavLink>
                        </span>
                    </span>

                </>}


            {!isAuthenticated &&
                <div className='ms-auto ms-lg-0 font-xsss'>
                    <NavLink to="/signup" className="d-inline-block text-color-main header-profile me-4"><span className="p-2 px-3 fw-500">Signup </span></NavLink>
                    <NavLink to="/login" className="d-inline-block text-color-main header-profile me-2"><span className="p-2 px-3 fw-500">Login </span></NavLink>
                </div>}


            <span className="position-relative pointer text-center menu-icon border-start ps-3 px-lg-3 ms-2 ms-lg-2 ms-xl-5" ref={headerOptionsRef}>
                {/* desktop dropdown btn */}
                <FaCaretDown onClick={() => setShowHeaderOptions(prev => !prev)} className='header-notification font-lg d-none d-lg-inline-block' />

                {/* mobile sidebar btn */}
                <span onClick={() => setShowMobileNavbar(prev => !prev)} className="m-1 pointer text-center d-lg-none">
                    <MdMenu className="header-notification font-lg" />
                </span>


                {/* options popup */}
                <div className={`position-absolute top-100 end-0 bg-content w300 dropdown-menu px-4 py-4 rounded-xxl shadow-lg ${showHeaderOptions ? " show" : ""}`} >

                    {/* profile */}
                    {isAuthenticated && < div className="d-sm-block d-lg-none card-body pointer p-0 d-flex">
                        <NavLink to={`/${user.username ? user.username : user.id}`} className="d-flex" activeClassName="active" onClick={() => setShowHeaderOptions(false)}>
                            <span className='pt-1 me-3'>
                                <img src={user.profile.thumbnail} alt="user" className="rounded-circle w30" />
                            </span>
                            <h5 className="m-0 p-0">
                                <span className="fw-500 text-color font-xsss mt-0 me-4 pointer"> {user.profile?.full_name} <span className="d-block font-xssss fw-500 mt-1 text-color-light"> Visit your profile</span></span>
                            </h5>

                        </NavLink>
                    </div>}



                    {/* settings */}
                    {isAuthenticated && <div className="card-body pointer p-0 d-flex">
                        <NavLink to={"/settings"} className="d-flex" onClick={() => setShowHeaderOptions(false)}>
                            <MdSettings className="text-color pt-1 me-3 font-lg" />

                            <h5 className="m-0 p-0">
                                <span className="fw-500 text-color font-xsss mt-0 me-4 pointer"> Settings <span className="d-block font-xssss fw-500 mt-1 text-color-light"> Change your profile</span></span>
                            </h5>
                        </NavLink>
                    </div>}

                    {/* theme */}
                    <span onClick={() => { setShowHeaderOptions(false) }}>
                        {!showMobileNavbar && <Theme theme={theme} isAuthenticated={isAuthenticated} />}
                    </span>



                    {/* sign out */}
                    {isAuthenticated && <div className="card-body pointer p-0 d-flex" onClick={() => { logout(); setShowHeaderOptions(false) }}>
                        <MdLogout className="text-color pt-1 me-3 font-lg" />
                        <h5 className="m-0 p-0 pt-1">
                            <span className="fw-500 text-color font-xsss mt-0 me-4 pointer"> Log Out</span>
                        </h5>
                    </div>}

                </div>
            </span>



            {/* mobile sidebar */}
            <div className={`d-lg-none navigation-wrapper ${showMobileNavbar ? 'nav-active' : ''}`}>
                <nav className={`navigation ${showMobileNavbar ? 'nav-active' : ''}`} ref={navbarRef}>
                    {/* mobile close btn */}
                    <span onClick={() => setShowMobileNavbar(false)} className="d-lg-none position-absolute pointer m-3 right-0 btn-round-sm background-color-light text-color"> &#x2715; </span>

                    <div className="container ps-0 pe-0">
                        <div className="nav-content">

                            <div className="mb-2">
                                <ul className="mb-3 pt-4 pt-lg-0">
                                    {isAuthenticated && <>
                                        <li onClick={() => setShowMobileNavbar(false)}><NavLink to="/" exact={true} className="text-color-light"><MdHome className="pt-1 me-2 font-lg" /><span>Home</span></NavLink></li>
                                        <li onClick={() => setShowMobileNavbar(false)}><NavLink to="/friends/find" className="text-color-light"><MdPersonSearch className="pt-1 me-2 font-lg" /><span>Find Friends</span></NavLink></li>
                                        <li onClick={() => setShowMobileNavbar(false)}><NavLink to="/messages" className="text-color-light"><MdChat className="pt-1 me-2 font-lg" /><span>Messages</span></NavLink></li>
                                        <li onClick={() => setShowMobileNavbar(false)}><NavLink to={`/${user.username ? user.username : user.id}`} className="text-color-light"><MdPerson className="pt-1 me-2 font-lg" /><span>Profile</span></NavLink></li>
                                        <li onClick={() => setShowMobileNavbar(false)}><NavLink to="/settings" className="text-color-light"><MdSettings className="pt-1 me-2 font-lg" /><span>Settings</span></NavLink></li>
                                    </>}
                                    <div className="mt-3 mx-3 text-color-light"><Theme theme={theme} isAuthenticated={isAuthenticated} /> </div>
                                    {isAuthenticated && <li onClick={() => { logout(); setShowMobileNavbar(false) }}><a className="text-color-light pointer"> <MdLogout className="text-color pt-1 me-3 font-lg" /><span>Logout</span></a></li>}
                                </ul>
                            </div>

                        </div>
                    </div>
                </nav>
            </div>


            {/* mobile footer */}
            {isAuthenticated &&

                <div className="app-footer border-0 shadow-lg background-color-lightest px-4">

                    <span className="m-2 pointer menu-icon">
                        <NavLink to={"/friends"} className="notification-btn" activeClassName="active"><MdPeople className={friendNotificationsClasses} />
                            {friendNotifications > 0 && <span className="notification-count">{friendNotifications}</span>}
                        </NavLink>
                    </span>

                    {/* messages */}
                    <span className="m-2 pointer ms-3 menu-icon">
                        <NavLink to={"/messages"} className="notification-btn" activeClassName="active"><MdModeComment className={messageNotificationsClasses} />
                            {messageNotifications > 0 && <span className="notification-count">{messageNotifications}</span>}
                        </NavLink>
                    </span>

                    {/* general notifications */}
                    <span className="m-2 pointer ms-3 menu-icon">
                        <NavLink to={"/notifications"} className="notification-btn" activeClassName="active"> <MdOutlinePublic className={generalNotificationsClasses} />
                            {generalNotifications > 0 && <span className="notification-count">{generalNotifications}</span>}
                        </NavLink>
                    </span>
                </div>}

        </div >
    )
}

