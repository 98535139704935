import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import Newsfeed from '../newsfeed/Newsfeed'
import PeopleYouMayKnow from './PeopleYouMayKnow'
import Events from '../components/Events'
import About from './About'

export default function Home() {
    const isAuthenticated = useSelector((state) => state.userReducer.isAuthenticated)

    useEffect(() => {
        document.title = 'cmuna'
    })

    return (
        <div className="main-content right-chat-active">
            <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left">
                    <div className="row m-0">
                        <div className="col-lg-8 p-0">
                            <Newsfeed type={isAuthenticated ? "home" : "public"} isAuthenticated={isAuthenticated}/>
                        </div>
                        <div className="col-lg-4 p-0 ps-lg-3" style={{ minHeight: "100vh"}}>
                            <div className="" style={{ position: "sticky", top: 75 }}>
                                <Events />
                                {isAuthenticated && <PeopleYouMayKnow />}
                                <About />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
