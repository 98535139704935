import { useState, useEffect } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'

export default function ResendEmailVerification({user}) {

    const [isLoading, setIsLoading] = useState(false)
    const [emailSent, setEmailSent] = useState(false)
    const [error, setError] = useState('')

    const source = axios.CancelToken.source()

    const resendActivation = async() => {
        if (isLoading) return
        
        try {
            setIsLoading(true)
            const response = await axios.post('accounts/resend_email_verification/', {
                email: user.email
            }, {
                cancelToken: source.token
            })

            if (response?.status === 204) {
                setEmailSent(true)
                toast.success('Email sent successfully.')
            }

        } catch (err) {
            if (axios.isCancel(err)) return
            setError(err)
            
        } finally {
            setIsLoading(false)
        }

    }

    useEffect(() => {
        if (error) {
            toast.error(error.response?.data?.non_field_errors?.toString() || error.response?.data?.token?.toString() || error?.message  || 'An unknown error occurred.')
            setError('')
        }
    }, [error])


    useEffect(() => {
        return () => source.cancel()
    }, [])

    return (
        <div className="position-fixed alert alert-warning text-center p-1 rounded-0" role="alert" style={{marginTop:60, width: "100%", zIndex: 2, opacity: 0.8}}>
            <span className='fw-bold font-xsss'> Email is not verified </span>
            <button disabled={isLoading || emailSent} onClick={resendActivation} className="d-inline-block btn btn-secondary font-xsss rounded-xxxl p-1 px-3 ms-2 m-1">
                <span className='d-flex align-items-center'>
                    {isLoading && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}
                    <span>Resend email</span>
                </span>
            </button>
        </div>
    )
}
