import { useState, useEffect } from 'react'
import axios from 'axios'
import ContentEditor from "../../editor/ContentEditor"


export default function CreateComment({ postId, updateComments, user }) {
    // const commentObj = { key: Math.random(), isLoading: false, message: '', error: '', content: '', contentWithEmojis: '', formattedContent: undefined, mentions: [] }

    // const [isLoading, setIsLoading] = useState(false)
    // const [error, setError] = useState('')
    // const [message, setMessage] = useState('')
    // const [commentContent, setCommentContent] = useState(commentObj)


    const source = axios.CancelToken.source()

    // create new comment
    const handleCommentSubmit = async(content, formattedContent, contentWithEmojis, mentions, filteredUploadedFiles, urlPreviews) => {

        try {
            const response = await axios.post(`/newsfeed/create_comment/${postId}/`,
                {
                    content: contentWithEmojis,
                    formatted_content: formattedContent,
                    mentions: mentions,
                    url_previews: urlPreviews,
                }, {
                    cancelToken: source.token
                })
            if (response?.status === 201) {
                updateComments(response.data, 'add')
                // return ({ message: 'Comment successfully posted.' })

            }

        } catch (err) {
            if (axios.isCancel(err)) return
            throw Error(err.response?.data?.non_field_errors)
        }

    }



    useEffect(() => {
        return () => source.cancel()
    }, [])


    return (

        <div className="d-flex mt-2 px-1">
            <figure className="d-inline-block me-2"><img src={user.profile.thumbnail} alt="user" className="shadow-sm rounded-circle w45" /></figure>
            <span className='text-color ms-auto flex-fill ml-2'>
                <ContentEditor
                    // key={commentContent.key}
                    placeHolder="Write a comment…"
                    // formattedContent={commentContent.formattedContent}
                    // readOnly={commentContent.isLoading}
                    // setContent={setCommentContent}
                    enableEmoji={true}
                    enableMention={true}
                    removeMentionPrefix={false}
                    enableHashtag={true}
                    enableUrlPreview={true}
                    enableUploadFiles={false}
                    stripPastedStyles={true}
                    enableEnterKeyToSubmit={true}
                    submitButtonName={"Comment"}
                    handleSubmit={handleCommentSubmit}
                />
            </span>
        </div>

    )

}