import { BrowserRouter, Switch, Route } from "react-router-dom"
import Header from './navbar/Header'
import CheckRoute from './components/CheckRoute'
import ScrollToTop from './components/ScrollToTop'
import ViewWrapper from './components/ViewWrapper'
import HomeView from "./home/HomeView"
import LoginView from "./accounts/LoginView"
import SignupView from "./accounts/SignupView"
import EmailVerificationView from "./accounts/EmailVerificationView"
import ResendEmailVerification from "./accounts/ResendEmailVerification"
import ForgotPasswordView from "./accounts/ForgotPasswordView"
import ResetPassword from "./accounts/ResetPasswordView"
import SettingsView from "./settings/SettingsView"
import SearchView from "./search/SearchView"
import ProfileView from "./profile/ProfileView"
import MessagesView from "./messages/MessagesView"
import FindFriendsView from "./friends/FindFriendsView"
import FriendsRequestsNotificationsView from "./friends/FriendsRequestsNotificationsView"
import NotificationsView from "./notifications/NotificationsView"
import PostView from "./newsfeed/PostView"
import HashtagView from "./newsfeed/HashtagView"
import PrivacyView from "./docs/PrivacyView"
import TermsView from "./docs/TermsView"
import NotFoundView from "./404/NotFoundView"

import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import { connectNotificationsWs, connectChatWs } from './websockets'
import { getUserTheme } from './utils/utils'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'


function App() {
    const isAuthenticated = useSelector((state) => state.userReducer.isAuthenticated)
    const user = useSelector((state) => state.userReducer.user)
    const dispatch = useDispatch()

    const getUserData = async() => {
        try {
            const response = await axios.get('/accounts/me/')
            if (response?.status === 200) {
                dispatch({ type: 'UPDATE_USER', user: response.data })
            }
        } catch (err) {
            // fall silently 
        }
    }

    useEffect(() => {
        if (isAuthenticated) {
            connectNotificationsWs()
            connectChatWs()

            // if the page is refreshed then will get the user data
            if (window.performance.getEntriesByType) {
                if (window.performance.getEntriesByType("navigation")[0].type === "reload") {
                    getUserData()
                }
            }
        }
    }, [isAuthenticated])



    return (
        <BrowserRouter>
            <ScrollToTop />
            <Header />
            <>
                {(isAuthenticated && !user?.is_email_verified) && <ResendEmailVerification user={user} />}
                <Switch>
                    <CheckRoute exact path={"/"}>
                        <ViewWrapper isAuthenticated={isAuthenticated} user={user}>
                            <HomeView />
                        </ViewWrapper>
                    </CheckRoute>

                    <CheckRoute exact path={"/signup"} hideOnLogin={true}>
                        <SignupView />
                    </CheckRoute>

                    <CheckRoute path={"/login"} hideOnLogin={true}>
                        <LoginView />
                    </CheckRoute>

                    <CheckRoute path={"/settings"} requiresAuth={true}>
                        <ViewWrapper isAuthenticated={isAuthenticated} user={user}>
                            <SettingsView />
                        </ViewWrapper>
                    </CheckRoute>

                    <CheckRoute exact path={"/search"} requiresAuth={true}>
                        <ViewWrapper isAuthenticated={isAuthenticated} user={user}>
                            <SearchView />
                        </ViewWrapper>
                    </CheckRoute>

                    <CheckRoute exact path={"/notifications"} requiresAuth={true}>
                        <ViewWrapper isAuthenticated={isAuthenticated} user={user}>
                            <NotificationsView />
                        </ViewWrapper>
                    </CheckRoute>

                    <CheckRoute exact path={"/post/:postId"}>
                        <PostView />
                    </CheckRoute>

                    <CheckRoute exact path={"/hashtag/:hashtag"}>
                        <HashtagView />
                    </CheckRoute>

                    <Route exact path={"/email-verification/:uid/:token"} component={EmailVerificationView} />

                    <CheckRoute path={"/forgot-password"} hideOnLogin={true}>
                        <ForgotPasswordView />
                    </CheckRoute>

                    <CheckRoute exact path={"/reset-password/:uid/:token"} hideOnLogin={false}>
                        <ResetPassword />
                    </CheckRoute>

                    <CheckRoute exact path={["/messages", "/messages/:roomParam"]} requiresAuth={true}>
                        <ViewWrapper isAuthenticated={isAuthenticated} user={user}>
                            <MessagesView />
                        </ViewWrapper>
                    </CheckRoute>

                    <CheckRoute exact path={"/friends"} requiresAuth={true}>
                        <ViewWrapper isAuthenticated={isAuthenticated} user={user}>
                            <FriendsRequestsNotificationsView />
                        </ViewWrapper>
                    </CheckRoute>

                    <CheckRoute exact path={"/friends/find"} requiresAuth={true}>
                        <ViewWrapper isAuthenticated={isAuthenticated} user={user}>
                            <FindFriendsView />
                        </ViewWrapper>
                    </CheckRoute>

                    <Route exact path={"/privacy"} component={PrivacyView} />
                    <Route exact path={"/terms"} component={TermsView} />

                    <CheckRoute exact path={["/:userIdOrUsername", "/:userIdOrUsername/images/:type(posts|profile|background)?", "/:userIdOrUsername/connections/:type(friends|following|followers)?", "/:userIdOrUsername/:type(about|videos|files)"]} requiresAuth={true}>
                        <ProfileView />
                    </CheckRoute>

                    <Route path={"*"} component={NotFoundView} />
                </Switch>

                <ToastContainer
                    position="top-right"
                    limit={1}
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme={(user && getUserTheme(user)) === 'DARK' ? 'dark' : 'light'}
                />
            </>
        </BrowserRouter>
    )
}

export default App
