import { FaBold, FaItalic, FaUnderline, FaStrikethrough, FaCode, FaQuoteRight, FaListOl, FaListUl, FaRegFileCode } from 'react-icons/fa'
import { MdFormatBold, MdFormatItalic, MdFormatUnderlined, MdStrikethroughS, MdFormatQuote, MdCode, MdOutlineArticle, MdFormatListBulleted, MdFormatListNumbered } from 'react-icons/md'



export const BlockStyleControls = ({ editorState, onToggle }) => {
    const BLOCK_TYPES = [
        // { label: 'H1', style: 'header-one', icon: <MdFormatBold/> },
        // { label: 'H2', style: 'header-two', icon: <MdFormatBold/> },
        // { label: 'H3', style: 'header-three', icon: <MdFormatBold/> },
        // { label: 'H4', style: 'header-four', icon: <MdFormatBold/> },
        // { label: 'H5', style: 'header-five', icon: <MdFormatBold/> },
        // { label: 'H6', style: 'header-six', icon: <MdFormatBold/> },
        { label: 'Code Block', style: 'code-block', icon: <MdOutlineArticle />, title: 'Code Block' },
        { label: 'Blockquote', style: 'blockquote', icon: <MdFormatQuote />, title: 'Blockquote' },
        { label: 'OL', style: 'ordered-list-item', icon: <MdFormatListNumbered />, title: 'Ordered list' },
        { label: 'UL', style: 'unordered-list-item', icon: <MdFormatListBulleted />, title: 'Unordered list' },
    ]

    const selection = editorState.getSelection()
    const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType()

    return (
        <span className='RichEditor-controls pointer'>
            {BLOCK_TYPES.map((type) =>
                <TextFormatButton
                    key={type.label}
                    active={type.style === blockType}
                    label={type.label}
                    onToggle={onToggle}
                    style={type.style}
                    icon={type.icon}
                    title={type.title}
                />
            )}
        </span>
    )
}



export const InlineStyleControls = ({ editorState, onToggle }) => {
    const INLINE_STYLES = [
        { label: 'Bold', style: 'BOLD', icon: <MdFormatBold />, title: 'Bold' },
        { label: 'Italic', style: 'ITALIC', icon: <MdFormatItalic />, title: 'Italic' },
        { label: 'Underline', style: 'UNDERLINE', icon: <MdFormatUnderlined />, title: 'Underline' },
        { label: 'Strikethrough', style: 'STRIKETHROUGH', icon: <MdStrikethroughS />, title: 'Strikethrough' },
        { label: 'Monospace', style: 'CODE', icon: <MdCode />, title: 'Inline Code' },
    ]

    const currentStyle = editorState.getCurrentInlineStyle()

    return (
        <span className='RichEditor-controls pointer'>
            {INLINE_STYLES.map((type) =>
                <TextFormatButton
                    key={type.label}
                    active={currentStyle.has(type.style)}
                    label={type.label}
                    onToggle={onToggle}
                    style={type.style}
                    icon={type.icon}
                    title={type.title}
                />
            )}
        </span>
    )
}


export const TextFormatButton = (props) => {
    const onToggle = (e) => {
        e.preventDefault()
        props.onToggle(props.style)
    }

    let className = 'RichEditor-styleButton'
    if (props.active) { className += ' RichEditor-activeButton' }

    return (

        <span
            className={`${className} ${props.active ? 'background-color' : null} p-1 pt-0 rounded`}
            style={{ marginRight: 4, fontSize: 20, color: props.active ? 'var(--text-color-light)' : 'var(--text-color-lighter)' }}
            title={props.title}
            onMouseDown={onToggle}>
            {props.icon}
        </span>
    )
}



export const getCaretPopupPosition = (parentRef, topOffset=15, leftOffset=0) => {
    try {
        const parent = parentRef.current?.getBoundingClientRect()
        const selector = window.getSelection().getRangeAt(0).cloneRange()
        const rect = selector.getBoundingClientRect()
        return { top: rect.top - parent.top + topOffset, left: rect.left - parent.left + leftOffset}
    } catch (e) {
        return null
    }
}



export const getCaretLocation = (editorState) => {
    return editorState.getSelection().getAnchorOffset()
}



export const getText = (editorState, start, end) => {
    const block = getCurrentBlock(editorState)
    const blockText = block.getText()
    return blockText.substring(start, end)
}


export const getCurrentBlock = editorState => {
    if (editorState.getSelection) {
        const selectionState = editorState.getSelection()
        const contentState = editorState.getCurrentContent()
        const block = contentState.getBlockForKey(selectionState.getStartKey())
        return block
    }
}


function cleanEmptyLines(contentState, blockKey) {
    const blockBefore = contentState.getBlockBefore(blockKey)
    const blockAfter = contentState.getBlockAfter(blockKey)
    const currentBlockText = contentState.getBlockForKey(blockKey).getText().trim()

    // remove first block if it's empty 
    if (!blockBefore && currentBlockText === '') {
        const newBlockMap = contentState.getBlockMap().remove(blockKey)
        const newContentState = contentState.merge({ blockMap: newBlockMap })
        // if there's blockAfter we call the function again with newContentState and that blockKey
        if (blockAfter) {
            return cleanEmptyLines(newContentState, blockAfter.getKey())
        }
        return newContentState

        // if blockAfter and currentBlock are empty we remove currentBlock
    } else if (blockAfter && blockAfter.getText().trim() === '' && currentBlockText === '') {
        const newBlockMap = contentState.getBlockMap().remove(blockKey)
        const newContentState = contentState.merge({ blockMap: newBlockMap })
        return cleanEmptyLines(newContentState, blockAfter.getKey())

        // if there's more blockAfter we call the function again
    } else if (blockAfter) {
        return cleanEmptyLines(contentState, blockAfter.getKey())

        // remove lastBlock if it's empty 
    } else if (!blockAfter && currentBlockText === '') {
        const newBlockMap = contentState.getBlockMap().remove(blockKey)
        const newContentState = contentState.merge({ blockMap: newBlockMap })
        return newContentState
    }

    return contentState
}

// remove empty lines from the beginning and end and limit to one empty line between text 
export const getCurrentCleanContent = (editorState) => {
    const contentState = editorState.getCurrentContent()
    const firstBlock = contentState.getFirstBlock().getKey()
    return cleanEmptyLines(contentState, firstBlock)
}

