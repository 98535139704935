import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import { MdDarkMode } from 'react-icons/md'
import lightFavicon from './imgs/favicon-light.png'
import darkFavicon from './imgs/favicon-dark.png'

export default function Theme({ theme, isAuthenticated }) {

    const [selectTheme, setSelectTheme] = useState(theme)

    const favicon = document.querySelector('link[rel="icon"]')

    useEffect(() => {
        setSelectTheme(theme)
    }, [theme])

    const dispatch = useDispatch()
    const source = axios.CancelToken.source()


    const setDarkTheme = () => {
        favicon.href = darkFavicon
        document.documentElement.setAttribute('data-theme', 'dark')
        document.querySelector(':root').style.setProperty('color-scheme', 'dark')
    }

    const setLightTheme = () => {
        favicon.href = lightFavicon
        document.documentElement.setAttribute('data-theme', 'light')
        document.querySelector(':root').style.setProperty('color-scheme', 'light')
    }

    // save theme preference 
    const handleChangeTheme = async() => {
        try {
            const response = await axios.patch('/accounts/me/', { theme: selectTheme }, {
                cancelToken: source.token
            })
            if (response?.status === 200) {
                dispatch({ type: 'UPDATE_USER', user: response.data })
            }
        } catch (err) {
            if (axios.isCancel(err)) return
        }
    }


    useEffect(() => {
        if (selectTheme === "DARK") {
            setDarkTheme()
            // save
            if (isAuthenticated && theme !== selectTheme) handleChangeTheme()

        } else if (selectTheme === "LIGHT") {
            setLightTheme()
            // save
            if (isAuthenticated && theme !== selectTheme) handleChangeTheme()

        } else if (selectTheme === "AUTO") {
            const isSysDarkTheme = window.matchMedia("(prefers-color-scheme: dark)").matches

            if (isSysDarkTheme) {
                setDarkTheme()
            } else {
                setLightTheme()
            }
            // save
            if (isAuthenticated && theme !== selectTheme) handleChangeTheme()

        }

        // save selected theme to localStorage
        localStorage.setItem('theme', selectTheme)

    }, [selectTheme])


    useEffect(() => {
        return () => source.cancel()
    }, [])


    return (
        <div className="card-body p-0 d-flex">
            <MdDarkMode className="text-color pt-1 me-3 font-md" />

            <h5 className="m-0 p-0 mb-3 ">
                <span className="fw-500 text-color font-xsss mt-0 me-4 "> Theme </span>
                <div className="btn-group ">
                    <label className="pointer me-2 font-xssss fw-500 mt-1 text-color-light" htmlFor='theme-light'> Light
                        <input className="ms-2" type="radio" id="theme-light" checked={selectTheme === 'LIGHT'} value="LIGHT" onChange={(e) => setSelectTheme(e.target.value)} />
                    </label>

                    <label className="pointer me-2 font-xssss fw-500 mt-1 text-color-light" htmlFor='theme-dark'> Dark
                        <input className="ms-2" type="radio" id="theme-dark" checked={selectTheme === 'DARK'} value="DARK" onChange={(e) => setSelectTheme(e.target.value)} />
                    </label>

                    <label className="pointer me-2 font-xssss fw-500 mt-1 text-color-light" htmlFor='theme-auto'>Auto
                        <input className="ms-2" type="radio" id="theme-auto" checked={selectTheme === 'AUTO'} value="AUTO" onChange={(e) => setSelectTheme(e.target.value)} />
                    </label>
                </div>
            </h5>
        </div>
    )
}