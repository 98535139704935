import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import { toast } from 'react-toastify'
import { regex } from '../utils/utils'

export default function UserForm() {
    const user = useSelector((state) => state.userReducer.user)
    const profileObj = {
        firstName: user.profile.first_name || '',
        lastName: user.profile.last_name || '',
        birthday: {
            day: user.profile.birthday?.substring(8, 10) || 0,
            month: user.profile.birthday?.substring(5, 7) || 0,
            year: user.profile.birthday?.substring(0, 4) || 0
        },
        gender: user.profile.gender || 0,
        currentCity: user.profile.current_city || '',
        hometown: user.profile.hometown || '',
        website: user.profile.website || '',
        bio: user.profile.bio || ''
    }

    const [profile, setProfile] = useState(profileObj)
    const [isLoading, setIsLoading] = useState(false)
    const [enableSave, setEnableSave] = useState(false)
    const [error, setError] = useState({})


    const dispatch = useDispatch()
    const source = axios.CancelToken.source()


    useEffect(() => {
        if (((profile.firstName || user.profile.first_name) && profile.firstName?.trim() !== user.profile.first_name?.trim()) ||
            ((profile.lastName || user.profile.last_name) && profile.lastName?.trim() !== user.profile.last_name?.trim()) ||
            ((profile.currentCity || user.profile.current_city) && profile.currentCity?.trim() !== user.profile.current_city?.trim()) ||
            ((profile.hometown || user.profile.hometown) && profile.hometown?.trim() !== user.profile.hometown?.trim()) ||
            ((profile.website || user.profile.website) && profile.website?.trim() !== user.profile.website?.trim()) ||
            ((profile.bio || user.profile.bio) && profile.bio?.trim() !== user.profile.bio?.trim()) ||
            ((profile.gender || user.profile.gender) && profile.gender !== user.profile.gender) ||
            (((profile.birthday.year && profile.birthday.month && profile.birthday.day) || user.profile.birthday) && `${profile.birthday.year}-${profile.birthday.month}-${profile.birthday.day}` !== user.profile.birthday)) {

            setEnableSave(true)
        } else {
            setEnableSave(false)
        }
    }, [profile, user.profile])



    const handleUpdateProfile = async(e) => {
        if (profile.website && !regex.urlRegex.test(profile.website.trim())) {
            return setError({ website: ['Invalid url.'] })
        }
        e.preventDefault()
        setIsLoading(true)
        setError({})

        try {
            const response = await axios.patch('/profiles/me/', {
                first_name: profile.firstName,
                last_name: profile.lastName,
                birthday: (profile.birthday.year && profile.birthday.month && profile.birthday.day) ? `${profile.birthday.year}-${profile.birthday.month}-${profile.birthday.day}` : null,
                gender: profile.gender,
                current_city: profile.currentCity,
                hometown: profile.hometown,
                website: profile.website?.startsWith('http') ? profile.website : profile.website ? 'http://' + profile.website : null,
                bio: profile.bio,
            }, {
                cancelToken: source.token
            })

            if (response?.status === 200) {
                dispatch({ type: 'UPDATE_USER', user: { ...user, profile: response.data } })
                setIsLoading(false)
                toast.success('Profile information successfully updated.')
            }

        } catch (err) {
            if (axios.isCancel(err)) return
            if (err?.response?.data) {
                if (err.response?.data?.non_field_errors) {
                    toast.error(err.response.data.non_field_errors?.toString())
                }
                setError(err?.response?.data)
            } else {
                toast.error('Unknown error')
            }
            setIsLoading(false)
        }

    }

    return (
        <div className="px-4" >
            <h5 className="p-2 pb-3 border-bottom text-color-light">Profile</h5>
            <div className="py-2">

                {/* Name */}
                <div className="row py-2">
                    <div className="col-md-6">
                        <label className="text-color-lighter font-xsss" >First Name</label>
                        <input className={`${error.first_name ? 'is-invalid' : ''} form-control`} type="text" placeholder="First Name" value={profile.firstName} disabled={isLoading} onChange={e => setProfile(prev => ({ ...prev, firstName: e.target.value }))} />
                        {error?.first_name?.length > 0 && <ul>
                            {error?.first_name.map((err, idx) => <li key={idx} className="help-block text-danger font-xssss  ms-1">{err}</li>)}
                        </ul>}
                    </div>
                    <div className="col-md-6 pt-md-0 pt-3">
                        <label className="text-color-lighter font-xsss" >Last Name</label>
                        <input className={`${error.last_name ? 'is-invalid' : ''} form-control`} type="text" placeholder="Last Name" value={profile.lastName} disabled={isLoading} onChange={e => setProfile(prev => ({ ...prev, lastName: e.target.value }))} />
                        {error?.last_name?.length > 0 && <ul>
                            {error?.last_name.map((err, idx) => <li key={idx} className="help-block text-danger font-xssss  ms-1">{err}</li>)}
                        </ul>}
                    </div>
                </div>



                <div className="row py-2">

                    {/* Gender */}
                    <div className="col-md-6 mb-sm-3 mb-md-1">
                        <label className="text-color-lighter font-xsss" >Gender</label>
                        <select className={`${error.gender ? 'is-invalid' : ''} d-block w-100`} value={profile.gender} disabled={isLoading} onChange={e => setProfile(prev => ({ ...prev, gender: e.target.value }))}>
                            <option value="0" disabled>Gender</option>
                            <option value="M">Male</option>
                            <option value="F">Female</option>
                            <option value="O">Other</option>
                        </select>
                        {error?.gender?.length > 0 && <ul>
                            {error?.gender.map((err, idx) => <li key={idx} className="help-block text-danger font-xssss  ms-1">{err}</li>)}
                        </ul>}
                    </div>

                    {/* Birthday */}
                    <div className="col-md-6">
                        <label className="text-color-lighter font-xsss" >Birthday</label>
                        <div className="d-flex">
                            <select className={`${error.birthday ? 'is-invalid' : ''} flex-fill me-2`} value={profile.birthday.month} disabled={isLoading} onChange={e => setProfile(prev => ({ ...prev, birthday: { ...prev.birthday, month: e.target.value } }))}>
                                <option value="0" disabled>Month</option>
                                <option value="01">Jan</option>
                                <option value="02">Feb</option>
                                <option value="03">Mar</option>
                                <option value="04">Apr</option>
                                <option value="05">May</option>
                                <option value="06">Jun</option>
                                <option value="07">Jul</option>
                                <option value="08">Aug</option>
                                <option value="09">Sep</option>
                                <option value="10">Oct</option>
                                <option value="11">Nov</option>
                                <option value="12">Dec</option>
                            </select>

                            <select className={`${error.birthday ? 'is-invalid' : ''} flex-fill me-2`} value={profile.birthday.day} disabled={isLoading} onChange={e => setProfile(prev => ({ ...prev, birthday: { ...prev.birthday, day: e.target.value } }))}>
                                <option value="0" disabled>Day</option>
                                <option value="01">1</option>
                                <option value="02">2</option>
                                <option value="03">3</option>
                                <option value="04">4</option>
                                <option value="05">5</option>
                                <option value="06">6</option>
                                <option value="07">7</option>
                                <option value="08">8</option>
                                <option value="09">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                                <option value="21">21</option>
                                <option value="22">22</option>
                                <option value="23">23</option>
                                <option value="24">24</option>
                                <option value="25">25</option>
                                <option value="26">26</option>
                                <option value="27">27</option>
                                <option value="28">28</option>
                                <option value="29">29</option>
                                {(profile.birthday.month !== '02') && <option value="30">30</option>}
                                {['01', '03', '05', '07', '08', '10', '12'].includes(profile.birthday.month) && <option value="31">31</option>}

                            </select>

                            <select className={`${error.birthday ? 'is-invalid' : ''} flex-fill`} value={profile.birthday.year} disabled={isLoading} onChange={e => setProfile(prev => ({ ...prev, birthday: { ...prev.birthday, year: e.target.value } }))} >
                                <option value="0" disabled>Year</option>
                                <option value="2014">2014</option><option value="2013">2013</option><option value="2012">2012</option>
                                <option value="2011">2011</option><option value="2010">2010</option><option value="2009">2009</option>
                                <option value="2008">2008</option><option value="2007">2007</option><option value="2006">2006</option>
                                <option value="2005">2005</option><option value="2004">2004</option><option value="2003">2003</option>
                                <option value="2002">2002</option><option value="2001">2001</option><option value="2000">2000</option>
                                <option value="1999">1999</option><option value="1998">1998</option><option value="1997">1997</option>
                                <option value="1996">1996</option><option value="1995">1995</option><option value="1994">1994</option>
                                <option value="1993">1993</option><option value="1992">1992</option><option value="1991">1991</option>
                                <option value="1990">1990</option><option value="1989">1989</option><option value="1988">1988</option>
                                <option value="1987">1987</option><option value="1986">1986</option><option value="1985">1985</option>
                                <option value="1984">1984</option><option value="1983">1983</option><option value="1982">1982</option>
                                <option value="1981">1981</option><option value="1980">1980</option><option value="1979">1979</option>
                                <option value="1978">1978</option><option value="1977">1977</option><option value="1976">1976</option>
                                <option value="1975">1975</option><option value="1974">1974</option><option value="1973">1973</option>
                                <option value="1972">1972</option><option value="1971">1971</option><option value="1970">1970</option>
                                <option value="1969">1969</option><option value="1968">1968</option><option value="1967">1967</option>
                                <option value="1966">1966</option><option value="1965">1965</option><option value="1964">1964</option>
                                <option value="1963">1963</option><option value="1962">1962</option><option value="1961">1961</option>
                                <option value="1960">1960</option><option value="1959">1959</option><option value="1958">1958</option>
                                <option value="1957">1957</option><option value="1956">1956</option><option value="1955">1955</option>
                                <option value="1954">1954</option><option value="1953">1953</option><option value="1952">1952</option>
                                <option value="1951">1951</option><option value="1950">1950</option><option value="1949">1949</option>
                                <option value="1948">1948</option><option value="1947">1947</option><option value="1946">1946</option>
                                <option value="1945">1945</option><option value="1944">1944</option><option value="1943">1943</option>
                                <option value="1942">1942</option><option value="1941">1941</option><option value="1940">1940</option>
                                <option value="1939">1939</option><option value="1938">1938</option><option value="1937">1937</option>
                                <option value="1936">1936</option><option value="1935">1935</option><option value="1934">1934</option>
                                <option value="1933">1933</option><option value="1932">1932</option><option value="1931">1931</option>
                                <option value="1930">1930</option><option value="1929">1929</option><option value="1928">1928</option>
                                <option value="1927">1927</option><option value="1926">1926</option><option value="1925">1925</option>
                                <option value="1924">1924</option><option value="1923">1923</option><option value="1922">1922</option>
                                <option value="1921">1921</option><option value="1920">1920</option><option value="1919">1919</option>
                                <option value="1918">1918</option><option value="1917">1917</option><option value="1916">1916</option>
                                <option value="1915">1915</option><option value="1914">1914</option><option value="1913">1913</option>
                                <option value="1912">1912</option><option value="1911">1911</option><option value="1910">1910</option>
                            </select>
                        </div>
                        {error?.birthday?.length > 0 && <ul>
                            {error?.birthday.map((err, idx) => <li key={idx} className="help-block text-danger font-xssss  ms-1">{err}</li>)}
                        </ul>}
                    </div>


                </div>


                {/* Addresses */}
                <div className="row py-2">
                    <div className="col-md-6">
                        <label className="text-color-lighter font-xsss" >Current City</label>
                        <input className={`${error.current_city ? 'is-invalid' : ''} form-control`} type="text" placeholder="Current City" value={profile.currentCity} disabled={isLoading} onChange={e => setProfile(prev => ({ ...prev, currentCity: e.target.value }))} />
                        {error?.current_city?.length > 0 && <ul>
                            {error?.current_city.map((err, idx) => <li key={idx} className="help-block text-danger font-xssss  ms-1">{err}</li>)}
                        </ul>}
                    </div>
                    <div className="col-md-6 pt-md-0 pt-3">
                        <label className="text-color-lighter font-xsss" >Hometown</label>
                        <input className={`${error.hometown ? 'is-invalid' : ''} form-control`} type="text" placeholder="Hometown" value={profile.hometown} disabled={isLoading} onChange={e => setProfile(prev => ({ ...prev, hometown: e.target.value }))} />
                        {error?.hometown?.length > 0 && <ul>
                            {error?.hometown.map((err, idx) => <li key={idx} className="help-block text-danger font-xssss  ms-1">{err}</li>)}
                        </ul>}
                    </div>
                </div>

                {/* Website */}
                <div className="row py-2">
                    <div className="col">
                        <label className="text-color-lighter font-xsss" >Website</label>
                        <input className={`${error.website ? 'is-invalid' : ''} form-control`} type="text" placeholder="Website" value={profile.website} disabled={isLoading} onChange={e => setProfile(prev => ({ ...prev, website: e.target.value }))} />
                        {error?.website?.length > 0 && <ul>
                            {error?.website.map((err, idx) => <li key={idx} className="help-block text-danger font-xssss  ms-1">{err}</li>)}
                        </ul>}
                    </div>

                </div>

                {/* Bio */}
                <div className="row py-2">
                    <div className="col">
                        <label className="text-color-lighter font-xsss" >Bio</label>
                        <textarea className={`${error.bio ? 'is-invalid' : ''} form-control`} rows="4" maxLength="200" style={{ resize: "none" }} type="text" placeholder="Bio" value={profile.bio} disabled={isLoading} onChange={e => setProfile(prev => ({ ...prev, bio: e.target.value }))} />
                        {error?.bio?.length > 0 && <ul>
                            {error?.bio.map((err, idx) => <li key={idx} className="help-block text-danger font-xssss  ms-1">{err}</li>)}
                        </ul>}
                    </div>

                </div>

                {/* Save */}
                <div className="d-flex py-3 ms-auto">
                    <div className="ms-auto">
                        {enableSave && <button className="btn btn-outline-secondary me-2" onClick={() => { setProfile(profileObj); setError({}) }}>Cancel</button>}
                        <button className="btn btn-primary font-xsss p-2 px-3" disabled={isLoading || !enableSave} onClick={handleUpdateProfile}>
                            {isLoading && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}
                            Save Changes
                        </button>
                    </div>
                </div>


            </div>
        </div>

    )

}