import { useState, useEffect } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import CheckAndSetPasswords from '../components/CheckAndSetPasswords'

export default function ChangePassword({ user }) {

    const [changePasswordIsLoading, setChangePasswordIsLoading] = useState(false)
    const [resetPasswordIsLoading, setResetPasswordIsLoading] = useState(false)
    const [error, setError] = useState({})
    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmNewPassword, setConfirmNewPassword] = useState('')
    const [validPassword, setValidPassword] = useState(false)

    const source = axios.CancelToken.source()

    const handleSubmit = async(e) => {
        e.preventDefault()

        if (!currentPassword) {
            setError({ current_password: ['Current password is required.'] })
        }
        else if (!validPassword) {
            setError({ password: ['Invalid password.'], confirm_password: ['Invalid password.'] })
        }

        else {
            try {
                setChangePasswordIsLoading(true)
                const response = await axios.post('/accounts/change_password/', {
                    current_password: currentPassword,
                    password: newPassword,
                }, {
                    cancelToken: source.token
                })
                if (response?.status === 204) {
                    setCurrentPassword('')
                    setNewPassword('')
                    setConfirmNewPassword('')
                    toast.success('Password successfully changed.')
                }

            } catch (err) {
                if (axios.isCancel(err)) return

                if (err?.response?.data) {
                    if (err.response.data?.non_field_errors) {
                        toast.error(err.response.data.non_field_errors?.toString())
                    }
                    setError(err.response.data)
                } else {
                    toast.error('Unknown error')
                }

            } finally {
                setChangePasswordIsLoading(false)
            }

        }

    }

    const resetPassword = async(e) => {
        if (resetPasswordIsLoading || changePasswordIsLoading) return

        try {
            setResetPasswordIsLoading(true)
            const response = await axios.post('/accounts/forgot_password/', { email: user.email }, {
                cancelToken: source.token
            })
            if (response?.status === 204) {
                toast.success('Reset password email successfully sent.')
            }

        } catch (err) {
            if (axios.isCancel(err)) return
            toast.error(error?.response?.data?.non_field_errors?.toString() || error?.message || 'An unknown error occurred.')
        } finally {
            setResetPasswordIsLoading(false)
        }
    }


    // handle Unmount component
    useEffect(() => {
        return () => source.cancel()
    }, [])


    return (
        <div className="px-4" >
            <h5 className="p-2 pb-3 border-bottom text-color-light">Password</h5>
            <div className="py-2">

                <form onSubmit={handleSubmit} onClick={() => setError({})} className="row">

                    <div className="col-md-6">
                        <div className="form-group has-error py-1">
                            <label className="text-color-lighter font-xsss" >Current password</label>
                            <input className={`${error.current_password ? 'is-invalid' : ''} form-control`} type="password" placeholder="Current password" value={currentPassword} onChange={e => { setCurrentPassword(e.target.value); setError({}) }} onClick={() => setError({})} />
                            {error?.current_password?.length > 0 && <ul>
                                {error?.current_password.map((err, idx) => <li key={idx} className="help-block text-danger font-xssss  ms-1">{err}</li>)}
                            </ul>
                            }
                        </div>

                        <CheckAndSetPasswords
                            error={error}
                            password={newPassword}
                            setPassword={setNewPassword}
                            enableConfirmPassword={true}
                            confirmPassword={confirmNewPassword}
                            setConfirmPassword={setConfirmNewPassword}
                            setValidPassword={setValidPassword}
                        />

                    </div>
                    <div className="col-md-6 p-2">

                        <div className="my-2">
                            <h6 className="mb-2">Don't know your password?</h6>
                            <p className="small text-muted ps-2">Send a password reset email, by clicking <span className="text-primary pointer hover-underline" onClick={resetPassword}>here</span>.</p>
                        </div>

                    </div>

                    {/* Change password */}
                    <div className="d-flex pt-3">
                        <div className="ms-auto">
                            <button type="submit" className="btn btn-primary font-xsss p-2 px-3" disabled={changePasswordIsLoading || resetPasswordIsLoading || !currentPassword || !validPassword}>
                                {changePasswordIsLoading && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}
                                Change Password
                            </button>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    )
}
