import { useEffect } from 'react'
import { Link } from "react-router-dom"

export default function NotFound() {
    useEffect(() => {
        document.title = '404'
    })

    return (
        <div className="h-100 d-flex align-items-center justify-content-center prevent-select" style={{ minHeight: '100vh' }}>
            <div className='text-center'>
                <p className="display4-size lh-1">404</p>
                <p className="font-xsss">Sorry, Page not found.</p>
                <Link className="font-xsss" to={'/'}>Back to home</Link>
            </div>
        </div>
    )
}
