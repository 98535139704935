import { useEffect } from 'react'
import dayjs from 'dayjs'

import Newsfeed from '../newsfeed/Newsfeed'
import Post from '../newsfeed/post/Post'
import Loader from '../components/Loader'
import Events from '../components/Events'
import { MdHouse, MdOutlineWork, MdLocationPin, MdLink, MdCalendarToday } from "react-icons/md"


export default function ProfileHome({ loading, user, isMyProfile }) {

    useEffect(() => {
        if (user && user?.profile) {
            document.title = user.profile.first_name
        }
    })

    return (
        <>
            <div className="col-lg-4 p-0" style={{ minHeight: "100vh" }}>

                <div className="" style={{ position: "sticky", top: 75 }}>

                    <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
                        {loading || !user?.profile ?

                            <Loader height={300}>
                                <rect x="22" y="20" rx="5" ry="5" width="45" height="16" />
                                <rect x="22" y="50" rx="3" ry="3" width="270" height="8" />
                                <rect x="22" y="70" rx="3" ry="3" width="250" height="8" />

                                <rect x="3" y="100" rx="3" ry="3" width="310" height="1" />

                                <rect x="22" y="128" rx="8" ry="8" width="26" height="28" />
                                <rect x="60" y="130" rx="3" ry="3" width="65" height="8" />
                                <rect x="60" y="146" rx="3" ry="3" width="95" height="8" />


                                <rect x="22" y="185" rx="8" ry="8" width="26" height="28" />
                                <rect x="60" y="187" rx="3" ry="3" width="65" height="8" />
                                <rect x="60" y="204" rx="3" ry="3" width="120" height="8" />


                                <rect x="22" y="240" rx="8" ry="8" width="26" height="28" />
                                <rect x="60" y="242" rx="3" ry="3" width="65" height="8" />
                                <rect x="60" y="258" rx="3" ry="3" width="95" height="8" />

                            </Loader> :

                            <>
                                <div className="card-body d-block m-2 mb-3 border-bottom">
                                    <h4 className="fw-500 mb-2 font-xsss text-color-lighter">Bio</h4>
                                    {user?.profile?.bio && <p className="fw-500 text-color lh-1 font-xssss my-1">{user.profile.bio}</p>}
                                </div>

                                {/* <div className="card-body border-top d-flex">
                                    <MdOutlineWork className="text-color-lighter me-2 font-lg" />
                                    <h4 className="fw-500 text-color-lighter font-xssss ">Work <span className="d-block font-xssss fw-500 mt-1 lh-3 text-color">What's up, how are you?</span></h4>
                                </div> */}

                                {user?.profile?.current_city && <div className="card-body d-flex pt-0">
                                    <MdLocationPin className="text-color-lighter me-2 font-lg" />
                                    <h4 className="fw-500 text-color-lighter font-xssss"> Lives<span className="d-block font-xssss fw-500 mt-1 lh-3 text-color">{user.profile.current_city}</span> </h4>
                                </div>}

                                {user?.profile?.hometown && <div className="card-body d-flex pt-0">
                                    <MdHouse className="text-color-lighter me-2 font-lg" />
                                    <h4 className="fw-500 text-color-lighter font-xssss ">Hometown <span className="d-block font-xssss fw-500 mt-1 lh-3 text-color">{user.profile.hometown}</span></h4>
                                </div>}

                                {user?.profile?.website && <div className="card-body pt-0">
                                    <MdLink className="text-color-lighter me-2 font-lg" />
                                    <h4 className="fw-500 text-color-lighter font-xssss d-inline-block mb-0 lh-1">Website </h4>
                                    <span className="d-block ms-4 text-truncate lh-1 mb-2">
                                        <a className="font-xssss ms-2 fw-500 text-color" href={user.profile.website} target='_blank' rel="noreferrer">{user.profile.website}</a>
                                    </span>
                                </div>}

                                {user?.created_at && <div className="card-body d-flex pt-0">
                                    <MdCalendarToday className="text-color-lighter me-2 font-lg" />
                                    <h4 className="fw-500 text-color-lighter font-xssss">Joined <span className="d-block font-xssss fw-500 mt-1 lh-3 text-color">{dayjs(user.created_at).format('MMMM D, YYYY')}</span></h4>
                                </div>}
                            </>
                        }
                    </div>

                    <Events />
                </div>
            </div>
            <div className="col-lg-8 p-0 ps-lg-3">

                {loading || !user ?
                    <>
                        {/* loading */}
                        <Post loading={true} key={1} />
                        <Post loading={true} key={2} />
                    </> :
                    <Newsfeed userId={user?.id} isMyProfile={isMyProfile} isAuthenticated={true}/>
                }
            </div>
        </>

    )
}
