import { useState, useEffect } from 'react'
import { NavLink } from "react-router-dom"
import axios from 'axios'
import dayjs from 'dayjs'
import Loader from '../components/Loader'
import { MdThumbUpOffAlt, MdInsertComment, MdShare, MdAlternateEmail, MdGroupAdd, MdOutlineMoreHoriz } from "react-icons/md"

export default function Notification({ notification, updateNotifications, loading }) {
    const [isLoading, setIsLoading] = useState(loading)
    const [error, setError] = useState('')
    const [message, setMessage] = useState('')
    const [currentNotification, setCurrentNotification] = useState(notification)

    const source = axios.CancelToken.source()


    const formatNotificationMessage = (notification) => {
        if (notification.type === 'LIKE') {
            return `liked your ${notification.comment ? 'comment.' : 'post.'}`
        } else if (notification.type === 'SHARE') {
            return `shared your ${notification.comment ? 'comment.' : 'post.'}`
        } else if (notification.type === 'COMMENT') {
            return `commented on your ${notification.comment ? 'comment.' : 'post.'}`
        } else if (notification.type === 'MENTION') {
            return `mentioned you in a ${notification.comment ? 'comment.' : 'post.'}`
        } else if (notification.type === 'FRIEND_REQUEST_ACCEPTED') {
            return `accepted your friend request.`
        }
    }

    // delete notification
    const handleDeleteNotification = async() => {
        try {
            const response = await axios.post('/notifications/request/', {
                notification_id: currentNotification.id,
                action: "DELETE"
            })

            if (response?.status === 204) {
                // TODO: fix later
                // console.log('Notification successfully deleted.')
                // updateNotifications(notification, 'delete')
            }

        } catch (err) {
            // fall silently
        }
    }

    useEffect(() => {
        return () => source.cancel()
    }, [])



    return (
        <>
            {isLoading ?
                <Loader height={80}>
                    <circle cx="40"  cy="30" r="25" />
                    <rect x="75" y="14" rx="4" ry="4" width="70%" height="10" />
                    <rect x="75" y="34" rx="4" ry="4" width="100" height="10" />
                </Loader>
                :

                <NavLink
                    to={currentNotification.type === 'FRIEND_REQUEST_ACCEPTED' ? `/${currentNotification.actor.username ? currentNotification.actor.username : currentNotification.actor.id}` : `/post/${currentNotification.post_public_id}`}
                    className={`d-flex align-items-center p-3 rounded-xxl ${!currentNotification.read_at ? 'background-color-light' : null}`}
                >
                    <img src={currentNotification.actor.profile.thumbnail} alt="user" className=" rounded-circle w45 me-3" />

                    {currentNotification.type === "LIKE" && <MdThumbUpOffAlt className="text-white p-1 notification-react bg-primary" />}
                    {currentNotification.type === "COMMENT" && <MdInsertComment className="text-white p-1 font-xssss notification-react bg-primary" />}
                    {currentNotification.type === "SHARE" && <MdShare className="text-white p-1 font-xssss notification-react bg-primary" />}
                    {currentNotification.type === "MENTION" && <MdAlternateEmail className="text-white p-1 font-xssss notification-react bg-primary" />}
                    {currentNotification.type === "FRIEND_REQUEST_ACCEPTED" && <MdGroupAdd className="text-white p-1 font-xssss notification-react bg-primary" />}

                    <h6 className="font-xssss mb-0 mt-0 fw-500 lh-20">
                        <span className="text-color fw-600 me-1">{currentNotification.actor.profile.full_name}</span>
                        {formatNotificationMessage(currentNotification)}
                        <span className="d-block font-xsssss text-color-lighter my-0"> {dayjs(currentNotification.created_at).fromNow()}</span>
                    </h6>
                    <MdOutlineMoreHoriz className="text-color-light font-xs ms-auto" />
                </NavLink>}
        </>
    )
}