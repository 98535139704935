import axios from 'axios'
import { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useParams, Switch, Route } from "react-router-dom"
import { isOnlyDigits, isValidUsername } from '../utils/utils'
import { stringifyUrlParams } from '../utils/utils'
import Sidebar from '../navbar/Sidebar'
import ProfileAbout from './ProfileAbout'
import ProfileImages from './ProfileImages'
import ProfileVideos from './ProfileVideos'
import ProfileFiles from './ProfileFiles'
import ProfileConnections from './ProfileConnections'
import NotFound from '../404/NotFoundView'


import ProfileHeader from './ProfileHeader'
import ProfileHome from './ProfileHome'



export default function Profile() {
    const user = useSelector((state) => state.userReducer.user)

    const [isLoading, setIsLoading] = useState(false)
    const [userId, setUserId] = useState(null)
    const [username, setUsername] = useState(null)
    const [notFound, setNotFound] = useState(false)
    const [isMyProfile, setIsMyProfile] = useState(false)
    const [userInfo, setUserInfo] = useState(null)


    const source = axios.CancelToken.source()

    const { userIdOrUsername, type } = useParams()

    useEffect(() => {
        // document.title = 'profile'

        if (userIdOrUsername) {
            if (isOnlyDigits(userIdOrUsername)) {
                setUserId(userIdOrUsername)
                setUsername(null)
                return
            } else if (userIdOrUsername.startsWith('@')) {
                const username = userIdOrUsername.slice(1)
                if (isValidUsername(username)) {
                    setUsername(username)
                    setUserId(null)
                    return
                }
            } else if (isValidUsername(userIdOrUsername)) {
                setUsername(userIdOrUsername)
                setUserId(null)
                return
            }
            return setNotFound(true)

        }
    }, [userIdOrUsername])






    useEffect(() => {
        if (user &&  userInfo) {
            setIsMyProfile(user.id === userInfo.id)
        }
    }, [user, userInfo])



    useEffect(() => {

        async function fetchData() {
            setIsLoading(true)
            setNotFound(false)

            try {
                const urlParams = stringifyUrlParams({ user_id: userId, username })
                const response = await axios.get(`/accounts/?${urlParams}`, {
                    cancelToken: source.token
                })

                if (response?.status === 200) {
                    document.title = `${response.data.profile.full_name}`
                    const userData = response.data
                    setUserInfo(userData)

                    // update the url (switch user id to username if user has a username)
                    if (userData.username && userData.username !== userIdOrUsername) {
                        const newUrl = `${window.location.protocol}//${window.location.host}/${response.data.username || response.data.id}${type ? `/${type}` : ''}`
                        window.history.pushState({ path: newUrl }, '', newUrl)
                    }

                    setIsLoading(false)
                }

            } catch (err) {
                setIsLoading(false)
                if (axios.isCancel(err)) return
                if (err?.response?.status === 404) { return setNotFound(true) }

                // setError(JSON.stringify(err.response?.data))
            }
        }

        if (userId || username) {
            fetchData()
        }

        return () => {
            return source.cancel()
        }

    }, [userId, username])


    return notFound ?
        <NotFound /> :

        <>
            <Sidebar isAuthenticated={!!user} user={user} />
            <div className="main-content right-chat-active">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="row m-0">

                            <ProfileHeader loading={isLoading} user={userInfo} isMyProfile={isMyProfile} />

                            <Switch>
                                <Route path={`/${userIdOrUsername}`} exact render={props => <ProfileHome loading={isLoading} user={userInfo} isMyProfile={isMyProfile} {...props} />} />
                                <Route path={`/${userIdOrUsername}/about`} render={props => <ProfileAbout loading={isLoading} user={userInfo} isMyProfile={isMyProfile} {...props} />} />
                                <Route path={"/:userIdOrUsername/images/:type(posts|profile|background)?"} render={props => <ProfileImages loading={isLoading} user={userInfo} isMyProfile={isMyProfile} {...props} />} />
                                <Route path={`/${userIdOrUsername}/videos`} render={props => <ProfileVideos loading={isLoading} user={userInfo} isMyProfile={isMyProfile} {...props} />} />
                                <Route path={`/${userIdOrUsername}/files`} render={props => <ProfileFiles loading={isLoading} user={userInfo} isMyProfile={isMyProfile} {...props} />} />
                                <Route path={"/:userIdOrUsername/connections/:type(friends|following|followers)?"} render={props => <ProfileConnections loading={isLoading} user={userInfo} isMyProfile={isMyProfile} {...props} />} />
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        </>

}
