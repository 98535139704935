import { useEffect } from 'react'
import useFetch from '../hooks/useFetchWithInfiniteScroll'
import User from './User'
import { stringifyUrlParams } from '../utils/utils'

export default function UserSuggestion({ position, selectedIndex, onClick, mentionQuery, setMention, elementRef }) {

    const urlParams = stringifyUrlParams({ query: mentionQuery })
    const url = (`/friends/search/?${urlParams}`)
    const { isLoading, error, results, lastItemRef } = useFetch(url)


    useEffect(() => {
        if (results.length) {
            setMention(prev => ({ ...prev, users: results, selectedIndex: 0 }))
        } else {
            // setMention(undefined)
        }
    }, [results])


    return (
        (isLoading || results.length > 0) ?
            <div
                ref={elementRef}
                className="card rounded-xxxl background-color-light p-1"
                style={{ zIndex: 2, maxHeight: 230, overflow: "auto", position: 'absolute', ...position }}
            >

                {results.length > 0 && results.map((user, index) =>
                    <div ref={results.length === index + 1 ? lastItemRef : null} key={user.id}>
                        <User user={user} selected={index === selectedIndex} onClick={onClick} />
                    </div>
                )}

                {isLoading && [...Array(2)].map((_, i) =>
                    <User loading={true} key={i} />
                )}

            </div> : null
    )

}