import { useEffect, useRef } from 'react'
import plyr from 'plyr'
import 'plyr/dist/plyr.css'

export default function VideoPlayer({ url, loop = false, controls = true, muted = false }) {

    // https://www.npmjs.com/package/plyr
    const player = useRef()

    const sources = {
        type: 'video',
        title: '',
        sources: [
            {
                src: url,
            },
            //  {
            //     src: '/path/to/movie.webm',
            //     type: 'video/webm',
            //     size: 1080,
            // },
        ],
        // poster: '/path/to/poster.jpg',
        // previewThumbnails: {
        //     src: '/path/to/thumbnails.vtt',
        // },
        // tracks: [
        //     {
        //         kind: 'captions',
        //         label: 'English',
        //         srclang: 'en',
        //         src: '/path/to/captions.en.vtt',
        //         default: true,
        //     },
        // ],
    }


    const options = {
        ratio: `16:9`,
        speed: { selected: 1, options: [0.5, 0.75, 1, 1.5, 2] },
        // settings:['captions', 'quality', 'speed', 'loop'],
        controls: controls ? [
            // 'rewind',
            'play',
            // 'fast-forward',
            'progress',
            'current-time',
            // 'duration',
            'mute',
            'volume',
            'settings',
            'fullscreen',
        ] : false,
        tooltips: { controls: false, seek: true },
        muted: muted,
        loop: { active: loop },
        hideControls: true,
        previewThumbnails: { enabled: false, src: '' },
    }

    useEffect(() => {
        player.current = new plyr('.video-player', options)
        player.current.source = sources

        return () => player.current.destroy()
    }, [])


    return (
        <div className='mt-2'>
            <video className='video-player' />
        </div>
    )
}
