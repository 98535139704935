
const initState = {
    friends: 0,
    messages: 0,
    general: 0,
}


export const notificationReducer = (state = initState, action) => {

    switch (action.type) {
    case ('SET_FRIENDS_NOTIFICATION'):
        return {
            ...state,
            friends: action.count,
        }

    case ('SET_MESSAGES_NOTIFICATION'):
        return {
            ...state,
            messages: action.count,
        }

    case ('SET_GENERAL_NOTIFICATION'):
        return {
            ...state,
            general: action.count,
        }

    case ('UPDATE_FRIENDS_NOTIFICATION'):
        return {
            ...state,
            friends: state.friends + action.count > 0 ? state.friends + action.count : 0,
        }

    case ('UPDATE_MESSAGES_NOTIFICATION'):
        return {
            ...state,
            messages: state.messages + action.count > 0 ? state.messages + action.count : 0,
        }

    case ('UPDATE_GENERAL_NOTIFICATION'):
        return {
            ...state,
            general: state.general + action.count > 0 ? state.general + action.count : 0,
        }

    case ('RESET_NOTIFICATIONS'):
        return initState

    default:
        return state
    }

}
