import { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import axios from 'axios'
import Loader from '../components/Loader'

export default function SuggestedUser({ loading, user, updateList }) {
    const [isLoading, setIsLoading] = useState(loading)

    const source = axios.CancelToken.source()


    // add 
    const handleAdd = async() => {
        try {
            const response = await axios.post('/friends/request/', {
                friend_id: user.id,
                action: "ADD_FRIEND"
            }, {
                cancelToken: source.token
            })

            if (response?.status === 201 || response?.status === 204) {
                updateList(prev => {
                    return prev.filter(u => u.id !== user.id)
                })
            }

        } catch (err) {
            if (axios.isCancel(err)) return
            // setError(err)
        }
    }


    // remove 
    const handleRemove = async() => {
        updateList(prev => {
            return prev.filter(u => u.id !== user.id)
        })
    }


    return (
        <>
            {isLoading ? <Loader height={82}>
                <circle cx="40" cy="32" r="25" />
                <rect x="72" y="14" rx="4" ry="4" width="100" height="15" />
                <rect x="72" y="39" rx="3" ry="3" width="80" height="10" />
                <rect x="200" y="13" rx="15" ry="15" width="60" height="30" />
                <rect x="265" y="13" rx="30" ry="30" width="30" height="30" />
            </Loader> :

                <div className="d-flex mx-3 mb-3">
                    {/* image */}
                    <img src={user.profile.thumbnail} alt="user" className="me-1 border" style={{
                        height: '50px',
                        width: '50px',
                        borderRadius: '50%'
                    }} />

                    {/* user info */}
                    <div className='ms-1 mt-1'>
                        <Link to={`/${user.username ? user.username : user.id}`} className="d-inline-block lh-1 fw-500 font-xssss text-color hover-underline w90 text-truncate">{user.profile.full_name}</Link>
                        {user.username && <h6 className="text-color fw-300 font-xsssss">@{user.username}</h6>}
                    </div>

                    {/* buttons */}
                    <div className='ms-auto py-2'>
                        <span onClick={handleAdd} className="d-inline-block btn btn-primary font-xssss text-white rounded-xxxl p-1 px-3"> Add </span>
                        <span onClick={handleRemove} className="d-none d-xl-inline-block btn text-color-light background-color-light font-xssss rounded-xxxl p-1 px-2 ms-0 ms-md-2"> &#x2715; </span>
                    </div>
                </div>}
        </>
    )
}