import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from "react-router-dom"
import axios from 'axios'
import qs from 'qs'
import { regex } from '../utils/utils'


export default function Login({ next, paramError}) {
    const [isLoading, setIsLoading] = useState(false)
    const [recaptchaRequired, setRecaptchaRequired] = useState(false)
    const [error, setError] = useState(paramError || {})
    const [emailOrUsername, setEmailOrUsername] = useState('')
    const [password, setPassword] = useState('')
    const [googleAuthLink, setGoogleAuthLink] = useState(`${process.env.REACT_APP_API_URL}/accounts/oauth/google`)
    const [microsoftAuthLink, setMicrosoftAuthLink] = useState(`${process.env.REACT_APP_API_URL}/accounts/oauth/microsoft`)

    const dispatch = useDispatch()
    const history = useHistory()

    const source = axios.CancelToken.source()



    const login = (emailOrUsername, password, recaptchaToken, cancelToken) => {
        return axios.post('/accounts/login/', {
            email_or_username: emailOrUsername,
            password,
            recaptcha_token: recaptchaToken
        }, {
            cancelToken: cancelToken
        })
    }

    const loginWithRecaptcha = () => {
        window.grecaptcha.ready(() => {
            setError({})
            setIsLoading(true)

            window.grecaptcha
                .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: "login" })
                .then(async token => {

                    try {
                        const response = await login(emailOrUsername, password, token, source.token)
                        // TODO: repeated with code in submit function
                        if (response?.status === 200) {
                            handleAfterSuccessfulLogin(response)
                        }

                    } catch (err) {
                        if (axios.isCancel(err)) return
                        setError(err.response?.data)

                    } finally {
                        setIsLoading(false)
                    }

                }).catch(err => {
                    if (axios.isCancel(err)) return
                    setIsLoading(false)
                })
        })
    }

    const handleAfterSuccessfulLogin = response => {
        dispatch({ type: 'SIGN_IN', data: response.data })
        history.push(next)
    }

    const handleSubmit = async(e) => {
        e.preventDefault()
        const errors = {}

        if (!regex.emailRegex.test(emailOrUsername.trim())) {
            if (!regex.usernameRegex.test(emailOrUsername.trim())) {
                errors.email_or_username = ['Invalid email / username.']
            }
        }
        if (!regex.passwordRegex.test(password.trim())) {
            errors.password = ['Invalid password.']
        }

        if (Object.keys(errors).length !== 0) return setError(errors)

        try {
            if (recaptchaRequired) {
                loginWithRecaptcha()
            } else {
                setIsLoading(true)
                setError({})
                const response = await login(emailOrUsername, password, null, source.token)
                if (response?.status === 200) {
                    handleAfterSuccessfulLogin(response)
                }
            }

        } catch (err) {
            // reached max failed login attempts
            if (!recaptchaRequired && err.response?.status === 406) {
                return setRecaptchaRequired(true)
            } else {
                if (axios.isCancel(err)) return
                setError(err.response?.data)
            }

        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        setGoogleAuthLink(`${googleAuthLink}?next=${next}`)
        setMicrosoftAuthLink(`${microsoftAuthLink}?next=${next}`)
    }, [next])

    // Add reCaptcha
    useEffect(() => {
        const script = document.createElement("script")
        script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`
        script.async = true
        document.getElementById('login').appendChild(script)
    }, [])

    useEffect(() => {
        if (recaptchaRequired && emailOrUsername && password) {
            loginWithRecaptcha()
        }
    }, [recaptchaRequired])


    return (

        <div className="card mt-1 mt-md-3 py-4 px-5 border rounded-xl account-form" id="login">

            <h5 className="text-center mb-4 text-main-color">Login</h5>

            {/* errors */}
            {error?.non_field_errors?.length > 0 && <ul>
                {error?.non_field_errors.map((err, idx) =>
                    <li key={idx} className="alert alert-danger alert-dismissible fade show p-2 px-3 font-bold font-xsss rounded-xxxl">
                        {err}
                        <button onClick={() => setError(prev => ({ ...prev, non_field_errors: prev.non_field_errors.filter(e => e !== err) }))} className="btn-close pt-2 font-bold font-xssss"></button>
                    </li>
                )}
            </ul>}

            <form onSubmit={handleSubmit} onClick={() => setError({})}>
                <div className="col my-2">
                    <label className="text-color font-xsss" >Email or username</label>
                    <input className={`${error.email_or_username ? 'is-invalid' : ''} form-control`} type="text" placeholder="Enter email or username" value={emailOrUsername} disabled={isLoading} onChange={e => setEmailOrUsername(e.target.value)} />
                    {error?.email_or_username?.length > 0 && <ul className='w-100'>
                        {error?.email_or_username.map((err, idx) => <li key={idx} className="help-block text-danger font-xssss  ms-1">{err}</li>)}
                    </ul>}
                </div>

                <div className="col my-2">
                    <label className="text-color font-xsss" >Password</label>
                    <input className={`${error.password ? 'is-invalid' : ''} form-control`} type="password" placeholder="Password" value={password} disabled={isLoading} onChange={e => setPassword(e.target.value)} />
                    {error?.password?.length > 0 && <ul>
                        {error?.password.map((err, idx) => <li key={idx} className="help-block text-danger font-xssss  ms-1">{err}</li>)}
                    </ul>}
                </div>

                <p className="small"><Link to={"/forgot-password"}> Forgot password?</Link></p>

                {/* login */}

                <div className="d-grid my-3">
                    <button type="submit" className="btn btn-primary fw-500 p-2 px-3" disabled={isLoading}>
                        {isLoading && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}
                                            Login
                    </button>
                </div>

            </form>

            <hr />

            <div className="d-grid my-2">
                <a href={googleAuthLink} className="d-flex btn btn-outline-secondary font-xsss p-2 px-3" >
                    <img className="me-auto" style={{ width: "1.3em", height: "1.3em", marginTop: "0.1em" }} src="/logos/google.png" alt='google'/>
                                        Login with Google
                </a>
            </div>

            <div className="d-grid my-2">
                <a href={microsoftAuthLink} className="d-flex btn btn-outline-secondary font-xsss p-2 px-3" >
                    <img className="me-auto" style={{ width: "1.3em", height: "1.3em", marginTop: "0.1em" }} src="/logos/microsoft.png" alt='microsoft'/>
                                        Login with Microsoft
                </a>
            </div>

            {/* <hr /> */}
            <div className="mt-3">
                <p className="mb-0 text-color font-xsss text-center">Don't have an account?  <Link className="ms-1 fw-500" to={"/signup"}> Sign Up</Link></p>
            </div>
        </div>
                    
    )

}
