import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { toast } from 'react-toastify'
import useFetch from '../hooks/useFetchWithInfiniteScroll'
import Notification from './Notification'
import { MdOutlineDelete, MdNotifications } from "react-icons/md"

export default function Notifications() {

    const notificationsCount = useSelector((state) => state.notificationReducer.general)

    const { isLoading, error, results: notifications, lastItemRef: lastNotificationRef } = useFetch('/notifications/general/')
    const dispatch = useDispatch()


    useEffect(() => {
        document.title = 'notifications'

        // mark general notifications as read
        if (notificationsCount > 0) {
            try {
                axios.post('/notifications/mark_general_notifications_as_read/')

            } catch (err) {
                if (axios.isCancel(err)) return
            }
        }
        dispatch({ type: 'SET_GENERAL_NOTIFICATION', count: 0 })

    }, [notificationsCount])


    useEffect(() => {
        if (error) {
            toast.error(error?.response?.data?.non_field_errors?.toString() || error?.message  || 'An unknown error occurred.')
        }
    }, [error])

    return (

        <div className="main-content right-chat-active">

            <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left">
                    <div className="">

                        <div className="p-3 w-100 position-relative scroll-bar background-color-lightest rounded-xxl">
                            <h2 className="fw-500 mb-4 m-2 font-md text-color-light d-flex align-items-center">Notifications
                                {/* <span className="circle-count bg-warning text-white font-xsssss rounded-3 ms-2 ls-3 fw-500 p-2  mt-0">23</span> */}
                                <span className="pointer ms-auto btn-round-sm background-color-light rounded-3"><MdOutlineDelete className="font-xss text-color-light" /></span>
                                <span className="pointer ms-2 btn-round-sm background-color-light rounded-3"><MdOutlineDelete className="font-xss text-color-light" /></span>
                                <span className="pointer ms-2 btn-round-sm background-color-light rounded-3"><MdOutlineDelete className="font-xss text-color-light" /></span>
                            </h2>

                            <ul className="notification-box">

                                {!isLoading && notifications.length === 0 && <div className='text-center p-3'>
                                    <h1 className='text-color-lightest'><MdNotifications /></h1>
                                    <h6 className='text-color-lightest'> No notifications.</h6>
                                </div>}

                                {notifications.length > 0 && notifications.map((notification, index) =>
                                    <li ref={(notifications.length === index + 1) ? lastNotificationRef : null} key={notification.id} className="m-1">
                                        <Notification notification={notification} />
                                    </li>
                                )}

                                {isLoading > 0 && [...Array(7)].map((_, i) =>
                                    <Notification loading={true} key={i} />
                                )}

                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    )

}