import { useState, useRef } from 'react'
import User from './User'
import { usePopper } from 'react-popper'
import { createPortal } from "react-dom"

export default function MentionComponent({ children, contentState, entityKey, readOnly }) {
    const [hovering, setHovering] = useState(false)

    const enterPopup = useRef(false)
    const [referenceElement, setReferenceElement] = useState(null)
    const [popperElement, setPopperElement] = useState(null)

    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [7, 1],
                },
            },
        ],
    })

    return (
        <>
            <span
                ref={setReferenceElement}
                className="pointer rounded text-primary"
                style={{background: "#3776d224"}}
                onMouseEnter={() => setHovering(true)}
                onMouseLeave={() => setTimeout(() => { if (!enterPopup.current) setHovering(false) }, 100)}
            >{children}</span>

            {hovering && createPortal(
                <div
                    onMouseEnter={() => enterPopup.current = true}
                    onMouseLeave={() => { setHovering(false); enterPopup.current = false }}
                    ref={setPopperElement}
                    className="background-color-lighter rounded-xxl p-1 mt-1"
                    style={{ ...styles.popper, zIndex: 3 }}
                    {...attributes.popper}
                >
                    <User user={contentState.getEntity(entityKey).getData()} />
                </div>
                ,
                document.querySelector('#root')
            )}

        </>
    )
}