import { NavLink } from "react-router-dom"

import { MdSearch, MdPeople, MdModeComment, MdOutlinePublic, MdSettings, MdLogout, MdMenu, MdHome, MdPerson, MdPersonSearch, MdChat, } from 'react-icons/md'

export default function Sidebar({ isAuthenticated, user }) {

    return isAuthenticated ?
        <div style={{
            position: 'fixed',
            top: 0,
            width: 280,
            background: 'transparent'
        }}>
            <nav className='navigation'>

                <div className="nav-content fw-500">

                    <div className="mb-2">
                        <ul className="mb-3 pt-4 pt-lg-0">
                            <li><NavLink to="/" exact={true} className="text-color-light"><MdHome className="pt-1 me-2 font-lg" /><span>Home</span></NavLink></li>
                            <li><NavLink to="/friends/find" className="text-color-light"><MdPersonSearch className="pt-1 me-2 font-lg" /><span>Find Friends</span></NavLink></li>
                            <li><NavLink to="/messages" className="text-color-light"><MdChat className="pt-1 me-2 font-lg" /><span>Messages</span></NavLink></li>
                            {user && <li><NavLink to={`/${user.username ? user.username : user.id}`} className="text-color-light"><MdPerson className="pt-1 me-2 font-lg" /><span>Profile</span></NavLink></li>}
                            <li><NavLink to="/settings" className="text-color-light"><MdSettings className="pt-1 me-2 font-lg" /><span>Settings</span></NavLink></li>
                        </ul>
                    </div>

                </div>
            </nav>
        </div> :
        null

}

