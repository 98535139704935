import { Link } from 'react-router-dom'
import Loader from '../components/Loader'

export default function User({ loading, user, selected, onClick }) {

    function handleClick(e) {
        e.preventDefault()
        e.stopPropagation()
        onClick(user)
    }

    return (
        <div style={{width:160}}>
            {loading || !user ? <Loader height={60} >
                <circle cx="30" cy="32" r="20" />
                <rect x="62" y="14" rx="4" ry="4" width="80" height="16" />
                <rect x="62" y="39" rx="3" ry="3" width="65" height="10" />
            </Loader> :

                <div onClick={onClick ? handleClick : undefined} className={`pointer d-flex px-1 py-2 rounded ${selected ? "background-color-lightest" : ''}`}>
                    {/* image */}
                    <img src={user.profile.thumbnail} alt="user" className="me-1 border" style={{
                        height: '40px',
                        width: '40px',
                        borderRadius: '50%'
                    }} />

                    {/* user info */}
                    <div className='ms-1'>
                        {onClick && <span className="d-inline-block lh-1 font-xsss text-color w90 text-truncate">{user.profile.full_name}</span>}
                        {!onClick && <Link to={`/${user.id}`} className="d-inline-block lh-1 font-xsss text-color hover-underline  w90 text-truncate" target="_blank">{user.profile.full_name}</Link>}
                        {user.username && <h6 className="text-color-light font-xsssss lh-1">@{user.username}</h6>}
                    </div>

                </div>}
        </div>
    )
}
