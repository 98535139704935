import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import { toast } from 'react-toastify'
import { regex } from '../utils/utils'

export default function UserForm({ user }) {

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    const [username, setUsername] = useState(user.username || '')


    const dispatch = useDispatch()

    const source = axios.CancelToken.source()



    const handleChangeUsername = async() => {
        if (isLoading || !username || (user.username === username?.trim())) return

        if (!regex.usernameRegex.test(username.trim())) {
            return setError({ username: ['Invalid username.'] })
        }

        setIsLoading(true)
        setError({})


        try {
            const response = await axios.patch('/accounts/me/', { username: username?.trim() }, {
                cancelToken: source.token
            })

            if (response?.status === 200) {
                dispatch({ type: 'UPDATE_USER', user: response.data })
                setIsLoading(false)
                toast.success('Username successfully changed.')
            }

        } catch (err) {
            if (axios.isCancel(err)) return
            else if (err?.response?.data) {
                if (err.response?.data?.non_field_errors) {
                    toast.error(err.response.data.non_field_errors?.toString())
                }
                setError(err?.response?.data)
            } else {
                toast.error('Unknown error')
            }
            setIsLoading(false)
        }
    }



    return (
        <div className="px-4" >
            <h5 className="p-2 pb-3 border-bottom text-color-light">Username</h5>
            <div className="py-2">



                <div className="row">
                    <div className="col-md-6 py-2">
                        <div className="form-group has-error">
                            <label className="text-color-lighter font-xsss" >Username</label>
                            <input className={`${error.username ? 'is-invalid' : ''} form-control`} type="text" value={username} onChange={e => setUsername(e.target.value)} onClick={() => setError({})} />
                            {error?.username?.length > 0 && <ul>
                                {error?.username.map((err, idx) => <li key={idx} className="help-block text-danger font-xssss  ms-1 font-xssss">{err}</li>)}
                            </ul>}
                        </div>
                        {user.username && <h6 className='small text-muted text-left mt-4'>{process.env.REACT_APP_URL}/<b>{user.username}</b></h6>}

                    </div>
                    <div className="col-md-6 p-2">

                        <div className="my-4">
                            <h6 className="mb-2">Username requirements</h6>
                            <div className="small text-muted ps-2">
                                {/* <span className="d-block">{passwordRequirements.eightOrMore ? <MdCheck className="text-success" /> : <MdClose className="text-danger" />} At Minimum 8 characters.</span>
                                <span className="d-block">{passwordRequirements.specialChar ? <MdCheck className="text-success" /> : <MdClose className="text-danger" />} At least one special character.</span>
                                <span className="d-block">{passwordRequirements.numberChar ? <MdCheck className="text-success" /> : <MdClose className="text-danger" />} At least one number.</span>
                                <span className="d-block">{passwordRequirements.capitalChar ? <MdCheck className="text-success" /> : <MdClose className="text-danger" />} At least one capital letter.</span> */}
                                <span className="d-block">Must be between (3-28) characters.</span>
                                <span className="d-block">Can contain: letters (a-z), numbers (0-9), underscore (_), and period (.)</span>
                            </div>
                        </div>


                    </div>

                    {/* Save */}
                    <div className="d-flex pt-3">
                        <div className="ms-auto">
                            <button className="btn btn-primary font-xsss p-2 px-3" disabled={isLoading || !username || user.username === username?.trim()} onClick={handleChangeUsername}>
                                {isLoading && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}
                                Change Username
                            </button>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )

}