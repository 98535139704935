import { useState, useEffect } from 'react'
import { useParams } from "react-router-dom"
import axios from 'axios'
import CheckAndSetPasswords from '../components/CheckAndSetPasswords'

export default function ResetPasswordView(props) {

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState('')
    const [error, setError] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmNewPassword, setConfirmNewPassword] = useState('')
    const [validPassword, setValidPassword] = useState(false)

    const source = axios.CancelToken.source()

    useEffect(() => {
        document.title = 'reset password'

        return () => source.cancel()
    }, [])

    let { uid, token } = useParams()

    const handleSubmit = async(e) => {
        e.preventDefault()
        setError('')
        setMessage('')

        if (validPassword) {
            setIsLoading(true)
            try {
                const response = await axios.post('/accounts/reset_password/',
                    {
                        password: newPassword,
                        uid,
                        token
                    }, {
                        cancelToken: source.token
                    })

                if (response?.status === 204) {
                    setMessage('Password Successfully Set')
                    setNewPassword('')
                    setConfirmNewPassword('')
                }

            } catch (err) {
                if (axios.isCancel(err)) return
                setError(err.response?.data)

            } finally {
                setIsLoading(false)
            }

        } else {
            setError({ password: ['Invalid password.'], confirm_password: ['Invalid password.'] })
        }

    }



    return (

        <div className="fluid-container" id="reset-password">
            <div className="row m-0">

                <div className='col-12 d-flex justify-content-center vertical-center'>

                    <div className="card mt-1 mt-md-3 py-4 px-5 border rounded-xl account-form">

                        <h5 className="text-center mb-4 text-main-color">Reset password</h5>

                        {message && <li className="alert alert-success alert-dismissible fade show p-2 px-3 font-bold font-xsss rounded-xxxl">
                            {message}
                            <button onClick={() => setMessage('')} className="btn-close pt-2 font-bold font-xssss"></button>
                        </li>}

                        {error?.non_field_errors?.length > 0 && <ul>
                            {error?.non_field_errors.map((err, idx) =>
                                <li key={idx} className="alert alert-danger alert-dismissible fade show p-2 px-3 font-bold font-xsss rounded-xxxl">
                                    {err}
                                    <button onClick={() => setError(prev => ({ ...prev, non_field_errors: prev.non_field_errors.filter(e => e !== err) }))} className="btn-close pt-2 font-bold font-xssss"></button>
                                </li>
                            )}
                        </ul>}

                        {error?.token?.length > 0 && <ul>
                            {error?.token.map((err, idx) =>
                                <li key={idx} className="alert alert-danger alert-dismissible fade show p-2 px-3 font-bold font-xsss rounded-xxxl">
                                    {err}
                                    <button onClick={() => setError(prev => ({ ...prev, token: prev.token.filter(e => e !== err) }))} className="btn-close pt-2 font-bold font-xssss"></button>
                                </li>
                            )}
                        </ul>}


                        <form onSubmit={handleSubmit} onClick={() => setError({})}>
                            <CheckAndSetPasswords
                                error={error}
                                password={newPassword}
                                setPassword={setNewPassword}
                                enableConfirmPassword={true}
                                confirmPassword={confirmNewPassword}
                                setConfirmPassword={setConfirmNewPassword}
                                setValidPassword={setValidPassword}
                            />


                            {/* set password */}
                            <div className="d-grid my-4">
                                <button type="submit" className="btn btn-primary p-2 px-3" disabled={isLoading}>
                                    {isLoading && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}
                                    Reset password
                                </button>
                            </div>

                        </form>

                    </div>
                </div>
            </div>
        </div>

    )

}
