export default function Logo() {
    return <g id="XMLID_2_">
        <path d="M155.4,171.8H69.2c-28.7,0-43.1-14.4-43.1-43.1V71.3c0-28.7,14.4-43.1,43.1-43.1h86.2
    c9.6,0,14.4,4.8,14.4,14.4c0,9.6-4.8,14.4-14.4,14.4H83.6c-19.1,0-28.7,9.6-28.7,28.7v28.7c0,19.1,9.6,28.7,28.7,28.7h71.8
    c9.6,0,14.4,4.8,14.4,14.4C169.7,167,164.9,171.8,155.4,171.8z"/>
        <path d="M313.3,85.6c0-19.1-9.6-28.7-28.7-28.7h-28.7c-19.1,0-28.7,9.6-28.7,28.7v71.8
    c0,9.6-4.8,14.4-14.4,14.4c-9.6,0-14.4-4.8-14.4-14.4V71.3c0-28.7,14.4-43.1,43.1-43.1H299c12.2,0,21.7,2.6,28.7,7.8
    c7-5.2,16.6-7.8,28.7-7.8h57.4c28.7,0,43.1,14.4,43.1,43.1v86.2c0,9.6-4.8,14.4-14.4,14.4c-9.6,0-14.4-4.8-14.4-14.4V85.6
    c0-19.1-9.6-28.7-28.7-28.7h-28.7c-19.1,0-28.7,9.6-28.7,28.7v71.8c0,9.6-4.8,14.4-14.4,14.4c-9.6,0-14.4-4.8-14.4-14.4V85.6z"/>
        <path d="M586.2,171.8h-57.4c-28.7,0-43.1-14.4-43.1-43.1V42.6c0-9.6,4.8-14.4,14.4-14.4
    c9.6,0,14.4,4.8,14.4,14.4v71.8c0,19.1,9.6,28.7,28.7,28.7h28.7c19.1,0,28.7-9.6,28.7-28.7V42.6c0-9.6,4.8-14.4,14.4-14.4
    c9.6,0,14.4,4.8,14.4,14.4v86.2C629.2,157.4,614.9,171.8,586.2,171.8z"/>
        <path d="M758.5,28.2c28.7,0,43.1,14.4,43.1,43.1v86.2c0,9.6-4.8,14.4-14.4,14.4
    c-9.6,0-14.4-4.8-14.4-14.4V85.6c0-19.1-9.6-28.7-28.7-28.7h-28.7c-19.1,0-28.7,9.6-28.7,28.7v71.8c0,9.6-4.8,14.4-14.4,14.4
    c-9.6,0-14.4-4.8-14.4-14.4V71.3c0-28.7,14.4-43.1,43.1-43.1H758.5z"/>
        <path d="M945.1,71.3c0-9.6-4.8-14.4-14.4-14.4h-86.2c-9.6,0-14.4-4.8-14.4-14.4
    c0-9.6,4.8-14.4,14.4-14.4h86.2c28.7,0,43.1,14.4,43.1,43.1v57.4c0,28.7-14.4,43.1-43.1,43.1h-57.4c-28.7,0-43.1-14.4-43.1-43.1
    s14.4-43.1,43.1-43.1h57.4C940.4,85.6,945.1,80.9,945.1,71.3z M945.1,128.7c0-9.6-4.8-14.4-14.4-14.4h-43.1
    c-19.1,0-28.7,4.8-28.7,14.4c0,9.6,9.6,14.4,28.7,14.4h43.1C940.4,143.1,945.1,138.3,945.1,128.7z"/>
    </g>
}