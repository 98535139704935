import { useState } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import dayjs from 'dayjs'
import ContentEditor from '../editor/ContentEditor'
import File from "../components/File"
import PreviewUrl from "../components/PreviewUrl"
import { FaRegEye } from 'react-icons/fa'


export default function Message({ message, position }) {
    const createdAtDateFormat = 'YYYY-MM-DD HH:mm:ss'


    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    // const [currentMessage, setCurrentMessage] = useState(message)
    const [editMode, setEditMode] = useState(false)

    const user = useSelector((state) => state.userReducer.user)


    // // edit message
    // const handleUpdateMessage = async (e) => {
    //     e.preventDefault()
    //     setIsLoading(true)
    //     setError('')
    //     setMessage('')

    //     try {
    //         const response = await axios.patch('/newsfeed/message/', {
    //             message_id: currentMessage.id,
    //             content: currentMessage.content,
    //         })
    //         console.log('response', response)
    //         if (response?.status === 204) {
    //             setMessage('Message Successfully updated')
    //             setCurrentMessage({ ...currentMessage, content: currentMessage.content })
    //             setEditMode(false)
    //             setIsLoading(false)
    //         }

    //     } catch (err) {
    //         // throw err
    //         console.log(err)
    //         console.log(err.response)
    //         setError(JSON.stringify(err.response?.data))
    //         setIsLoading(false)
    //     }
    // }


    // // delete message
    // const handleDeleteMessage = async () => {
    //     try {
    //         const response = await axios.delete('/newsfeed/message/', {
    //             data: {
    //                 message_id: currentMessage.id
    //             }
    //         })

    //         if (response?.status === 204) {
    //             console.log('Message Successfully deleted')
    //             updateMessages(message, 'delete')
    //         }

    //     } catch (err) {
    //         // throw err
    //         console.log(err)
    //         console.log(err.response)
    //     }
    // }

    return (
    // <>
    //     {/* check if user is the user */}
    //     {/* {currentMessage.user.id === user.id &&
    //         <span>
    //             {editMode && <span onClick={() => { setCurrentMessage(message) setEditMode(false) }}> cancel edit </span>}
    //             {!editMode && <span onClick={() => setEditMode(true)}> edit </span>}
    //             <span onClick={handleDeleteMessage}> delete </span>
    //         </span>
    //     } */}

    //     {/* view message */}
    //     {!editMode && message &&
    //         <>
    //             <h4>{message.user.profile.full_name || message.user.profile.first_name}</h4>
    //             <span>{dayjs(message.created_at).format(createdAtDateFormat)}</span>

    //             <ContentEditor
    //                 formattedContent={message.formatted_content}
    //                 uploadedFileList={message.files}
    //                 urlPreviewsList={message.url_previews}
    //                 readOnly={true}
    //                 enableEmoji={true}
    //             />

    //             {/* {message.files.length > 0 && message.files.map(file => <File file={file} />)} */}

    //             {/* {message.url_previews?.length > 0 && message.url_previews.map((urlPreview, idx) => <PreviewUrl urlPreview={urlPreview} editMode={editMode} key={idx} />)} */}

    //             {message.read_by.length > 0 && message.read_by.map(item => <span key={item.read_at}>read by {item.user.profile.first_name} @ {dayjs(item.read_at).format('hh:mm:ss a')} <br /></span>)}
    //         </>
    //     }

    //     {/* Edit message */}
    //     {/* {editMode && !isLoading &&
    //         <form onSubmit={handleUpdateMessage}>
    //             <label>
    //                 <input type="textarea" value={currentMessage.content} onChange={e => setCurrentMessage({ ...currentMessage, content: e.target.value })} />
    //             </label>
    //             <input type="submit" value="Update message" />
    //         </form>
    //     } */}
    // </>



        <div className={`${position === "start" ? "flex-row" : "flex-row-reverse"} d-flex pt-1`}>
            <img src={message.user.profile.thumbnail} alt="user" className="rounded-circle" style={{ width: "45px", height: "45px" }} />
            {/* <h5>{message.user.profile.full_name || message.user.profile.first_name}</h5> */}

            <div className='mx-2 mb-1' style={{ minWidth: 0 }}>
                <div className={`${position === "start" ? "text-color background-color-lighter" : "text-white bg-primary"} p-3 rounded-xxl`}>
                    <ContentEditor
                        formattedContent={message.formatted_content}
                        uploadedFileList={message.files}
                        urlPreviewsList={message.url_previews}
                        clampOptions={{ disable: true }}
                        readOnly={true}
                        enableEmoji={true}
                    />

                    {message.read_by.length > 0 && <span className={`${position === "start" ? 'text-muted' : "text-white"} font-xsss`}> <FaRegEye /> <span className="font-xsssss">{dayjs(message.created_at).fromNow()}</span></span>}

                    {/* <p className="font-xsssss ms-3 mb-3 rounded-3 text-muted">
                        {message.read_by.length > 0 && message.read_by.map(item => <span key={item.read_at}>read by {item.user.profile.first_name}</span>)}
                        <br />

                        <i className="ti-double-check text-info"></i>
                        {dayjs(message.created_at).fromNow()}
                    </p> */}

                </div>
            </div>

        </div>
    )
}