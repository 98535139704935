import store from './store'
import { eventEmitter } from './events'


let chatReconnectTimeoutVal = 1000
let notificationsReconnectTimeoutVal = 1000

export const connectNotificationsWs = () => {
    const storeUser = store.getState().userReducer

    if (storeUser.isAuthenticated) {
        const notificationsWs = new WebSocket(`${process.env.REACT_APP_WS_URL}/notifications/`)

        notificationsWs.onopen = () => {
            // on connecting, do nothing but log it to the console
            console.log('notifications connected')

            try {
                notificationsWs.send(JSON.stringify({ command: "fetch_friend_notifications" }))
                notificationsWs.send(JSON.stringify({ command: "fetch_general_notifications" }))
            } catch (err) {
                console.log(err) // catch error
            }
        }

        notificationsWs.onmessage = evt => {
            // listen to data sent from the websocket server
            const payload = JSON.parse(evt.data)

            if (payload.command === 'fetch_friend_notifications') {
                store.dispatch({ type: 'SET_FRIENDS_NOTIFICATION', count: payload.count })
            }
            else if (payload.command === 'fetch_general_notifications') {
                // setGeneralNotifications(payload)
                store.dispatch({ type: 'SET_GENERAL_NOTIFICATION', count: payload.count })
            }
            else if (payload.command === 'friend_notification') {
                store.dispatch({ type: 'UPDATE_FRIENDS_NOTIFICATION', count: 1 })
            }
            else if (payload.command === 'general_notification') {
                store.dispatch({ type: 'UPDATE_GENERAL_NOTIFICATION', count: 1 })
            }
        }


        notificationsWs.onclose = (event) => {
            console.log('notifications disconnected', event)
            // automatically try to reconnect on connection loss (not when we closed it)
            if (event.code !== 1000) {
                setTimeout(() => {
                    notificationsReconnectTimeoutVal = notificationsReconnectTimeoutVal * 2
                    connectNotificationsWs()
                }, notificationsReconnectTimeoutVal)
            }
        }


    }
}



export const connectChatWs = () => {
    const storeUser = store.getState().userReducer

    if (storeUser.isAuthenticated) {
        const chatWs = new WebSocket(`${process.env.REACT_APP_WS_URL}/chat/`)

        chatWs.onopen = () => {
            // on connecting, do nothing but log it to the console
            console.log('chat connected')
            store.dispatch({ type: 'SET_CHAT_WS', ws: chatWs })

            try {
                chatWs.send(JSON.stringify({ command: "fetch_unread_messages" }))
            } catch (err) {
                console.log(err) // catch error
            }
        }


        chatWs.onmessage = evt => {
            // listen to data sent from the websocket server
            const payload = JSON.parse(evt.data)
            console.log('payload', payload)

            if (payload.command === 'fetch_unread_messages') {
                store.dispatch({ type: 'SET_MESSAGES_NOTIFICATION', count: payload.count })

            }

            if (payload.command === 'fetch_or_create_room') {
                eventEmitter.dispatch('fetch_or_create_room', payload)
            }

            if (payload.command === 'fetch_room_list') {
                eventEmitter.dispatch('fetch_room_list', payload)
            }

            else if (payload.command === 'new_message') {
                store.dispatch({ type: 'UPDATE_MESSAGES_NOTIFICATION', count: 1 })
                store.dispatch({ type: 'UPDATE_LAST_MESSAGE', payload })
                eventEmitter.dispatch('new_message', payload)
            }

            else if (payload.command === 'fetch_room_messages') {
                eventEmitter.dispatch('fetch_room_messages', payload)
            }

            else if (payload.command === 'typing_status') {
                store.dispatch({ type: 'UPDATE_TYPING_STATUS', payload })
                eventEmitter.dispatch('typing_status', payload)
            }

            else if (payload.command === 'messages_read') {
                eventEmitter.dispatch('messages_read', payload)

            }
        }


        chatWs.onclose = (event) => {
            console.log('chat disconnected', event)
            // automatically try to reconnect on connection loss (not when we closed it)
            if (event.code !== 1000) {
                setTimeout(() => {
                    chatReconnectTimeoutVal = chatReconnectTimeoutVal * 2
                    connectChatWs()
                }, chatReconnectTimeoutVal)
            } else {
                store.dispatch({ type: 'SET_CHAT_WS', ws: null })
            }
        }


    }
}
