import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from "react-router-dom"
import axios from 'axios'
import qs from 'qs'
import { regex } from '../utils/utils'
import Login from './Login'

export default function LoginView() {
    const [checkingLoginStatus, setCheckingLoginStatus] = useState(true)
    const [error, setError] = useState({})
    const [next, setNext] = useState('/')


    const dispatch = useDispatch()
    const history = useHistory()

    const source = axios.CancelToken.source()


    const getUserData = async(next) => {
        try {
            const response = await axios.get('/accounts/me/', {
                cancelToken: source.token
            })

            if (response?.status === 200) {
                const data = { user: response.data }
                dispatch({ type: 'SIGN_IN', data })

                if (next) {
                    history.push(next)
                } else {
                    history.push(next)
                }
            }

        } catch (err) {
            if (axios.isCancel(err)) return
            setCheckingLoginStatus(false)
        }
    }

    useEffect(() => {
        document.title = 'login'
        // parse url params
        const urlParams = qs.parse(history.location?.search, { ignoreQueryPrefix: true })
        // console.log('urlParams', urlParams)
        const fromPath = history.location?.state?.from?.pathname
        const nextPath = fromPath && fromPath !== '/login' ? fromPath : '/'


        if (urlParams?.status === 'successful_login') {
            getUserData(urlParams?.next)
        } else {
            setCheckingLoginStatus(false)
        }

        if (urlParams?.error) {
            setError({ non_field_errors: [urlParams.error] })
        }

        setNext(nextPath)

        return () => source.cancel()

    }, [])


    return (
        <div className="fluid-container">
            <div className="row m-0">
                {!checkingLoginStatus && <div className='col-12 d-flex justify-content-center vertical-center'>
                    <Login next={next} paramError={error}/>
                </div>}
            </div>
        </div>
    )

}
