import { Link } from "react-router-dom"

const year = new Date().getFullYear()

export default function About() {

    return (
        <div className="pb-5 prevent-select">
            {/* <div className="card-body d-flex p-2">
                <h4 className="fw-500 mb-0 font-xssss text-grey-900">About</h4>
            </div> */}

            <div className=''>
                <div className="text-center">
                    <Link to='/terms' className="font-xssss text-primary"> Terms </Link>
                    <span className="mx-2 text-color-lightest"> </span>
                    <Link to='/privacy' className="font-xssss text-primary"> Privacy </Link>
                </div>
            </div>

            <div className='text-center '>
                <span className="font-xssss text-color-lighter"> {`cmuna © ${year}. All rights reserved.`} </span>
            </div>
        </div>
    )
}