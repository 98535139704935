import { useState, useRef } from 'react'
import axios from 'axios'
import Modal from './Modal'
import Loader from './Loader'

import { FaFilePdf, FaFileAlt, FaFileWord, FaFileExcel, FaFilePowerpoint, FaFileArchive, FaFileCsv, FaFile, FaFileVideo, FaFileAudio, FaDownload, FaFileDownload } from 'react-icons/fa'


export default function UploadFile({ fileInfo, editMode }) {
    const [imgLoaded, setImgLoaded] = useState(false)

    const imgModal = useRef(null)
    const deleteModal = useRef(null)



    const handleDeleteFile = (file) => {
        if (file.error) {
            fileInfo.deleteFile()
        } else {
            deleteModal.current.open()
        }
    }

    const renderIcon = (fileInfo) => {
        // video in edit mode only
        if (editMode && fileInfo.content_type?.includes('video')) {
            return <FaFileVideo style={{ color: "var(--bs-danger)", fontSize: 40 }} />
        }
        // pdf
        else if (fileInfo.content_type === 'application/pdf') {
            return <FaFilePdf style={{ color: "var(--bs-danger)", fontSize: 40 }} />
        }
        // txt
        else if (fileInfo.content_type === 'text/plain') {
            return <FaFileAlt style={{ color: "var(--bs-gray-600)", fontSize: 40 }} />
        }
        // word
        else if (fileInfo.content_type === 'application/msword' || fileInfo.content_type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            return <FaFileWord style={{ color: "var(--bs-primary)", fontSize: 40 }} />
        }
        // csv
        else if (fileInfo.content_type === 'text/csv') {
            return <FaFileCsv style={{ color: "var(--bs-green)", fontSize: 40 }} />
        }
        // excel
        else if (fileInfo.content_type === 'application/vnd.ms-excel' || fileInfo.content_type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            return <FaFileExcel style={{ color: "var(--bs-green)", fontSize: 40 }} />
        }
        // power point
        else if (fileInfo.content_type === 'application/vnd.ms-powerpoint' || fileInfo.content_type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
            return <FaFilePowerpoint className='text-warning' style={{ color: "var(--bs-warning)", fontSize: 40 }} />
        }
        // zip/rar
        else if (fileInfo.content_type === 'application/zip' || fileInfo.content_type === 'application/x-rar') {
            return <FaFileArchive style={{ color: "var(--bs-gray-700)", fontSize: 40 }} />
        }
        // audio
        else if (fileInfo.content_type?.includes("audio")) {
            return <FaFileAudio style={{ color: "var(--bs-purple)", fontSize: 40 }} />
        }
        // torrent
        else if (fileInfo.content_type === 'application/octet-stream') {
            return <FaFileDownload style={{ color: "var(--bs-green)", fontSize: 40 }} />
        }
        else {
            return <FaFile style={{ color: "var(--bs-gray-700)", fontSize: 40 }} />
        }
    }

    // const downloadFile = async (file) => {
    //     console.log('file', file)

    //     try {
    //         const response = await axios.get(file.fileSrc, {
    //             withCredentials: false,
    //             responseType: 'arraybuffer',
    //             headers: { "Access-Control-Allow-Origin": "*", 'Access-Control-Allow-Headers': 'Content-Type' }
    //         })
    //         console.log('response', response)


    //         if (response?.status === 200) {
    //             const url = window.URL.createObjectURL(new Blob([response.data]))
    //             const link = document.createElement('a')
    //             link.href = url
    //             link.setAttribute('download', file.content_type) //or any other extension
    //             document.body.appendChild(link)
    //             link.click()
    //         }

    //     } catch (err) {
    //         console.log('err', err)
    //         // if (axios.isCancel(err)) return
    //         // setError(JSON.stringify(err.response?.data))
    //         // setIsLoading(false)
    //     }
    // }

    return (
        <div className={`${editMode ? 'background-color-lightest mx-1 my-2' : 'background-color-lighter mx-1 my-2 rounded-3'} position-relative rounded p-0 mb-3"`}>

            {editMode &&
                <>
                    {fileInfo.error && <span className="position-absolute text-danger background-color-lighter rounded-3 px-2 py-1 m-1 mt-4 fw-500 font-xsssss">{fileInfo.error}</span>}
                    {/* delete */}
                    <span onClick={() => handleDeleteFile(fileInfo)} className="position-absolute pointer mt-1 right-0 btn-round-xs background-color-light text-color" style={{ zIndex: 2 }}> &#x2715; </span>
                    {/* confirmation deleteModal */}
                    <Modal
                        title="Are you sure you want to delete this file?"
                        actionBtns={[
                            { text: "Cancel", classes: "btn text-color font-xssss p-2 px-3 me-2", closeOnClick: true, onClick: null },
                            { text: "Yes", classes: "btn btn-primary font-xssss text-white p-2 px-3 me-2", onClick: fileInfo.deleteFile }
                        ]}
                        ref={deleteModal}>
                    </Modal>

                    {/* upload percentage */}
                    {fileInfo.percentage > 0 && <div className="progress p-0 m-0 rounded-0 rounded-top">
                        <div className={`${fileInfo.uploaded ? 'bg-success' : null}  progress-bar progress-bar-striped font-xsssss`} style={{ width: `${fileInfo.percentage}%` }} aria-valuenow={fileInfo.percentage} aria-valuemin="0" aria-valuemax="100">
                            {fileInfo.percentage}% {fileInfo.uploaded ? '✓' : ''}
                        </div>
                    </div>}
                </>
            }

            {fileInfo.content_type?.includes('image') ? <>
                <div className='rounded-bottom' >
                    <img onClick={() => imgModal.current.open()} src={fileInfo.thumbnail ? fileInfo.thumbnail : fileInfo.fileSrc} className="pointer rounded" alt={fileInfo.name} style={{ height: fileInfo.error || !editMode ? 115 : 100, width: 107, objectFit: 'cover' }} />
                </div>
                <Modal
                    title="Image"
                    ref={imgModal}
                >
                    <div className='background-color' style={{ width: "85vw", height: "85vh" }}>
                        {!imgLoaded && <Loader height="85vh" width="100%">
                            <rect x="15%" y="0" rx="8" ry="8" width="70%" height="100%" />
                        </Loader>}

                        <img src={fileInfo.fileSrc}
                            className="rounded"
                            alt={fileInfo.name}
                            style={{ width: "100%", height: "100%", objectFit: 'contain', display: imgLoaded ? "block" : "none", margin: "0 auto" }}
                            onLoad={() => setImgLoaded(true)}
                        />
                    </div>
                </Modal>
            </> :
                <div style={{ height: 85, minWidth: 100, objectFit: 'cover' }} title={fileInfo.name}>

                    <div className='text-center mt-2 font-xl'>
                        {renderIcon(fileInfo)}
                    </div>

                    {/* <div onClick={() => { downloadFile(fileInfo) }} className='w-100 rounded-bottom background-color position-absolute bottom-0 ps-2 font-xssss'>{fileInfo.name?.length > 13 ? fileInfo.name.substring(0, 13) + "..." : fileInfo.name}</div> */}
                    <div className='w-100 text-color-light background-color position-absolute d-flex bottom-0 p-1 rounded-bottom'>
                        <span className='font-xsssss w70 text-truncate' style={{ cursor: 'default' }}>{fileInfo.name}</span>
                        {!editMode && <a href={fileInfo.fileSrc} className='ms-auto text-color-light font-xssss lh-sm ps-1' download={fileInfo.name} target='_blank' rel="noreferrer"><FaDownload /></a>}
                    </div>

                </div>
            }

        </div>
    )
}