import { useState, useEffect } from 'react'
import { useParams } from "react-router-dom"
import { useSelector } from 'react-redux'
import axios from 'axios'
import { toast } from 'react-toastify'
import NotFound from '../404/NotFoundView'
import Post from './post/Post'

export default function PostView() {

    const [isLoading, setIsLoading] = useState(false)
    const [post, setPost] = useState(null)
    const [notFound, setNotFound] = useState(false)
    const isAuthenticated = useSelector((state) => state.userReducer.isAuthenticated)
    const { postId } = useParams()


    const formatTitle = post => {
        return `${post.user.profile.first_name} ${post.content ? ` | ${post.content.slice(0, 25)}...` : ``}`
    }

    useEffect(() => {
        const source = axios.CancelToken.source()

        async function fetchData() {
            setIsLoading(true)

            try {
                // pass route params
                const response = await axios.get(`/newsfeed/post/${postId}/`, {
                    cancelToken: source.token
                })

                if (response?.status === 200) {
                    setPost(response.data)
                    setIsLoading(false)
                    document.title = formatTitle(response.data)
                }


            } catch (err) {
                if (axios.isCancel(err)) return
                if (err.response?.status && err.response?.status === 404) {
                    setNotFound(true)
                }
                toast.error(err?.response?.data?.non_field_errors?.toString() || err?.message || 'An unknown error occurred.')
                setIsLoading(false)
            }
        }
        if (postId.length === 16) {
            fetchData()
        } else {
            setNotFound(true)
        }


        return () => {
            return source.cancel()
        }

    }, [postId])

    return notFound ?
        <NotFound /> :

        <div className="main-content right-chat-active">
            <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left">
                    <div className="text-color">
                        {post && <Post loading={isLoading} post={post} disableClamp={true} renderComments={true} isAuthenticated={isAuthenticated}/>}
                    </div>
                </div>
            </div>
        </div>
}
