import { useState, useEffect, useRef, useReducer } from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from "react-router-dom"
import axios from 'axios'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'
import useCloseWhenClickOut from '../../hooks/useCloseWhenClickOut'
import CreateComment from '../comment/CreateComment'
import SharedPost from './SharedPost'
import CreatePost from './CreatePost'
import Comment from '../comment/Comment'
import Modal from '../../components/Modal'
import ContentEditor from '../../editor/ContentEditor'
import Loader from '../../components/Loader'
import Login from '../../accounts/Login'
import { FaThumbsUp, FaCommentAlt, FaShare, FaPencilAlt, FaTrash, FaExclamationCircle } from 'react-icons/fa'
import { MdOutlineMoreHoriz, MdShare, MdOutlineFileCopy, MdPeople } from "react-icons/md"

export default function Post({ post, loading, updatePosts, disableClamp, renderComments, isAuthenticated }) {
    let componentPost = useRef(post)
    const createdAtDateFormat = 'YYYY-MM-DD HH:mm:ss'


    const [isLoading, setIsLoading] = useState(loading)
    const [isLikeLoading, setIsLikeLoading] = useState(false)
    const [isLoadingComments, setIsLoadingComments] = useState(false)
    const [error, setError] = useState(null)
    const [currentPost, setCurrentPost] = useState(componentPost.current)
    const [showSharePost, setShowSharePost] = useState(false)
    const [editorCount, setEditorCount] = useState(0)

    const [comments, setComments] = useState(null)
    const [showComments, setShowComments] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [showShareOptions, setShowShareOptions] = useState(false)
    const [showPostOptions, setShowPostOptions] = useState(false)

    const user = useSelector((state) => state.userReducer.user)

    const deleteModalRef = useRef(null)
    const shareModalRef = useRef(null)
    const loginModalRef = useRef(null)
    const postShareRef = useRef(null)
    const postOptionsRef = useRef(null)

    const source = axios.CancelToken.source()

    // handle close popups when click outside the popup element
    useCloseWhenClickOut(postShareRef, setShowShareOptions)
    useCloseWhenClickOut(postOptionsRef, setShowPostOptions)


    useEffect(() => {
        setIsLoading(loading)
    }, [loading])

    // handle errors
    useEffect(() => {
        if (error) {
            toast.error(error?.response?.data?.non_field_errors?.toString() || error?.message || 'An unknown error occurred.')
            setError('')
        }
    }, [error])

    // handle render comments
    useEffect(() => {
        if (renderComments) {
            handleComments()
        }
    }, [renderComments])

    const handleEditBtn = () => {
        setEditorCount(prev => prev + 1)
        setEditMode(true)
        setShowPostOptions(false)
    }

    const handleCancelBtn = () => {
        setCurrentPost(componentPost.current)
        setEditorCount(prev => prev + 1)
        setEditMode(false)
        setShowPostOptions(false)
    }

    const handleDeleteBtn = () => {
        deleteModalRef.current.open()
        setShowPostOptions(false)
    }

    const handleShareBtn = () => {
        setShowPostOptions(false)
        setShowSharePost(true)
        shareModalRef.current.open()
        setShowShareOptions(false)
    }

    const copyToClipboard = (id) => {
        navigator.clipboard.writeText(`${process.env.REACT_APP_URL}/post/${id}`)
        setShowShareOptions(false)
        toast.success('Link was copied to the clipboard.')
        toast.clearWaitingQueue()
    }



    // edit post
    const handleUpdatePost = async(content, formattedContent, contentWithEmojis, mentions, filteredUploadedFiles, urlPreviews) => {
        try {
            const response = await axios.patch(`/newsfeed/post/${currentPost.public_id}/`, {
                content: content,
                formatted_content: formattedContent,
                mentions: mentions,
                public: currentPost.public,
                url_previews: urlPreviews,
                files_id: [...filteredUploadedFiles.map(f => f.id)]
            }, {
                cancelToken: source.token
            })

            if (response?.status === 204) {
                setCurrentPost(prev => {
                    const updatedPost = { ...prev, content: content, formatted_content: formattedContent, files: filteredUploadedFiles, url_previews: urlPreviews, edited_at: new Date().getTime() }
                    componentPost.current = updatedPost
                    return updatedPost
                })
                setEditorCount(prev => prev + 1)
                setEditMode(false)
                return ({ message: 'Post Successfully updated' })
            }

        } catch (err) {
            if (axios.isCancel(err)) return
            throw Error(err.response?.data?.non_field_errors)
        }
    }


    // delete post
    const handleDeletePost = async() => {
        setIsLoading(true)

        try {
            const response = await axios.delete(`/newsfeed/post/${currentPost.public_id}/`, {
                cancelToken: source.token
            })
            if (response?.status === 204) {
                if (updatePosts) {
                    updatePosts(currentPost, 'delete')
                }
            }
        } catch (err) {
            if (axios.isCancel(err)) return
            setError(err)

        } finally {
            setIsLoading(false)
            deleteModalRef.current.close()
        }
    }


    // like
    const handleLike = async(e) => {
        if (isLikeLoading) return

        else if (!isAuthenticated) return loginModalRef.current.open()

        try {
            setIsLikeLoading(true)
            const response = await axios.post(`/newsfeed/like/${currentPost.public_id}/`, null, {
                cancelToken: source.token
            })
            if (response?.status === 204) {
                setCurrentPost({ ...currentPost, liked: true, likes: currentPost.likes + 1 })
            }
        } catch (err) {
            if (axios.isCancel(err)) return
            // setError(err)
        } finally {
            setIsLikeLoading(false)
        }
    }

    // unlike
    const handleUnlike = async(e) => {
        if (isLikeLoading) return

        try {
            setIsLikeLoading(true)
            const response = await axios.delete(`/newsfeed/like/${currentPost.public_id}/`, {
                cancelToken: source.token
            })
            if (response?.status === 204) {
                setCurrentPost({ ...currentPost, liked: false, likes: currentPost.likes - 1 })
            }
        } catch (err) {
            if (axios.isCancel(err)) return
            // setError(err)
        } finally {
            setIsLikeLoading(false)
        }
    }

    // show & hide comments
    const handleComments = async() => {
        if (showComments) {
            return setShowComments(false)
        }

        try {
            setIsLoadingComments(true)
            const response = await axios.get(`/newsfeed/comments/${post.public_id}/`, {
                cancelToken: source.token
            })
            if (response?.status === 200) {
                setComments(response.data)
                setShowComments(true)
            }

        } catch (err) {
            if (axios.isCancel(err)) return
            setError(err)
        } finally {
            setIsLoadingComments(false)
        }

    }

    const HandleGetMoreComments = async(url) => {
        try {
            setIsLoadingComments(true)
            const response = await axios.get(url, {
                cancelToken: source.token
            })
            if (response?.status === 200) {
                setComments(prev => ({ ...prev, next: response.data.next, previous: response.data.previous, results: [...prev.results, ...response.data.results] }))
            }

        } catch (err) {
            if (axios.isCancel(err)) return
            setError(err)
        } finally {
            setIsLoadingComments(false)
        }
    }

    // update comments
    const updateComments = (comment, action) => {
        if (action === 'add') {
            setComments({ ...comments, results: [comment, ...comments.results] })
            setCurrentPost({ ...currentPost, comments: currentPost.comments + 1 })
        }
        else if (action === 'delete') {
            setComments({ ...comments, results: [...comments.results.filter(item => comment.id !== item.id)] })
            setCurrentPost({ ...currentPost, comments: currentPost.comments - 1 })
        }
    }


    useEffect(() => {
        return () => source.cancel()
    }, [])

    return (
        <div className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">

            {isLoading ?
                <Loader height={380}>
                    <circle cx="20" cy="20" r="20" />
                    <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
                    <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
                    <rect x="0" y="72" rx="3" ry="3" width="350" height="6" />
                    <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
                    <rect x="0" y="110" rx="5" ry="5" width="100%" height="220" />

                    <rect x="0" y="360" rx="4" ry="4" width="70" height="12" />
                    <rect x="100" y="360" rx="4" ry="4" width="70" height="12" />
                    <rect x="200" y="360" rx="4" ry="4" width="70" height="12" />

                </Loader>

                : <>
                    <div className="card-body p-0 d-flex">
                        <figure className="me-3"><img src={currentPost.user.profile.thumbnail} alt="user" className="shadow-sm rounded-circle w45" /></figure>
                        <h4 className="font-xsss mt-1">
                            <NavLink className="fw-500" to={`/${currentPost.user.username ? currentPost.user.username : currentPost.user.id}`} > {currentPost.user.profile.full_name} </NavLink>
                            <span className="d-block font-xssss text-color-lighter mt-1 lh-3 "> {dayjs(currentPost.created_at).fromNow()} {currentPost.edited_at && <span className="font-xsssss mx-2 lh-3 text-color-lightest" title={dayjs(currentPost.edited_at).format('MMM D, YYYY h:mm A')}> Edited </span>}</span>
                        </h4>

                        {/* post options wrapper */}

                        <div className="position-relative ms-auto justify-content-end" ref={postOptionsRef}>
                            {/* cancel btn */}
                            {editMode && <span onClick={handleCancelBtn} className="pointer text-color-lightest  p-2 px-4 lh-20 ls-3 d-inline-block rounded-xl font-xssss fw-500 ls-lg"> Cancel </span>}

                            {!post.public && <MdPeople className="me-3 text-color-lightest" title="Friends only"/>}

                            {/* post options btn */}
                            <span onClick={() => setShowPostOptions(prev => !prev)} className="ms-auto pointer">
                                <MdOutlineMoreHoriz className="text-color-light btn-round-sm background-color-lighter p-2" />
                            </span>

                            {/* post options popup */}
                            <div className={`${showPostOptions ? "show" : null} right-0 w300 dropdown-menu p-4 rounded-xxl`}>
                                {/* check if user is the author */}
                                {isAuthenticated && (post.user.id === user.id) ?
                                    <span>
                                        {/* edit */}
                                        {!editMode &&
                                            <div onClick={handleEditBtn} className="pointer p-0 d-flex">
                                                <FaPencilAlt className="text-color mt-2 me-3 font-md" />
                                                <h5 className="m-0 p-0">
                                                    <span className="fw-500 text-color font-xssss mt-0 me-4 pointer">
                                                        Edit
                                                        <span className="d-block font-xsssss fw-500 mt-1 text-color-lighter">Edit this post</span>
                                                    </span>
                                                </h5>
                                            </div>}


                                        {/* cancel edit */}
                                        {editMode && <div onClick={handleCancelBtn} className="pointer p-0 d-flex">
                                            <span className="text-color-lighter  me-3 font-md mt-0"> &#x2715; </span>
                                            <h5 className="m-0 py-2">
                                                <span className="fw-500 text-color font-xssss mt-0 me-4">
                                                    Cancel
                                                    <span className="d-block font-xsssss fw-500 mt-1 text-color-lighter">Cancel editing this post</span>
                                                </span>
                                            </h5>
                                        </div>}


                                        {/* delete */}
                                        <div onClick={handleDeleteBtn} className="pointer p-0 d-flex">
                                            <FaTrash className="text-color mt-2 me-3 font-md" />
                                            <h5 className="m-0 p-0">
                                                <span className="fw-500 text-color font-xssss mt-0 me-4 pointer">
                                                    Delete
                                                    <span className="d-block font-xsssss fw-500 mt-1 text-color-lighter">Delete this post</span>
                                                </span>
                                            </h5>
                                        </div>

                                        {/* confirmation modal */}
                                        <Modal
                                            title="Are you sure you want to delete this post?"
                                            actionBtns={[
                                                { text: "Cancel", classes: "btn text-color font-xssss p-2 px-3 me-2", closeOnClick: true, onClick: null },
                                                { text: "Yes", classes: "btn btn-primary font-xssss text-white p-2 px-3 me-2", onClick: handleDeletePost }
                                            ]}
                                            ref={deleteModalRef}>
                                        </Modal>

                                    </span> :
                                    <>
                                        {/* report */}
                                        <div className="pointer p-0 d-flex">
                                            <FaExclamationCircle className="text-color mt-2 me-3 font-md" />
                                            <h5 className="m-0 p-0">
                                                <span className="fw-500 text-color font-xssss mt-0 me-4 pointer">
                                                    Report
                                                    <span className="d-block font-xsssss fw-500 mt-1 text-color-lighter">Report this post</span>
                                                </span>
                                            </h5>
                                        </div>
                                    </>
                                }

                            </div>
                        </div>


                    </div>

                    <div className="card-body p-0">
                        <ContentEditor
                            key={editorCount}
                            formattedContent={currentPost.formatted_content}
                            uploadedFileList={currentPost.files}
                            urlPreviewsList={currentPost.url_previews}
                            // placeHolder="Update the post…"
                            clampOptions={{ withToggle: true, disable: disableClamp }}
                            readOnly={!editMode}
                            enableTextFormat={true}
                            enableEmoji={true}
                            enableMention={true}
                            removeMentionPrefix={false}
                            enableHashtag={true}
                            enableUrlPreview={true}
                            enableUploadFiles={false}
                            uploadFilesEndpoint={"newsfeed/media/"}
                            submitButtonName={'Update'}
                            handleSubmit={handleUpdatePost}
                        />
                        {currentPost.shared && <SharedPost post={currentPost.shared_post} />}
                    </div>

                    {/* like, comments & share */}
                    {!editMode && <div className="card-body d-flex p-0 mt-3 prevent-select">

                        {/* like */}
                        <span onClick={currentPost.liked ? handleUnlike : handleLike} className={`${currentPost.liked ? 'text-primary' : 'text-color-lighter'} pointer d-flex align-items-center fw-500 lh-26 font-xsss me-4`}>
                            <FaThumbsUp className="me-2" /> {currentPost.likes} {currentPost.likes > 1 ? 'Likes' : 'Like'}
                        </span>


                        {/* comments */}
                        <span onClick={handleComments} className={`${showComments ? 'text-primary' : 'text-color-lighter'} pointer d-flex align-items-center fw-500 lh-26 font-xsss me-4`}>
                            <FaCommentAlt className="me-2" /> {currentPost.comments} {currentPost.comments > 1 ? 'Comments' : 'Comment'}
                        </span>

                        {/* share wrapper */}
                        <span className="position-relative" ref={postShareRef}>
                            {/* share btn */}
                            <span onClick={() => setShowShareOptions(prev => !prev)} className={`${currentPost.shared_by_me ? 'text-primary' : 'text-color-lighter'} pointer d-flex align-items-center fw-500 lh-26 font-xsss me-4`}>
                                <FaShare className="me-2" /> {currentPost.shares} {currentPost.shares > 1 ? 'Shares' : 'Share'}
                            </span>

                            {/* share post options popup */}
                            <div className={`${showShareOptions ? "show" : null} background-color-lightest w300 dropdown-menu p-4 rounded-xxl`} >

                                {/* share */}
                                {(isAuthenticated && post.public && !currentPost.shared_by_me) && <>
                                    <div onClick={handleShareBtn} className="pointer p-0 d-flex">
                                        <MdShare className="text-color mt-2 me-3 font-md" />
                                        <h5 className="m-0 p-0">
                                            <span className="fw-500 text-color  font-xssss mt-0 me-4 pointer">
                                                Share
                                                <span className="d-block font-xsssss fw-500 mt-1 text-color-lighter">Share this post on your profile.</span>
                                            </span>
                                        </h5>
                                    </div>

                                    {/* share popup */}
                                    <Modal title="Share post" ref={shareModalRef}>
                                        {/* create shared post */}
                                        <div className='m-1' style={{ width: 800, maxWidth: '100%' }}>
                                            {showSharePost && <CreatePost sharedPost={currentPost} setCurrentPost={setCurrentPost} shareModalRef={shareModalRef} insidePopup={true} />}
                                        </div>
                                    </Modal>
                                </>}


                                {/* copy post link */}
                                <div onClick={() => copyToClipboard(currentPost.public_id)} className="pointer p-0 d-flex">
                                    <MdOutlineFileCopy className="text-color mt-2 me-3 font-md" />
                                    <h5 className="m-0 p-0">
                                        <span className="fw-500 text-color font-xssss mt-0 me-4 pointer">
                                            Copy link
                                            <span className="d-block font-xsssss fw-500 mt-1 text-color-lighter">Copy post link to clipboard</span>
                                        </span>
                                    </h5>
                                </div>
                            </div>
                        </span>
                    </div>}

                    {showComments &&
                        <div className="pt-4">
                            {comments.next && <span onClick={() => HandleGetMoreComments(comments.next)} className='d-block pointer text-primary text-center fw-500 font-xssss'>View more</span>}

                            {/* loading comments */}
                            {isLoadingComments && [...Array(2)].map((_, i) =>
                                <Comment loading={true} key={i} />
                            )}

                            {(!isAuthenticated && !isLoadingComments && comments.results.length === 0) && <div className='text-center text-color-lightest font-xssss p-2'>
                                <span> No comments yet.</span>
                            </div>}

                            {comments.results?.length > 0 && <div className='d-flex flex-column-reverse'>
                                {comments.results.map(comment => <Comment loading={comment.loading} comment={comment} key={comment.id} updateComments={updateComments} isAuthenticated={isAuthenticated}/>)}
                            </div>}

                            {isAuthenticated && <CreateComment postId={currentPost.public_id} updateComments={updateComments} user={user} />}
                        </div>
                    }
                </>}

            {/* login popup */}
            <Modal ref={loginModalRef}>
                <Login next={`/post/${currentPost?.public_id}`}/>
            </Modal>

        </div>
    )

}