import { useState, useEffect } from 'react'
import { MdCheck, MdClose } from 'react-icons/md'

export default function CheckAndSetPasswords({ isLoading, error, password, setPassword, enableConfirmPassword, confirmPassword, setConfirmPassword, setValidPassword }) {

    const pwReqObj = { show: false, capitalChar: false, specialChar: false, numberChar: false, eightOrMore: false, matchingPasswords: false }

    const [passwordRequirements, setPasswordRequirements] = useState(pwReqObj)

    useEffect(() => {
        if (password || confirmPassword) {
            let pwReq = { ...pwReqObj, show: true }
            const lengthRegex = /^.{8,48}$/
            const numberRegex = /^(?=.*[0-9]).*$/
            const capitalRegex = /^(?=.*[A-Z]).*$/
            const specialRegex = /^(?=.*[~`!@#$%^&*()--+={}[\]|\\:;"'<>,.?/_₹]).*$/

            if (lengthRegex.test(password)) {
                pwReq.eightOrMore = true
            } else {
                pwReq.eightOrMore = false
            }

            if (capitalRegex.test(password)) {
                pwReq.capitalChar = true
            } else {
                pwReq.capitalChar = false
            }

            if (numberRegex.test(password)) {
                pwReq.numberChar = true
            } else {
                pwReq.numberChar = false
            }

            if (specialRegex.test(password)) {
                pwReq.specialChar = true
            } else {
                pwReq.specialChar = false
            }

            if (enableConfirmPassword) {
                if (password === confirmPassword) {
                    pwReq.matchingPasswords = true
                } else {
                    pwReq.matchingPasswords = false
                }
            }


            if (pwReq.eightOrMore && pwReq.capitalChar && pwReq.numberChar && pwReq.specialChar && (!enableConfirmPassword || pwReq.matchingPasswords)) {
                setValidPassword(true)
            } else {
                setValidPassword(false)
            }

            setPasswordRequirements(pwReq)
        } else {
            setPasswordRequirements(prev => ({ ...prev, show: false }))
        }

    }, [password, confirmPassword])


    return (
        <>
            <div className="col my-1">
                <label className="text-color-light font-xsss">Password</label>
                <input className={`${error.password ? 'is-invalid' : ''} form-control`} type="password" placeholder="password" value={password} disabled={isLoading} onChange={e => setPassword(e.target.value)} />
                {error?.password?.length > 0 && <ul>
                    {error?.password.map((err, idx) => <li key={idx} className="help-block text-danger font-xssss  ms-1">{err}</li>)}
                </ul>}
            </div>

            {enableConfirmPassword && <div className="col my-1">
                <label className="text-color-light font-xsss" >Confirm password</label>
                <input className={`${error.confirm_password ? 'is-invalid' : ''} form-control`} type="password" placeholder="Confirm password" value={confirmPassword} disabled={isLoading} onChange={e => setConfirmPassword(e.target.value)} />
                {error.confirm_password?.length > 0 && <ul className='w-100'>
                    {error.confirm_password.map((err, idx) => <li key={idx} className="help-block text-danger font-xssss  ms-1">{err}</li>)}
                </ul>}
            </div>}

            {passwordRequirements.show && <div className="mt-3">
                <h6 className="mb-2 font-xssss">Password requirements</h6>
                <div className="font-xssss text-muted ps-2">
                    <span className="d-block">{passwordRequirements.eightOrMore ? <MdCheck className="text-success" /> : <MdClose className="text-danger" />}  8 - 48 characters.</span>
                    <span className="d-block">{passwordRequirements.specialChar ? <MdCheck className="text-success" /> : <MdClose className="text-danger" />} At least one special character.</span>
                    <span className="d-block">{passwordRequirements.numberChar ? <MdCheck className="text-success" /> : <MdClose className="text-danger" />} At least one number.</span>
                    <span className="d-block">{passwordRequirements.capitalChar ? <MdCheck className="text-success" /> : <MdClose className="text-danger" />} At least one capital letter.</span>
                    {enableConfirmPassword && <span className="d-block">{passwordRequirements.matchingPasswords ? <MdCheck className="text-success" /> : <MdClose className="text-danger" />} Matching passwords.</span>}
                </div>
            </div>}
        </>
    )

}