import { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import axios from 'axios'
import SuggestedUser from './SuggestedUser'


export default function PeopleYouMayKnow() {
    const [isLoading, setIsLoading] = useState(false)
    const [suggestedList, setSuggestedList] = useState([])

    const source = axios.CancelToken.source()

    const getFriendList = async() => {
        setIsLoading(true)
        try {
            const response = await axios.get('/friends/find/', {
                cancelToken: source.token
            })

            if (response?.status === 200) {
                setSuggestedList(response.data.results)
            }

        } catch (err) {
            if (axios.isCancel(err)) return

        } finally {
            setIsLoading(false)
        }
    }


    useEffect(() => {
        getFriendList()
        return () => source.cancel()

    }, [])


    return (
        <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
            <div className="card-body d-flex p-4">
                <h4 className="fw-500 mb-0 font-xssss text-grey-900">People you may know</h4>
                <Link to={`/friends/find`} className="fw-500 ms-auto font-xssss text-primary"> See all </Link>
            </div>

            <div className='mb-2'>
                {isLoading > 0 && [...Array(5)].map((_, i) =>
                    <SuggestedUser loading={true} key={i} />
                )}

                {/* Shuffle array and get 5 users to show  */}
                {!isLoading && suggestedList.length > 0 && suggestedList.sort(() => 0.5 - Math.random()).slice(0, 5).map(user =>
                    <SuggestedUser user={user} updateList={setSuggestedList} key={user.id} />
                )}
            </div>

        </div>
    )
}