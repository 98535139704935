import { useState, useEffect } from 'react'
import useFetch from '../hooks/useFetchWithInfiniteScroll'
import { useParams, NavLink } from "react-router-dom"
import { toast } from 'react-toastify'
import { stringifyUrlParams } from '../utils/utils'
import FriendsDirectory from '../friends/FriendsDirectory'

export default function ProfileConnections({ user, isMyProfile }) {

    const { type } = useParams()

    const [url, setUrl] = useState(``)
    const { isLoading, error, results: media, setResults, lastItemRef: lastPostRef } = useFetch(url)

    // update url if props changes
    useEffect(() => {
        if (type === 'posts') {
            setUrl(`/newsfeed/media/?${stringifyUrlParams({ user_id: user?.id, content_type: 'images' })}`)

        } else if (type === 'profile') {
            setUrl(`/profiles/images/?${stringifyUrlParams({ user_id: user?.id, category: 'PROFILE' })}`)

        } else if (type === 'background') {
            setUrl(`/profiles/images/?${stringifyUrlParams({ user_id: user?.id, category: 'BACKGROUND' })}`)
        }
    }, [user, type])


    useEffect(() => {
        if (error) {
            toast.error(error?.response?.data?.non_field_errors?.toString() || error?.message || 'An unknown error occurred.')
        }
    }, [error])

    return (

        <div className="card w-100 border-0 p-3 py-2 rounded-xxl">
            <ul className="nav nav-tabs d-flex product-info-tab border-bottom-0 ps-2 mb-2" id="pills-tab" role="tablist">
                <li className="list-inline-item me-5">
                    <NavLink
                        to={`/${user?.username ? user?.username : user?.id}/connections/friends`}
                        exact
                        className="text-color fw-600 font-xssss pt-3 pb-2 ls-1 d-inline-block"
                        activeClassName="active"
                        isActive={() => type === 'friends'}>
                        Friends
                    </NavLink>
                </li>

                <li className="list-inline-item me-5">
                    <NavLink
                        to={`/${user?.username ? user?.username : user?.id}/connections/following`}
                        exact
                        className="text-color fw-600 font-xssss pt-3 pb-2 ls-1 d-inline-block"
                        activeClassName="active"
                        isActive={() => type === 'following'}>

                        Following
                    </NavLink>
                </li>
                <li className="list-inline-item me-5">
                    <NavLink
                        to={`/${user?.username ? user?.username : user?.id}/connections/followers`}
                        exact
                        className="text-color fw-600 font-xssss pt-3 pb-2 ls-1 d-inline-block"
                        activeClassName="active"
                        isActive={() => type === 'followers'}>

                        Followers
                    </NavLink>
                </li>

            </ul>


            {type && <FriendsDirectory userId={user?.id} username={user?.username} type={type} />}

        </div>


    )
}
