import axios from 'axios'
import { useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import Cropper from 'react-easy-crop'
import { MdZoomIn, MdZoomOut } from 'react-icons/md'
import Modal from '../components/Modal'


export default function UploadProfileImage({ aspect = { width: 500, height: 500 }, category, maxUploadImgSize = 20, setImage, setIsLoading, children }) {
    const initCrop = { x: 0, y: 0 }

    const [popupImage, setPopupImage] = useState(null)
    const [localImage, setLocalImage] = useState(null)

    const [crop, setCrop] = useState(initCrop)
    const [zoom, setZoom] = useState(1)
    const [maxZoomRange, setMaxZoomRange] = useState(0)
    const [completedCrop, setCompletedCrop] = useState({ croppedArea: undefined, croppedAreaPixels: undefined })

    const user = useSelector((state) => state.userReducer.user)
    const modal = useRef(null)
    const dispatch = useDispatch()



    const handleImageChange = function(e) {
        setCrop(initCrop)
        let file, img
        if ((file = e.target.files[0])) {
            img = new Image()
            const objectUrl = URL.createObjectURL(file)
            img.src = objectUrl
            img.onload = function() {
                if ((this.height < aspect.height || this.width < aspect.width) || e.target.files[0].size > maxUploadImgSize * 1000000) {
                    toast.warning(`Minimum resolution for ${category.toLowerCase()} images is ${aspect.width} X ${aspect.height}, and maximum file size is ${maxUploadImgSize} MB.`)
                    setLocalImage(null)
                    return false
                } else {
                    setLocalImage(e.target.files[0])
                    setPopupImage(URL.createObjectURL(e.target.files[0]))
                    setMaxZoomRange(parseInt(aspect.width > aspect.height ? this.naturalWidth / aspect.width : this.naturalHeight / aspect.height))
                    modal.current.open()
                }
            }
        }
    }

    const handleCancel = function(e) {
        setLocalImage(null)
        setPopupImage(null)
        modal.current?.close()

    }

    // const dispatch = useDispatch()
    const source = axios.CancelToken.source()

    const changeProfileImage = async() => {

        if (localImage && completedCrop.croppedAreaPixels) {
            setIsLoading(true)
            setMaxZoomRange(0)
            modal.current?.close()

            const formData = new FormData()
            formData.append('category', category)
            formData.append('crop_x', completedCrop.croppedAreaPixels.x)
            formData.append('crop_y', completedCrop.croppedAreaPixels.y)
            formData.append('crop_height', completedCrop.croppedAreaPixels.height)
            formData.append('crop_width', completedCrop.croppedAreaPixels.width)
            formData.append("image", localImage)

            try {
                const response = await axios.post('/profiles/images/', formData, { cancelToken: source.token })

                if (response?.status === 201) {
                    setImage(response.data.thumbnail)
                    dispatch({
                        type: 'UPDATE_USER',
                        user: {
                            ...user,
                            profile: { ...user.profile, ...(category === 'PROFILE' ? { thumbnail: response.data.thumbnail } : { background_image_thumbnail: response.data.thumbnail }) }
                        }
                    })
                    setLocalImage(null)
                    toast.success(`uploaded a ${category.toLowerCase()} picture successfully.`)
                }

            } catch (err) {
                if (axios.isCancel(err)) return
                toast.error(err.response?.data?.image?.toString() || err.response?.data?.non_field_errors?.toString() || 'An unknown error occurred.')
                modal.current?.open()

            } finally {
                setIsLoading(false)
            }
        } else {
            toast.warn('Please select image to upload')
        }
    }


    return (
        <>
            <Modal
                title={`Update ${category.toLowerCase()} image`}
                actionBtns={[
                    { text: "Cancel", classes: "btn text-color font-xssss p-2 px-3 me-2", onClick: handleCancel },
                    { text: "Update", classes: "btn btn-primary font-xssss text-white p-2 px-3 me-2", onClick: changeProfileImage }
                ]}
                ref={modal}>
                <div className='d-flex justify-content-around'>
                    <div className='phx-3' style={{ width: '1000px', maxWidth: '100%' }}>
                        {/* {popupImage &&
                            <div className='d-flex mb-3'>
                                <span
                                    className="ms-auto pointer bg-grey text-primary p-2 px-3 d-inline-block rounded-xl font-xssss fw-500 me-2"
                                    onClick={handleCancel}>
                                    cancel
                                </span>

                                <span
                                    className="pointer bg-primary p-2 px-3 d-inline-block rounded-xl font-xssss fw-500 text-white me-2"
                                    onClick={changeProfileImage}>
                                    Update
                                </span>
                            </div>
                        } */}
                        <div className="" style={{ position: 'relative', width: '100%', height: maxZoomRange ? '80%' : '100%', minHeight: 600 }}>
                            <Cropper
                                image={popupImage}
                                crop={crop}
                                zoom={zoom}
                                maxZoom={maxZoomRange}
                                aspect={aspect.width / aspect.height}
                                onCropChange={setCrop}
                                onCropComplete={(croppedArea, croppedAreaPixels) => setCompletedCrop({ croppedArea, croppedAreaPixels })}
                                onZoomChange={setZoom}
                            />
                        </div>

                        {maxZoomRange > 1 && <div className="my-2 text-center">
                            <MdZoomOut className='me-3 mt-2 text-color-lightest font-lg' onMouseDown={() => setZoom(prev => { if (Number(prev) > 1) { return Number(prev) - 0.1 } else { return prev } })} />
                            <input
                                type="range"
                                value={zoom}
                                min={1}
                                max={maxZoomRange}
                                step={0.1}
                                onChange={(e) => {
                                    setZoom(e.target.value)
                                }}
                                className="zoom-range w-25"
                            />
                            <MdZoomIn className='ms-3 mt-2 text-color-lightest font-lg' onMouseDown={() => setZoom(prev => { if (Number(prev) < maxZoomRange) { return Number(prev) + 0.1 } else { return prev } })} />
                        </div>}

                    </div>
                </div>
            </Modal>

            <label htmlFor={`${category}ImgInput`} className="pointer">{children}</label>
            <input
                id={`${category}ImgInput`}
                type="file"
                style={{ display: 'none' }}
                accept="image/*"
                onChange={e => handleImageChange(e)}
                onClick={e => (e.target.value = null)}
            />
        </>
    )
}