import { useParams, NavLink, useLocation } from "react-router-dom"
import ProfileImage from "./ProfileImage"
import ProfileBackgroundImage from "./ProfileBackgroundImage"
import Friend from "../friends/Friend"
import Loader from '../components/Loader'

import { MdMailOutline, MdOutlineMoreHoriz } from "react-icons/md"


export default function ProfileHeader({ loading, user, isMyProfile }) {
    const { pathname } = useLocation()

    return (
        <div className="card w-100 border-0 p-0  mb-3 shadow-xss rounded-xxl">
            {/* <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3"><img src="https://via.placeholder.com/1200x250.png" alt="avater" /></div> */}
            <ProfileBackgroundImage loading={loading} profile={user?.profile} enableUpload={isMyProfile} />
            <div className="card-body p-0 position-relative">

                <ProfileImage loading={loading} profile={user?.profile} enableUpload={isMyProfile} />

                {(loading || !user) ?
                    <h4 className="fw-500 font-sm mt-2 mb-lg-5 mb-4 pl-15">
                        <Loader height={40}>
                            <rect x="0" y="0" rx="5" ry="5" width="120" height="18" />
                            <rect x="0" y="27" rx="5" ry="15" width="60" height="14" />
                        </Loader> </h4> :
                    <>
                        <h4 className="fw-500 font-sm mt-2 mb-lg-5 mb-4 pl-15">{user.profile.full_name} <span className="fw-500 font-xssss mt-1 mb-3 d-block">{user.profile.online ? 'Online' : 'Offline'}</span></h4>
                        <div className="d-flex align-items-center justify-content-center position-absolute-md right-15 top-0 me-2">
                            {/* friend options */}
                            <Friend friend={user} key={user.id} />

                            {/* message */}
                            <NavLink to={{ pathname: `/messages/${user.id}` }} className="d-none d-lg-block background-color-light btn-round-lg ms-2 rounded-3"><MdMailOutline className="font-md text-color" /> </NavLink>
                            {/* options */}
                            <span className="d-none d-lg-block pointer background-color-light btn-round-lg ms-2 rounded-3"><MdOutlineMoreHoriz className="font-md text-color" /></span>

                        </div>
                    </>}
            </div>

            <div className="card-body d-block w-100 shadow-none mb-0 p-0 border-top">

                {(loading || !user) ? <Loader height={55}>
                    <rect x="18" y="10" rx="5" ry="5" width="55" height="30" />
                    <rect x="109" y="10" rx="5" ry="5" width="55" height="30" />
                    <rect x="196" y="10" rx="5" ry="5" width="55" height="30" />
                    <rect x="289" y="10" rx="5" ry="5" width="55" height="30" />
                    <rect x="386" y="10" rx="5" ry="5" width="55" height="30" />
                    <rect x="477" y="10" rx="5" ry="5" width="55" height="30" />
                </Loader> :
                    <ul className="nav nav-tabs h55 d-flex product-info-tab border-bottom-0 ps-4" id="pills-tab" role="tablist">
                        <li className="list-inline-item me-5">
                            <NavLink
                                to={`/${user.username ? user.username : user.id}`}
                                exact
                                className="text-color fw-600 font-xssss pt-3 pb-3 ls-1 d-inline-block"
                                activeClassName="active"
                                isActive={() => [`/${user.username}`, `/${user.id}`].includes(pathname)}>
                                Posts
                            </NavLink>
                        </li>
                        <li className="list-inline-item me-5">
                            <NavLink
                                to={`/${user.username ? user.username : user.id}/about`}
                                exact
                                className="text-color fw-600 font-xssss pt-3 pb-3 ls-1 d-inline-block"
                                activeClassName="active"
                                isActive={() => [`/${user.username}/about`, `/${user.id}/about`].includes(pathname)}>
                                About
                            </NavLink>
                        </li>
                        <li className="list-inline-item me-5">
                            <NavLink
                                to={`/${user.username ? user.username : user.id}/connections/friends`}
                                exact
                                className="text-color fw-600 font-xssss pt-3 pb-3 ls-1 d-inline-block"
                                activeClassName="active"
                                isActive={() => [`/${user.username}/connections`, `/${user.id}/connections`].includes(pathname)}>
                                Connections
                            </NavLink>
                        </li>
                        <li className="list-inline-item me-5">
                            <NavLink
                                to={`/${user.username ? user.username : user.id}/images/posts`}
                                exact
                                className="text-color fw-600 font-xssss pt-3 pb-3 ls-1 d-inline-block"
                                activeClassName="active"
                                isActive={() => pathname.includes(`/${user.username}/images`)| pathname.includes(`/${user.id}/images`)}>
                                Images
                            </NavLink>
                        </li>
                        <li className="list-inline-item me-5">
                            <NavLink
                                to={`/${user.username ? user.username : user.id}/videos`}
                                exact
                                className="text-color fw-600 font-xssss pt-3 pb-3 ls-1 d-inline-block"
                                activeClassName="active"
                                isActive={() => [`/${user.username}/videos`, `/${user.id}/videos`].includes(pathname)}>
                                Videos
                            </NavLink>
                        </li>
                        <li className="list-inline-item me-5">
                            <NavLink
                                to={`/${user.username ? user.username : user.id}/files`}
                                exact
                                className="text-color fw-600 font-xssss pt-3 pb-3 ls-1 d-inline-block"
                                activeClassName="active"
                                isActive={() => [`/${user.username}/files`, `/${user.id}/files`].includes(pathname)}>
                                Files
                            </NavLink>
                        </li>
                        {/* <li className="list-inline-item me-5">
                            <NavLink
                                to={`/${user.username ? user.username : user.id}/media`}
                                exact
                                className="text-color fw-600 font-xssss pt-3 pb-3 ls-1 d-inline-block"
                                activeClassName="active"
                                isActive={() => [`/${user.username}/media`, `/${user.id}/media`].includes(pathname)}>
                                Media
                            </NavLink>
                        </li> */}

                    </ul>}

            </div>
        </div>

    )
}
