import { useEffect, useImperativeHandle, useState, forwardRef, useCallback } from 'react'
import { createPortal } from 'react-dom'
import styles from './css/Modal.module.css'

const rootElement = document.getElementById('root')

export function Modal({ title, actionBtns = [], children, fade = false, defaultOpened = false }, ref) {
    const [isOpen, setIsOpen] = useState(defaultOpened)

    const close = useCallback(() => {
        setIsOpen(false)
    }, [])

    useImperativeHandle(ref, () => ({
        open: () => {
            setIsOpen(true)
        },
        close
    }), [close])

    const handleEscape = useCallback(event => {
        if (event.keyCode === 27) close()
    }, [close])

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('keydown', handleEscape, false)
            // disable scrolling
            document.body.classList.add("no-scroll")
        } else {
            document.body.classList.remove("no-scroll")
        }
        return () => {
            document.removeEventListener('keydown', handleEscape, false)
            document.body.classList.remove("no-scroll")
        }
    }, [handleEscape, isOpen])


    return createPortal(
        isOpen ? (
            <div className={`${styles.modal} ${fade ? styles.fade : ''}`}>
                <div className={styles.overlay} onClick={close} />


                <div className={`${styles.body} ${title ? 'card' : ''} d-flex shadow-xss rounded-xxl`}>
                    {title && <div className='d-flex justify-content-end border-bottom p-2 mt-1'>
                        <h4 className='d-inline-block font-xss px-2 pt-2 me-auto'>{title}</h4>
                        <span onClick={close} className="d-inline-block pointer text-color-light btn-round-sm background-color-light ms-2" style={{ minWidth: 35 }}> &#x2715; </span>
                    </div>}

                    {children && <div className='overflow-auto'>{children}</div>}

                    {/* action btns */}
                    {actionBtns.length > 0 && <div className='ms-auto my-3 me-2'>
                        {actionBtns.map((btn, index) =>
                            <span
                                onClick={btn.closeOnClick ? close : btn.onClick}
                                className={btn.classes}
                                key={index}>
                                {btn.text}
                            </span>
                        )}
                    </div>}
                </div>
            </div>
        ) : null,
        rootElement
    )
}

export default forwardRef(Modal)